import React, { useEffect } from "react";
import ClubPage from "./components/ClubPage";
import { Heading } from "./components/Heading";
import Container from "@mui/material/Container";
import { MainCard } from "./components/MainCard";
import { BottomHeading } from "./components/BottomHeading";
import { CardSection } from "./components/CardSection";

import apiRequest from '../../api/apiRequest';

export const ManageClubHub = () => {
  const [clubs, setClubs] = React.useState([]);
  const [featuredClub, setFeaturedClub] = React.useState(null);

  useEffect(() => {
    apiRequest({
      url: `/clubs?active=1`,
      method: 'GET',
    }).then((res) => {
      let c = [];
      res.data.forEach((club) => {
        if (club.featured) {
          setFeaturedClub(club);
        } else {
          c.push(club);
        }
      })
      setClubs(c);
    });
  }, []);

  
  return (
    <React.Fragment>
      <Heading />
      {(featuredClub) ? <MainCard club={featuredClub} /> : []}
      <Container maxWidth="xl">
        <CardSection clubs={clubs}/>
      </Container>
    </React.Fragment>
  );
};

export const ManageClubPage = ClubPage;

export default ManageClubHub;
