import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ConfigurationContext from 'contexts/ConfigurationContext';

export const FamiliesProfileActivities = ({ profile }) => {
  const { activities, fetchActivities } = React.useContext(ConfigurationContext);

  React.useEffect(() => {
    if (activities.length === 0) fetchActivities();
  }, [activities, fetchActivities]);

  if (profile?.activities?.length === 0) return;

  return (
    <Box>
      <Grid container maxWidth='xl' justifyContent='center'>
        <Grid item container md={10}>
          <Typography fontSize={{ md: 40 }} fontWeight={700} mt={4}>
            Activites
          </Typography>
          <Grid item container md={12} mt={4}>
            {profile?.activities?.map((item) => (
              <>
                <Grid item md={1.5} xs={6}>
                  <Box display='flex' alignItems='center' flexDirection='column' mb={4}>
                    {/* <img src={`/assets/icons/activity-icon${item.id}.svg`} alt='' height='50px' /> */}
                    <img src={activities.find((a) => a.id === item.id)?.icon.url} alt='' height='50px' />
                    <Typography fontSize={{ md: 18 }} fontWeight={600} mt={2}>
                      {item?.name}
                    </Typography>
                  </Box>
                </Grid>
              </>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FamiliesProfileActivities;
