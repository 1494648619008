import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ConfigurationContext from '../../../../contexts/ConfigurationContext';
import urls from 'api/urls';
import { PROGRAM_CONTENT } from 'constants/program_content';

export const Hero = () => {
  const { configurations } = useContext(ConfigurationContext);

  return (
    <React.Fragment>
      <Box
        // height={700}
        height={750}
        sx={{
          objectFit: 'cover',
          backgroundSize: 'cover',
          // backgroundPosition: "center",
          backgroundPosition: 'bottom',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)), url(${
            PROGRAM_CONTENT[urls.programId()].home_hero_image
          })`,
        }}
      >
        <Container maxWidth='false' disableGutters>
          <Grid item container>
            <Grid item md={6} sm={8} xs={12} mt={{ xs: 35, md: 35, sm: 20, lg: 35 }}>
              <Typography
                wrap
                ml={{ xs: 3, md: 15, sm: 4 }}
                color='white'
                fontSize={{ xs: 30, md: 45, sm: 40, lg: 50 }}
                fontWeight='bold'
                // lineHeight={{ xs: 1.5, md: 1.2 }}
                lineHeight={{ xs: 1.5, md: 1.1 }}
              >
                Explore Everything <br /> {configurations.schoolShortName ? configurations.schoolShortName : ''} has to
                offer <br /> on {configurations.programName ? configurations.programName : ''}
              </Typography>
              <Box
                wrap
                ml={{ xs: 3, md: 15, sm: 4 }}
                // mt={2}
                mt={0}
                sx={{ display: 'flex', flexWrap: 'wrap' }}
              >
                <Typography variant='body2' color='white' fontSize={{ xs: 11, md: 15, sm: 13 }}>
                  {PROGRAM_CONTENT[urls.programId()]?.home_hero_subtitle
                    ? PROGRAM_CONTENT[urls.programId()].home_hero_subtitle(
                        configurations.schoolShortName ? configurations.schoolShortName : ''
                      )
                    : ''}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
};
