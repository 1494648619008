import { object, string } from 'zod';

export const signupSchema = object({
  firstName: string().min(1, 'First Name is required').max(20, 'First Name must be less than 20 characters'),
  lastName: string().min(1, 'Last Name is required').max(20, 'Last Name must be less than 20 characters'),
  email: string().min(1, 'Email is required').email('Email is invalid'),
  password: string()
    .min(8, 'Password must be at least 8 characters')
    .max(32, 'Password must be less than 32 characters'),
  confirmPassword: string()
    .min(8, 'Password must be at least 8 characters')
    .max(32, 'Password must be less than 32 characters'),
}).refine((data) => data.password === data.confirmPassword, {
  path: ['confirmPassword'],
  message: 'Passwords do not match',
});

export const logInSchema = object({
  email: string().min(1, 'Email is required').email('Email is invalid'),
  password: string().min(1, 'Password is required'),
});

export const forgotPasswordSchema = object({
  email: string().min(1, 'Email is required').email('Email is invalid'),
});

export const resetPasswordSchema = object({
  password: string()
    .min(8, 'Password must be at least 8 characters')
    .max(32, 'Password must be less than 32 characters'),
  confirmPassword: string()
    .min(8, 'Password must be at least 8 characters')
    .max(32, 'Password must be less than 32 characters'),
}).refine((data) => data.password === data.confirmPassword, {
  path: ['confirmPassword'],
  message: 'Passwords do not match',
});

export const changePasswordSchema = object({
  currentPassword: string()
    .min(6, 'Password must be at least 8 characters')
    .max(32, 'Password must be less than 32 characters'),
  password: string()
    .min(8, 'Password must be at least 8 characters')
    .max(32, 'Password must be less than 32 characters'),
  confirmPassword: string()
    .min(8, 'Password must be at least 8 characters')
    .max(32, 'Password must be less than 32 characters'),
}).refine((data) => data.password === data.confirmPassword, {
  path: ['confirmPassword'],
  message: 'Passwords do not match',
});

export const createPasswordSchema = object({
  password: string()
    .min(8, 'Password must be at least 8 characters')
    .max(32, 'Password must be less than 32 characters'),
  confirmPassword: string()
    .min(8, 'Password must be at least 8 characters')
    .max(32, 'Password must be less than 32 characters'),
}).refine((data) => data.password === data.confirmPassword, {
  path: ['confirmPassword'],
  message: 'Passwords do not match',
});

export const editAccountSchema = object({
  email: string().min(1, 'Email is required').email('Email is invalid'),
  firstName: string().min(1, 'First Name is required').max(20, 'First Name must be less than 20 characters'),
  lastName: string().min(1, 'Last Name is required').max(20, 'Last Name must be less than 20 characters'),
  title: string(),
  phone: string(),
  city: string(),
  state: string(),
  zipCode: string(),
  country: string(),
  classYear: string(),
});
