import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import QuoteRow from './Quoterow';
import AboutMediaRow from './AboutMediaRow';

export const Image = ({ formData, updateFormData }) => {
  return (
    <React.Fragment>
      <Container>
        <Box mt={5}>
          <Typography variant='h5' fontWeight='bold'>
            Image or Video
          </Typography>
        </Box>

        <AboutMediaRow about={formData?.about_media} />

        <QuoteRow formData={formData} updateFormData={updateFormData} />
      </Container>
    </React.Fragment>
  );
};

export default Image;
