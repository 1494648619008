import * as React from 'react';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import { MenuItem, ListItemIcon } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import ListItemText from '@mui/material/ListItemText';
// import AuthContext from "contexts/AuthContext";

import { usePopupState, bindHover, bindMenu } from 'material-ui-popup-state/hooks';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import ProfileContext from 'contexts/ProfileContext';
import ConfigurationContext from 'contexts/ConfigurationContext';

const MenuPopupState = () => {
  const { profile } = React.useContext(ProfileContext);
  const { isFeatureEnabled } = React.useContext(ConfigurationContext);
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoMenu',
  });

  const isVerifiedFamily = profile?.roles?.find((r) => r?.name === 'Verified Family') && isFeatureEnabled('family');
  const isStaff = profile?.roles?.find((r) => r?.name === 'Staff') && isFeatureEnabled('staff');
  const isGraduate = profile?.roles?.find((r) => r?.name === 'Graduate') && isFeatureEnabled('graduate');

  if (!isVerifiedFamily && !isStaff && !isGraduate) return;

  return (
    <React.Fragment>
      <MenuItem sx={{ mt: 1 }} {...bindHover(popupState)}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText>My Profile</ListItemText>
        <ListItemIcon>
          <Box ml={2}>
            <ArrowForwardIcon fontSize='small' />
          </Box>
        </ListItemIcon>
      </MenuItem>

      <HoverMenu
        {...bindMenu(popupState)}
        sx={{ top: 0, left: -2 }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {isVerifiedFamily && (
          <MenuItem onClick={popupState.close}>
            <Link to={ROUTES.FAMILY_PROFILE}>Family Profile</Link>
          </MenuItem>
        )}

        {isStaff && (
          <MenuItem onClick={popupState.close}>
            <Link to={ROUTES.STAFF_PROFILE}>Staff Profile</Link>
          </MenuItem>
        )}

        {isGraduate && (
          <MenuItem onClick={popupState.close}>
            <Link to={ROUTES.GRADUATE_PROFILE}>Graduate Profile</Link>
          </MenuItem>
        )}
      </HoverMenu>
    </React.Fragment>
  );
};

export default MenuPopupState;
