import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { CardMedia, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import urls from './../../../../api/urls'
import { BackgroundCircle } from 'components/Global/BackgroundCircle'

export const MainCard = (props) => {
  const history = useHistory()
  const navigate = (path) => {
    return history.push({
      pathname: path,
    })
  }

  const theme = useTheme()

  let club = props.club
  if (!club) {
    club = {
      clubName: 'Loading...',
      about: '',
    }
  }

  return (
    <div style={{ width: '100%', marginBottom: '30px' }}>
      <Grid container>
        <Grid
          item
          md={12}
          sm={12}
          justifyContent={'end'}
          className="club-listing-featured-container"
        >
          <Box>
            <Box sx={{ width: '100%', position: 'relative' }}>
              <Box
                display={{ xs: 'block' }}
                sx={{
                  position: 'absolute',
                  right: '10%',
                  top: '-25%',
                  zIndex: '-999',
                }}
              >
                <BackgroundCircle
                  top="-100px"
                  right="5%"
                  zIndex="1"
                  styles={{ image: { width: 340, height: 340 } }}
                  color={theme.palette.primary.main}
                  strokeWidth={20}
                />
              </Box>
              <Card elevation={0}>
                <CardMedia>
                  <img
                    width="100%"
                    alt={club.name}
                    className="club-listing-featured-image"
                    src={
                      club.image
                        ? urls.getApiDomain() + club.image.path
                        : '/assets/images/noImage.jpg'
                    }
                  />
                </CardMedia>
              </Card>
              <Box
                display={{ xs: 'none', sm: 'block', md: 'block' }}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '-10%',
                  zIndex: '-999',
                }}
              >
                <BackgroundCircle
                  zIndex="1"
                  styles={{ image: { width: 340, height: 340 } }}
                  color={theme.palette.primary.main}
                  strokeWidth={20}
                />
              </Box>
            </Box>
            <Box width={{ lg: '50%', md: '60%', sm: '65%' }}>
              <Typography variant="h4" fontWeight={900}>
                {club.clubName}
              </Typography>
              <Typography variant="body2">{club.about}</Typography>
            </Box>
            <Button
              style={{
                width: '170px',
                height: '47px',
                border: '1px solid ' + theme.palette.primary.main,
                color: theme.palette.primary.main,
                borderRadius: '25px',
                marginTop: '15px',
                marginBottom: '15px',
              }}
              onClick={() => navigate('/club-page/' + club.id)}
            >
              Learn More
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}
