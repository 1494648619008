import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export const FamiliesBioAndFavoriteMemory = ({ profile, type }) => {
  const condBio = profile?.about?.values?.bio == null;
  const condExperience = profile?.about?.values?.favorite_experience == null;
  if (condBio && condExperience) return;

  return (
    <Box>
      {profile?.about?.values?.bio ? (
        <>
          <Grid container maxWidth='xl' justifyContent='center'>
            <Grid item container md={10}>
              <Typography fontSize={{ md: 40 }} fontWeight={700} mt={10} p={{ xs: 4, md: 0 }}>
                Bio
              </Typography>
            </Grid>
          </Grid>
          <Grid container maxWidth='xl' justifyContent='center'>
            <Grid item container md={10}>
              <Typography fontSize={{ md: 18 }} ml={2}>
                {profile?.about?.values?.bio}
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        ''
      )}

      {profile?.about?.favorite_experience ? (
        <>
          <Grid container maxWidth='xl' justifyContent='center'>
            <Grid item container md={10}>
              <Typography fontSize={{ md: 40 }} fontWeight={700} mt={10} p={{ xs: 4, md: 0 }}>
                Favorite Experience
              </Typography>
            </Grid>
          </Grid>
          <Grid container maxWidth='xl' justifyContent='center'>
            <Grid item container md={10}>
              <Typography fontSize={{ md: 18 }} ml={2}>
                {profile?.favorite_experience}
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        ''
      )}
    </Box>
  );
};

export default FamiliesBioAndFavoriteMemory;
