import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ConfigurationContext from 'contexts/ConfigurationContext';
import { useTheme } from '@mui/material/styles';

export const GraduatesHeader = ({ query, setQuery, onSearch }) => {
  const { configurations } = React.useContext(ConfigurationContext);
  const theme = useTheme();

  return (
    <Box>
      <Container maxWidth='false' disableGutters>
        <Grid item container>
          <Grid item xs={12} mt={10}>
            <Typography
              wrap
              ml={{ xs: 5, md: 15, sm: 4 }}
              variant='h3'
              fontSize={{ xs: 30, md: 50, sm: 40 }}
              fontFamily='Proxima-Nova-Black'
              color={theme.palette.secondary.main}
              textAlign='center'
            >
              It all started at {configurations?.schoolName ? configurations?.schoolName : ''}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Container>
              <Typography color='#131416' fontSize={{ md: 40 }} fontWeight={700} mt={5}>
                Alumni Network
              </Typography>
              <Typography>
                Use the {configurations?.schoolShortName ? configurations?.schoolShortName : ''} alumni network to stay
                connected with your classmates and remain an active member of the{' '}
                {configurations?.schoolName ? configurations?.schoolName : ''} community!
              </Typography>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default GraduatesHeader;
