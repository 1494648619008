import React, { useEffect } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import urls from "api/urls";
import apiRequest from "api/apiRequest";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import AuthContext from "contexts/AuthContext";
import { BackgroundCircle } from "components/Global/BackgroundCircle";

export const ClubPageCover = (props) => {
  const theme = useTheme();
  const { isAuthenticated, user, logout } = React.useContext(AuthContext);
  const useStyles = makeStyles({
    button: {
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  });

  const classes = useStyles();
  let club = props.club || {};
  const [isMember, setIsMember] = React.useState(false);

  useEffect(() => {
    if (!props.club) return;
    apiRequest({
      method: "get",
      url: "/clubs/" + props.club.id + "/family",
    }).then((response) => {
      setIsMember(response.data);
    });
  }, [props.club]);

  const toggleJoin = () => {
    if (isMember) {
      //catch error below
      apiRequest({
        method: "delete",
        url: "/clubs/" + club.id + "/family",
      }).then((response) => {
        setIsMember(response.data);
      });
    } else {
      apiRequest({
        method: "post",
        url: "/clubs/" + club.id + "/family",
      }).then(
        (response) => {
          setIsMember(response.data);
        },
        (err) => {
          alert(err);
        }
      );
    }
  };

  if (!club) {
    return [];
  }

  return (
    <div>
      <Grid container>
        <Grid item sx={{ position: "relative" }} width={"100%"} height={"100%"}>
          <Box
            display={{
              xs: "none",
              sm: "block",
              md: "block",
            }}
            sm={{}}
            md={{
              position: "absolute",
              zIndex: "-999",
              left: "12%",
              top: "-8%",
            }}
          >
            <BackgroundCircle
              bottom="-150px"
              left="-20%"
              zIndex="-1"
              styles={{ image: { width: 340, height: 340 } }}
              color={theme.palette.primary.main}
              strokeWidth={20}
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              zIndex: "-999",
              left: "-10%",
              bottom: "40%",
            }}
            display={{ xs: "none", sm: "block", md: "block" }}
          ></Box>

          <Box
            elevation={0}
            className="club-cover-container"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Box width="100%">
              <img
                className="club-cover-image"
                alt="club-cover"
                src={
                  club.image
                    ? urls.getApiDomain() + club.image?.path
                    : "/assets/images/noImage.jpg"
                }
              />
              <Typography
                sx={{
                  fontFamily: "Proxima Nova",
                  fontStyle: "normal",
                  fontWeight: "900",
                  fontSize: "28px",
                  lineHeight: "28px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                About Our Club
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                width={{ sm: "85%", md: "80%", lg: "70%" }}
              >
                {club.about}
              </Typography>

              <br />
              <br />

              <Grid
                width={"100%"}
                container
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "100px",
                }}
              >
                {(club.num_students !== "") ?<Grid
                  item
                  xs={12}
                  sm={8}
                  md={6}
                  lg={6}
                  sx={{
                    display: "flex",
                    marginBottom: "20px",
                    border: "none",
                    borderBottom: "2px solid gray",
                    padding: "none",
                    paddingBottom: "10px",
                  }}
                >
                   <Typography variant="body1" sx={{ marginLeft: "30px" }}>
                    Number of Participants: {club.num_students}
                  </Typography> 
                </Grid> : []}
                <Grid item md={6}></Grid>
                {(club.meeting_times) ? <Grid
                  item
                  xs={12}
                  sm={8}
                  md={6}
                  lg={6}
                  sx={{
                    display: "flex",
                    marginBottom: "20px",
                    border: "none",
                    borderBottom: "2px solid gray",
                    padding: "none",
                    paddingBottom: "10px",
                  }}
                >
                  <Typography variant="body1" sx={{ marginLeft: "30px" }}>
                    When we Meet: {club.meeting_times}
                  </Typography>
                </Grid> : []}
                <Grid item md={6}></Grid>
                {(club.gradeBands && club.gradeBands.length) ? <Grid
                  item
                  md={12}
                  sx={{
                    display: "flex",
                  }}
                >
                  <Typography variant="body1" sx={{ marginLeft: "30px" }}>
                    Participants: {club.gradeBands.map((band, index) => (index && band ? `${(index !== 0) ? ', ' : ''}${band.gradeBand?.grade_band}` : band?.gradeBand?.grade_band)).join("")}
                  </Typography>
                </Grid> : []}
                <Grid item md={6}></Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ justifyContent: "center", display: "flex" }}>
        {(club.name && user && user.is_verified_family) ? (
          <Grid container>
            <Grid
              item
              textAlign={"center"}
              mb={5}
              width={"100%"}
              height={"100%"}
            >
              <Box
                sx={{
                  width: "100%",
                  "@media (max-width: 1024px)": {
                    "@-moz-document url-prefix()": {
                      display: "block",
                      marginTop: "250px",
                    },
                  },
                }}
              >
                <Button
                  variant="contained"
                  className={classes.button}
                  sx={{
                    fontWeight: "bold",
                    fontSize: 16,
                    width: "350px",
                    height: "50px",
                    borderRadius: "25px",
                  }}
                  onClick={() => toggleJoin()}
                >
                  {!isMember ? "Follow" : "Unfollow"}
                </Button>
                <br />
                <p>
                  {!isMember
                    ? "Click follow to receive updates about everything we're up to!"
                    : ""}
                </p>
              </Box>
            </Grid>
          </Grid>
        ) : (
          []
        )}
      </Box>
      <BackgroundCircle
        bottom="-180px"
        right="50px"
        zIndex="-1"
        styles={{ image: { width: 340, height: 340 } }}
        color={theme.palette.primary.main}
        strokeWidth={20}
      />
    </div>
  );
};
