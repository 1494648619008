import React, { useEffect } from "react";
import ImageUpload from "components/ImageUpload";
import COLORS from "constants/colors";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Container from "@mui/system/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import IconButton from "@mui/material/IconButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const Announcements = (props) => {

  const [announcement, setAnnouncement] = React.useState(props.club ? props.club.announcement : "");

  useEffect(() => {
    setAnnouncement(props.club ? props.club.announcements : "");
  }, [props.club]);

  useEffect(() => {
    let club = {...props.club};
    club.announcements = announcement;
    if (props.onChange) props.onChange(club);
  }, [announcement]);

  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Box mb={5} mt={7}>
          <Typography variant="h4" fontWeight="bold">
            Announcements
          </Typography>
        </Box>
        
      <Grid item xs={12}>
        <Box mt={3} display="flex" ml={'2em'}>
          <TextField style={{paddingTop:'1em'}} multiline={true} rows={8} fullWidth value={announcement} onChange={(e) => setAnnouncement(e.target.value)}
            id="outlined-basic" label="" placeholder="Write your club announcements" variant="standard" />
        </Box>
      </Grid>
    </Container>
    </React.Fragment >
  );
};

export default Announcements;
