import React from 'react';
import Box from '@mui/system/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Container from '@mui/system/Container';
import { Typography, MenuItem, Select } from '@mui/material';
import {
  Divider,
  FormControl,
  Grid,
  TextField,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Chip,
  CircularProgress,
} from '@mui/material';
import COLORS from 'constants/colors';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { toast } from 'react-toastify';
import MessagingContext from 'contexts/MessagingContext';
import AuthContext from 'contexts/AuthContext';
// import { MESSAGE_PLACEHOLDER } from 'constants/profiles';

export const NewMessage = (props) => {
  const { isUserProfile, isModal, setNewMessage, members } = props || {};
  const [selectedMembers, setSelectedMembers] = React.useState([]);
  const [newMessageBody, setNewMessageBody] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const { sendMessage } = React.useContext(MessagingContext);
  const { user } = React.useContext(AuthContext);

  const names = members;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleSubmit = async () => {
    if (selectedMembers.length === 0) {
      toast.error('Please select a family member');
      return;
    }
    if (newMessageBody.trim() === '') {
      toast.error('Please type a message');
      return;
    }

    try {
      setIsLoading(true);
      for (let i = 0; i < selectedMembers.length; i++) {
        const m = selectedMembers[i];
        await sendMessage([user.id, m], newMessageBody);
      }
      setIsLoading(false);
      setNewMessage(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Box alignItems='center' display='flex' justifyContent='space-between' height='50px'>
        {isModal ? (
          ''
        ) : (
          <Typography variant='h5' fontWeight='bold'>
            Messages
          </Typography>
        )}
        {!isUserProfile && (
          <Button sx={{ width: '153px', height: '45px' }} className='MuiButton-profile' variant='contained'>
            New Message
          </Button>
        )}
      </Box>
      {!isUserProfile && <Box mt={5}>chat</Box>}
      {isUserProfile && (
        <Box mt={5}>
          <Paper sx={{ borderRadius: '26px', p: 3 }} elevation={4}>
            <Box display='flex' alignItems='center' height='20px'>
              <ArrowCircleLeftOutlinedIcon
                fontSize='large'
                color='primary'
                sx={{ cursor: 'pointer' }}
                onClick={() => setNewMessage(false)}
              />
              <Typography ml={3} variant='h6' fontWeight='bold'>
                New Message
              </Typography>
            </Box>
            <Box mt={2}>
              <Divider />
            </Box>
            <Grid container height='450px' marginTop={'15px'}>
              <Grid item md={1}>
                <Box>
                  <Typography variant='body1' p={1}>
                    To
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={10} position='relative'>
                <Box borderRadius={'5px'} bgcolor={COLORS.white.secondary} width='100%'>
                  <Select
                    labelId='demo-multiple-name-label'
                    id='demo-multiple-name'
                    multiple
                    value={selectedMembers}
                    onChange={(e) => {
                      setSelectedMembers(e.target.value);
                    }}
                    input={<OutlinedInput />}
                    MenuProps={MenuProps}
                    fullWidth
                    size='small'
                    renderValue={(selected) => {
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => {
                            const found = names.find((n) => n.id === value);
                            const fullName = `${found.first_name} ${found?.last_name || ''}`;
                            return <Chip key={value} label={fullName} color='primary' size='small' />;
                          })}
                        </Box>
                      );
                    }}
                  >
                    {names.map((name, idx) => {
                      const fullName = `${name.first_name} ${name?.last_name ? name.last_name : ''}`;
                      return (
                        <MenuItem key={idx} value={name.id} dense={true}>
                          <Checkbox checked={selectedMembers.includes(name.id)} />
                          <ListItemText primary={fullName} sx={{ fontSize: '0.8em' }} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {/* <Typography variant="body1" p={1}>
                      Jane Anderson
                    </Typography> */}
                </Box>
                <Box my={5}>
                  <FormControl fullWidth>
                    <TextField
                      InputProps={{ disableUnderline: 'false' }}
                      variant='standard'
                      required
                      multiline
                      fullWidth
                      rows={5}
                      id='message'
                      name='message'
                      sx={{ pr: 8 }}
                      placeholder='Type your message here'
                      value={newMessageBody}
                      onChange={(e) => {
                        setNewMessageBody(e.target.value);
                      }}
                    />
                  </FormControl>
                </Box>
                <Button
                  sx={{ width: '153px', height: '45px', position: 'absolute', right: '0', bottom: '30px' }}
                  className='MuiButton-profile'
                  variant='contained'
                  color='secondary'
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress /> : 'Send'}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      )}
    </Container>
  );
};

export default NewMessage;
