import { createContext, useState, useEffect } from 'react';
import apiRequest from '../api/apiRequest';
import urls from '../api/urls';

const QAContext = createContext();

export const QAProvider = ({ children }) => {
  const [qas, setQas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchQas();
  }, []);

  const fetchQas = async () => {
    try {
      setIsLoading(true);
      const response = await apiRequest({
        method: 'get',
        url: urls.qa,
      });
      setQas([...response.data]);
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  return (
    <QAContext.Provider
      value={{
        qas,
        isLoading,
        error,
        fetchQas,
      }}
    >
      {children}
    </QAContext.Provider>
  );
};

export default QAContext;
