import React from 'react';
import COLORS from 'constants/colors';
import Box from '@mui/system/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useContext } from 'react';
import ProfileContext from 'contexts/ProfileContext';
import { Link } from 'react-router-dom';
import ConfigurationContext from 'contexts/ConfigurationContext';

export const STAFF_PROFILE_LABELS = [
  { id: 1, label: 'Staff Profile' },
  { id: 2, label: 'Preferences' },
];

export const SideBar = (props) => {
  const { setActiveStep, activeStep } = props || {};
  const [selectedIndex, setSelectedIndex] = React.useState(activeStep);
  const { staffProfile, profile } = useContext(ProfileContext);
  const { configurations } = useContext(ConfigurationContext);

  return (
    <React.Fragment>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        marginBottom={{ md: '200px', lg: '200px' }}
      >
        <Avatar
          alt=''
          src={
            staffProfile?.profile_image?.url
              ? staffProfile?.profile_image?.url
              : configurations?.default_user_profile_image?.url
              ? configurations?.default_user_profile_image?.url
              : '/assets/images/family-profile.svg'
          }
          sx={{
            width: 70,
            height: 70,
            background: COLORS.grey.secondary,
            marginBottom: 2,
          }}
        />
        <Box textAlign='left'>
          <Link to={`/staff-directory/${profile?.id}`}>
            <Typography className={'MuiTypography-profile-sidebar'} fontWeight={700} color='primary'>
              View Profile
            </Typography>
          </Link>
          {STAFF_PROFILE_LABELS.map((item, index) => (
            <Box mt={2}>
              <Typography
                className={'MuiTypography-profile-sidebar'}
                onClick={() => {
                  setSelectedIndex(index);
                  setActiveStep(index);
                }}
                fontWeight={index === selectedIndex ? 700 : null}
              >
                {item.label}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default SideBar;
