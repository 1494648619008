import { createContext, useState } from 'react';
import apiRequest from '../api/apiRequest';
import urls from '../api/urls';

const AmbassadorContext = createContext();

export const AmbassadorProvider = ({ children }) => {
  const [ambassadors, setAmbassadors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const fetchAmbassadors = async () => {
    try {
      setIsLoading(true);

      const response = await apiRequest({
        method: 'get',
        url: `${urls.families}?limit=30&page=1&filter[is_standard][is]=0`,
      });
      setAmbassadors(response.data);
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  return (
    <AmbassadorContext.Provider
      value={{
        ambassadors,
        isLoading,
        error,
        fetchAmbassadors,
      }}
    >
      {children}
    </AmbassadorContext.Provider>
  );
};

export default AmbassadorContext;
