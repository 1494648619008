const ROUTES = {
  HOME: '/',
  QA: '/qa',
  TIPS: '/thinktank',
  CLUB_HUB: '/club-hub',
  CLUB_PAGE: '/club-page/:clubId',
  CLUB_EDIT: '/club-editor/:clubId',
  OUTCOMES: '/outcomes',
  OUR_STAFF: '/our-staff',
  PROFILES: '/auth/profile',
  MY_ACCOUNT: '/my-account',
  FAMILY_PROFILE: '/family-profile',
  STAFF_PROFILE: '/staff-profile',
  GRADUATE_PROFILE: '/graduate-profile',
  TIPS_ARTICLE: '/article/:articleId',
  FAMILY_NETWORK: '/family-network',
  // STAFF_PROFILE: '/staff/profile',
  CLUB_EDITOR: '/auth/profile/club-editor',
  STAFF_DIRECTORY: '/staff-directory',
  STAFF_DIRECTORY_DETAILS: '/staff-directory/:staffId',
  GRADUATES: '/graduates',
  GRADUATE_DETAILS: '/graduates/:graduateId',
  FAMILY_AMBASSADORS_DETAILS: '/family-ambassadors-details',
  FAMILY_NETWORK_DETAILS: '/family-network/families',
  FAMILY_NETWORK_DETAILS_INDIVIDUAL: '/family-network/families/:userId',
  FAMILY_AMBASSADORS_PROFILE_DETAILS: '/family-ambassadors-profile-details',
  PROFILE_DETAILS: '/family-network/:type/:userId',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_OF_USE: '/terms-of-use',

  //auth routes
  SIGN_IN: '/auth-signin',
  SIGN_UP: '/auth-signup',
  FORGOT_PASSWORD: '/auth-forgot-password',
  RESET_PASSWORD: '/auth-reset-password',
  AUTH_LANDING_SCREEN: '/auth-landing-screen',
  AUTH_CLUB_HUB_SIGNUP: '/auth-clubhub-signup',
  AUTH_CLUB_HUB_SIGNIN: '/auth-clubhub-signin',
  AUTH_GRADUATE_SIGNUP: '/auth-graduate-signup',
  AUTH_GRADUATE_SIGNIN: '/auth-graduate-signin',
  AUTH_ADMIN_STAFF_SIGNIN: '/auth-admin-staff-signin',
  AUTH_ADMIN_STAFF_SIGNUP: '/auth-admin-staff-signup',
};

export { ROUTES };
