import React from 'react';
import COLORS from 'constants/colors';
// import { PROFILE_ACTIVITIES } from "constants/profiles";
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';
import Badge from '@mui/material/Badge';
import Container from '@mui/system/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Divider, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';

const Activities = ({ formData, updateFormData, allActivities }) => {
  const theme = useTheme();
  const [selectedActivities, setSelectedActivities] = React.useState(formData.activity_ids);

  const handleUpdateActivities = (activityId) => {
    let newSA;
    if (selectedActivities.includes(activityId)) {
      newSA = selectedActivities.filter((a) => a !== activityId);
    } else {
      newSA = [...selectedActivities, activityId];
    }
    updateFormData('activity_ids', newSA);
    setSelectedActivities(newSA);
  };

  const updateAbout = (aboutKey, aboutValue) => {
    const abouts = formData.about ? [...formData.about] : [];
    const foundAbout = abouts.find((a) => a.name === aboutKey);
    if (foundAbout) foundAbout.value = aboutValue;
    else abouts.push({ name: aboutKey, value: aboutValue });

    updateFormData('about', abouts);
  };

  return (
    <React.Fragment>
      <Container>
        <Typography mt={5} variant='body2'>
          Select at least 3
        </Typography>
        <Typography mb={4} variant='h5' fontWeight='bold'>
          Activities I Like
        </Typography>
        <Grid container spacing={2}>
          {allActivities?.map((data, index) => (
            <Grid item md={2}>
              <Badge
                className='MuiBadge-Profile-badge'
                badgeContent={
                  (selectedActivities ?? []).includes(data.id) ? (
                    <DoneIcon
                      onClick={() => handleUpdateActivities(data.id)}
                      style={{
                        marginTop: 25,
                        marginRight: 20,
                        cursor: 'pointer',
                        backgroundColor: `#15DA7B`,
                        color: `white`,
                        borderRadius: '15px',
                      }}
                    />
                  ) : (
                    <AddIcon
                      onClick={() => handleUpdateActivities(data.id)}
                      style={{
                        marginTop: 25,
                        marginRight: 20,
                        cursor: 'pointer',
                        backgroundColor: `${theme.palette.primary.main}`,
                        color: `white`,
                        borderRadius: '15px',
                      }}
                    />
                  )
                }
              >
                <Box
                  bgcolor={COLORS.white.secondary}
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  textAlign='center'
                  alignItems='center'
                  borderRadius={'100%'}
                  width='100px'
                  height='100px'
                >
                  <img src={data.icon.url} alt='' width='30px' />
                  <Typography color={COLORS.dark.lighter} variant='caption'>
                    {data.name}
                  </Typography>
                </Box>
              </Badge>
            </Grid>
          ))}
        </Grid>

        <Typography mb={2} mt={5} variant='h5' fontWeight='bold'>
          More About Me
        </Typography>

        <Grid container spacing={3}>
          <Grid item md={6} xs={12} sm={6}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Favorite Book to Read
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                fullWidth
                hiddenLabel
                InputProps={{ disableUnderline: 'false' }}
                sx={{ mb: 2 }}
                color='secondary'
                variant='filled'
                margin='normal'
                className='family-input-fields'
                value={formData?.about?.find((a) => a?.name === 'favorite_book')?.value}
                onChange={(e) => updateAbout('favorite_book', e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12} sm={6}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Favorite Movie
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                fullWidth
                hiddenLabel
                InputProps={{ disableUnderline: 'false' }}
                sx={{ mb: 2 }}
                color='secondary'
                variant='filled'
                margin='normal'
                className='family-input-fields'
                value={formData?.about?.find((a) => a?.name === 'favorite_movie')?.value}
                onChange={(e) => updateAbout('favorite_movie', e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12} sm={6}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Favorite Food
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                fullWidth
                hiddenLabel
                InputProps={{ disableUnderline: 'false' }}
                sx={{ mb: 2 }}
                color='secondary'
                variant='filled'
                margin='normal'
                className='family-input-fields'
                value={formData?.about?.find((a) => a?.name === 'favorite_food')?.value}
                onChange={(e) => updateAbout('favorite_food', e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12} sm={6}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Favorite Hobbies
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                fullWidth
                hiddenLabel
                InputProps={{ disableUnderline: 'false' }}
                sx={{ mb: 2 }}
                color='secondary'
                variant='filled'
                margin='normal'
                className='family-input-fields'
                value={formData?.about?.find((a) => a?.name === 'favorite_hobbies')?.value}
                onChange={(e) => updateAbout('favorite_hobbies', e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12} sm={6}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Favorite Sport
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                fullWidth
                hiddenLabel
                InputProps={{ disableUnderline: 'false' }}
                sx={{ mb: 2 }}
                color='secondary'
                variant='filled'
                margin='normal'
                className='family-input-fields'
                value={formData?.about?.find((a) => a?.name === 'favorite_sport')?.value}
                onChange={(e) => updateAbout('favorite_sport', e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12} sm={6}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Favorite Weekend Plan
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                fullWidth
                hiddenLabel
                InputProps={{ disableUnderline: 'false' }}
                sx={{ mb: 2 }}
                color='secondary'
                variant='filled'
                margin='normal'
                className='family-input-fields'
                value={formData?.about?.find((a) => a?.name === 'favorite_weekend_plan')?.value}
                onChange={(e) => updateAbout('favorite_weekend_plan', e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Favorite School Memory
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                fullWidth
                hiddenLabel
                multiline={true}
                rows={5}
                InputProps={{ disableUnderline: 'false' }}
                sx={{ mb: 2 }}
                color='secondary'
                variant='filled'
                margin='normal'
                className='family-input-fields'
                value={formData?.about?.find((a) => a?.name === 'favorite_school_memory')?.value}
                onChange={(e) => updateAbout('favorite_school_memory', e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Advice for Academic Success
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                fullWidth
                hiddenLabel
                multiline={true}
                rows={5}
                InputProps={{ disableUnderline: 'false' }}
                sx={{ mb: 2 }}
                color='secondary'
                variant='filled'
                margin='normal'
                className='family-input-fields'
                value={formData?.about?.find((a) => a?.name === 'advice_for_academic_success')?.value}
                onChange={(e) => updateAbout('advice_for_academic_success', e.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Box my={5}>
          <Divider />
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Activities;
