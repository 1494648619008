import React, { useContext, useState } from 'react';
import { RealAnswers } from './components/RealAnswers/index';
import { ThanksBox } from './components/ThanksBox/index';
import MultiSlider from './components/MultiSlider';
import QAContext from '../../contexts/QAContext';
import LoadingIndicator from '../../components/Utils/LoadingIndicator';

export const ManageQA = () => {
  const [selectedResponse, setSelectedResponse] = useState(0);
  const { qas, isLoading, error } = useContext(QAContext);

  const finalQA = qas.filter((q) => q.responses.length > 0);

  let flatList = finalQA.map(({ id, responses }) =>
    responses.map((video) => {
      return { ...video, id };
    })
  );

  flatList = flatList.flat(Infinity);

  return (
    <React.Fragment>
      <RealAnswers data={finalQA} isLoading={isLoading} />
      {isLoading ? (
        <LoadingIndicator />
      ) : error !== '' ? (
        error
      ) : (
        <MultiSlider
          qaData={flatList}
          data={finalQA}
          selectedResponse={selectedResponse}
          setSelectedResponse={setSelectedResponse}
        />
      )}

      <ThanksBox qaData={finalQA.find(({ id }) => id === flatList[selectedResponse].id)} isLoading={isLoading} />
    </React.Fragment>
  );
};

export default ManageQA;
