import React from 'react';
import { GraduatesHeader } from './components';
import { toast } from 'react-toastify';
import apiRequest from 'api/apiRequest';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Badge from '@mui/material/Badge';
import { SearchInputTextField } from './components';
import { GraduatesList } from './components/GraduatesList';
import { SelectClass } from './components/SelectClass';
import { SelectPrefix } from './components/SelectPrefix/index';

export const ManageGraduates = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [graduates, setGraduates] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [pages, setPages] = React.useState(null);
  const [query, setQuery] = React.useState('');
  const [classYear, setClassYear] = React.useState(null);
  const [prefix, setPrefix] = React.useState(null);

  const fetchGraduates = async (pageNumber = 1) => {
    try {
      setIsLoading(true);
      const resp = await apiRequest({
        method: 'get',
        url: `/graduates?page=${pageNumber}${classYear ? `&filter[attending_school_since][is]=${classYear}` : ''}${
          prefix ? `&filter[first_name][prefix]=${prefix}` : ''
        }${query !== '' ? `&search[query]=${encodeURIComponent(query)}` : ''}`,
      });

      setGraduates(resp.data);
      const numberOfPages = Math.ceil(resp.total / resp.per_page);
      console.log(numberOfPages);
      setPages(numberOfPages);
      setIsLoading(false);
    } catch (error) {
      toast.error(error);
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchGraduates(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  React.useEffect(() => {
    if (classYear) {
      setPage(1);
      fetchGraduates(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classYear]);

  React.useEffect(() => {
    if (prefix) {
      setPage(1);
      fetchGraduates(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefix]);

  const fetchNextPage = () => {
    if (page < pages) {
      const nextPage = page + 1;
      setPage(nextPage);
    }
  };

  const fetchPrevPage = () => {
    if (page > 1) {
      const nextPage = page - 1;
      setPage(nextPage);
    }
  };

  const handleSearch = () => {
    setPage(1);
    fetchGraduates(1);
  };

  return (
    <React.Fragment>
      <GraduatesHeader />
      <SearchInputTextField query={query} setQuery={setQuery} onSearch={handleSearch} />
      <SelectClass classYear={classYear} setClassYear={setClassYear} />
      <SelectPrefix prefix={prefix} setPrefix={setPrefix} />
      <GraduatesList graduates={graduates} isLoading={isLoading} />
      {pages > 1 && (
        <Box display='flex' justifyContent='center' alignItems='center'>
          <Grid>
            <Button disabled={page === 1} onClick={fetchPrevPage} style={{ fontSize: '20px' }}>
              {'<'}
            </Button>
            <Badge badgeContent={page} />
            <Button style={{ fontSize: '20px' }} onClick={fetchNextPage} disabled={page >= pages}>
              {'>'}
            </Button>
          </Grid>
        </Box>
      )}
    </React.Fragment>
  );
};

export default ManageGraduates;
