import React, { useEffect } from "react";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/system/Container";
import Typography from "@mui/material/Typography";
import AmbassadorCard from "pages/ManageFamilyNetwork/components/AmbassadorCard";
import COLORS from "constants/colors";
import SelectFamilies from "../SelectFamilies";
import DeleteModal from "../DeleteModal";


import apiRequest from 'api/apiRequest';
import urls from 'api/urls';

export const Families = (props) => {
  const [isSelectFamily, setIsSelectFamily] = React.useState(false);

  const [familyToDelete, setFamilyToDelete] = React.useState(null);

  const [families, setFamilies] = React.useState([]);

  useEffect(() => {
    if (props.families.length !== families.length) {
      setFamilies(props.families);
    }
  }, [props.families]);

  useEffect(() => {
    if (props.onChange) props.onChange(families);
  }, [families]);

  const removeFamily = () => {
    if (familyToDelete) {
      setFamilies(families.filter(f => f !== familyToDelete));
    }
  };

  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Box mb={5} mt={7}>
          <Grid container>
            <Grid md={5}>
              <Typography variant="h4" fontWeight="bold">
                Families Following this Club:
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {/* <Grid container spacing={3}>
          {families.map((family) => (
            <Grid item md={4} sm={12} xs={12}>
              <AmbassadorCard
                onDelete={() => setFamilyToDelete(family)}
                id="1"
                image="/assets/images/cardImage.jpg"
                userName={family.name}
              />
            </Grid>
          ))}
        </Grid> */}
      </Container>

        <SelectFamilies
          families={families}
          setFamilies={setFamilies}
        />
      
      <DeleteModal
        title="Delete Family"
        text="Are you sure you want to delete the
        Thomson Family from this section?"
        setIsDeleteModal={setFamilyToDelete}
        isDeleteModal={familyToDelete}
        onConfirm={() => removeFamily()}
        onCancel={() => setFamilyToDelete(null)}
      />
    </React.Fragment>
  );
};

export default Families;
