import { createContext, useState } from 'react';
import { toast } from 'react-toastify';
import apiRequest from '../api/apiRequest';
import urls from '../api/urls';

const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState(null);
  const [familyProfile, setFamilyProfile] = useState(null);
  const [staffProfile, setStaffProfile] = useState(null);
  const [graduateProfile, setGraduateProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [error, setError] = useState('');

  const updateImage = async ({ userId, imageFile, imageId, ownerType }) => {
    try {
      const formData = new FormData();
      formData.append('name', 'profile');
      formData.append('image', imageFile);
      formData.append('owner_id', userId);
      formData.append('owner_type', ownerType);
      if (imageId) formData.append('_method', 'PUT');
      const url = imageId ? `${urls.updateProfileImage}/${imageId}` : urls.updateProfileImage;
      const response = await apiRequest({
        method: 'post',
        url,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (ownerType === 'User') {
        setProfile({
          ...profile,
          profile_image: response,
        });
      } else if (ownerType === 'Family') {
        setFamilyProfile({
          ...familyProfile,
          profile_image: response,
        });
      }
    } catch (error) {
      setError(error);
      setIsLoading(false);
      setProfile(null);
    }
  };

  const fetchProfile = async (userId) => {
    try {
      setIsLoading(true);
      const response = await apiRequest({
        method: 'get',
        url: `${urls.userProfile}/${userId}`,
      });

      setProfile(response.data);
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
      setProfile(null);
    }
  };

  const updateProfile = async (userId, profileDetails) => {
    try {
      const response = await apiRequest({
        method: 'put',
        url: `${urls.updateUserProfile}/${userId}`,
        data: {
          first_name: profileDetails.firstName,
          last_name: profileDetails.lastName,
          title: profileDetails.title,
          email: profileDetails.email,
          phone_number: profileDetails.phone,
          attending_school_since: profileDetails.classYear,
          location: {
            city: profileDetails.city,
            country: profileDetails.country,
            subdivision: profileDetails.state,
            code: profileDetails.zipCode,
          },
        },
      });
      setProfile(response.data);
    } catch (error) {
      setError(error);
      throw error;
    }
  };

  const savePreferences = async (user, preference_ids) => {
    try {
      setLoadingButton(true);
      const response = await apiRequest({
        method: 'put',
        url: `${urls.userEnabledPreferences}/me/preferences`,
        data: { preference_ids },
      });
      console.log(response);
      setLoadingButton(false);
      toast.success('Changes Saved!');
    } catch (error) {
      toast.error('Something went wrong!');
      // setError(error);
      throw error;
    }
  };

  //family profiles
  const fetchFamilyProfile = async (familyUserId) => {
    try {
      setIsLoading(true);
      const response = await apiRequest({
        method: 'get',
        url: `/families/${familyUserId}`,
      });
      setFamilyProfile(response.data);
      setIsLoading(false);
    } catch (error) {
      toast.error(error);
      setIsLoading(false);
    }
  };

  //staff profiles
  const fetchStaffProfile = async () => {
    try {
      setIsLoading(true);
      // console.log('Getting staff profile');
      const response = await apiRequest({
        method: 'get',
        url: `/staff/me`,
      });
      // console.log('Got staff profile');
      // console.log(response.data);

      const data = {
        ...response.data.staff_profile,
        title: response.data?.title,
        attending_school_since: response.data?.attending_school_since,
        first_name: response.data?.first_name,
        last_name: response.data?.last_name,
        social_media_accounts: response.data?.social_media_accounts ? response.data?.social_media_accounts : [],
        about: response.data?.about?.fields ? response.data?.about?.fields : [],
        activity_ids: response.data?.activities ? response.data?.activities.map((a) => a.id) : [],
        educations: response.data?.educations ? response.data?.educations.map((e) => ({ ...e, ourId: e.id })) : [],
        profile_image: response.data?.profile_image ? response.data?.profile_image : null,
        reviews: response.data?.staff_profile?.reviews ? response.data?.staff_profile?.reviews : [],
        quote: response.data?.quote ? response.data?.quote : '',
        about_media: response.data?.about_media ? response.data?.about_media : null,
      };

      setStaffProfile(data);
      setIsLoading(false);
    } catch (error) {
      toast.error(error);
      setIsLoading(false);
    }
  };

  //graduate profiles
  const fetchGraduateProfile = async () => {
    try {
      setIsLoading(true);
      // console.log('Getting staff profile');
      const response = await apiRequest({
        method: 'get',
        url: `/graduates/me`,
      });
      console.log('Got graduate profile');
      console.log(response.data);

      const data = {
        first_name: response.data?.first_name,
        last_name: response.data?.last_name,
        email: response.data?.email?.value,
        attending_school_since: response.data?.attending_school_since,
        social_media_accounts: response.data?.social_media_accounts ? response.data?.social_media_accounts : [],
        is_primary_email_visible: response.data?.is_primary_email_visible,
        about: response.data?.about?.fields ? response.data?.about?.fields : [],
        activity_ids: response.data?.activities ? response.data?.activities.map((a) => a.id) : [],
        educations: response.data?.educations ? response.data?.educations.map((e) => ({ ...e, ourId: e.id })) : [],
        profile_image: response.data?.profile_image ? response.data?.profile_image : null,
        // reviews: response.data?.reviews ? response.data?.reviews : [],
        // quote: response.data?.quote ? response.data?.quote : '',
        // about_media: response.data?.about_media ? response.data?.about_media : null,
      };

      setGraduateProfile(data);
      setIsLoading(false);
    } catch (error) {
      toast.error(error);
      setIsLoading(false);
    }
  };

  const clearProfile = () => {
    setProfile(null);
  };

  const clearFamilyProfile = () => {
    setFamilyProfile(null);
  };

  const clearAllProfiles = () => {
    setProfile(null);
    setFamilyProfile(null);
  };

  return (
    <ProfileContext.Provider
      value={{
        profile,
        isLoading,
        setIsLoading,
        error,
        loadingButton,
        familyProfile,
        fetchProfile,
        updateProfile,
        updateImage,
        savePreferences,
        fetchFamilyProfile,
        fetchStaffProfile,
        setFamilyProfile,
        staffProfile,
        setStaffProfile,
        clearProfile,
        clearFamilyProfile,
        clearAllProfiles,
        fetchGraduateProfile,
        graduateProfile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileContext;
