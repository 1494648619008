import React from 'react';
import Box from '@mui/system/Box';
import Paper from '@mui/material/Paper';
// import Button from '@mui/material/Button';
import Container from '@mui/system/Container';
import Typography from '@mui/material/Typography';
import { Divider, Grid, TextField, IconButton } from '@mui/material';
import Loader from 'components/Global/Loader';
import COLORS from 'constants/colors';
import { useTheme } from '@mui/material/styles';
import { Send } from '@mui/icons-material';
import MessagingContext from 'contexts/MessagingContext';
import AuthContext from 'contexts/AuthContext';
import moment from 'moment';
import useWindowSize from 'hooks/useWindowSize';
import { useLocation } from 'react-router-dom';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import urls from 'api/urls';

export const Messages = (props) => {
  // const { isUserProfile, setNewMessage, isModal } = props || {};
  const { isUserProfile, isModal } = props || {};
  const theme = useTheme();
  const { dialogues, fetchDialogues, getMessages, sendMessage } = React.useContext(MessagingContext);
  const { token, user } = React.useContext(AuthContext);
  const [isMessagesLoading, setIsMessagesLoading] = React.useState(true);
  const [isThreadLoading, setIsThreadLoading] = React.useState(false);
  const [isSmallScreen, setIsSmallScreen] = React.useState(false);
  const [isSendingMessage, setIsSendingMessage] = React.useState(false);
  const [currentView, setCurrentView] = React.useState('list');
  const [activeThread, setActiveThread] = React.useState(null);
  const [messages, setMessages] = React.useState([]);
  const [newMessageBody, setNewMessageBody] = React.useState('');
  const size = useWindowSize();
  const messagesRef = React.useRef();
  messagesRef.current = messages;
  window.Pusher = Pusher;

  const { search } = useLocation();
  function useQuery() {
    return React.useMemo(() => new URLSearchParams(search), []);
  }

  const query = useQuery();
  const dialogueIdFromUrl = query.get('dialogue_id') ? query.get('dialogue_id').toLowerCase() : null;

  React.useEffect(() => {
    window.echo = new Echo({
      broadcaster: 'pusher',
      key: process.env.REACT_APP_PUSHER_KEY,
      forceTLS: true,
      cluster: 'mt1',
      authEndpoint: process.env.REACT_APP_BROADCASTING_AUTH_URL,
      auth: {
        headers: {
          Authorization: token,
          'X-Think12-Program-Id': urls.programId(),
        },
      },
    });
    window.echo.connect();
    return () => {
      window.echo.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (dialogues.length) {
      const foundDialogue = dialogues.find((d) => d.id === parseInt(dialogueIdFromUrl));
      setActiveThread(foundDialogue || dialogues[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogues]);

  React.useEffect(() => {
    if (user) getDialogues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getDialogues = async () => {
    setIsMessagesLoading(true);
    await fetchDialogues();
    setIsMessagesLoading(false);
  };

  React.useEffect(() => {
    if (size.width < 900) setIsSmallScreen(true);
    else setIsSmallScreen(false);
  }, [size]);

  React.useEffect(() => {
    if (activeThread) {
      async function fetchMessages() {
        setIsThreadLoading(true);
        const resp = await getMessages(user.id, activeThread.id);
        setMessages(resp.reverse());
        setIsThreadLoading(false);
      }
      fetchMessages();
      window.echo.private(`Dialogue.${activeThread.id}`).listen('Api.v1.Message.MessageCreated', (event) => {
        const msg = event.message;
        if (msg.dialogue_id === activeThread?.id) {
          if (!messagesRef.current.find((m) => m.id === msg.id)) {
            setMessages([msg, ...messagesRef.current]);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeThread, getMessages]);

  const handleSendMessage = async () => {
    if (newMessageBody.trim() !== '') {
      const body = newMessageBody.trim();
      setNewMessageBody('');
      setIsSendingMessage(true);
      const foundUser = activeThread.participants.find((p) => p.id !== user.id);
      await sendMessage([user.id, foundUser.id], body);
      setIsSendingMessage(false);
    }
  };

  return (
    <React.Fragment>
      <Container>
        {isModal ? (
          ''
        ) : (
          <Box alignItems='center' display='flex' justifyContent='space-between'>
            <Typography variant='h5' fontWeight='bold'>
              Messages
            </Typography>
            {/* <Button
              sx={{ width: '153px', height: '45px', fontWeight: 'bold', fontSize: '18px' }}
              className='MuiButton-profile'
              variant='contained'
              color='secondary'
              onClick={() => setNewMessage(true)}
            >
              New Message
            </Button> */}
          </Box>
        )}
        {!isUserProfile && <Box mt={5}>chat</Box>}

        <Box mt={5}>
          <Paper sx={{ borderRadius: '26px', p: 3, pb: 0, mb: 5 }} elevation={4}>
            <Box display='flex' alignItems='center' onClick={() => setCurrentView('list')} height='20px'>
              <svg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V13C0 13.7956 0.316071 14.5587 0.87868 15.1213C1.44129 15.6839 2.20435 16 3 16H17C17.7956 16 18.5587 15.6839 19.1213 15.1213C19.6839 14.5587 20 13.7956 20 13V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3L10 7.88L2 3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2ZM18 13C18 13.2652 17.8946 13.5196 17.7071 13.7071C17.5196 13.8946 17.2652 14 17 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V5.28L9.48 9.85C9.63202 9.93777 9.80446 9.98397 9.98 9.98397C10.1555 9.98397 10.328 9.93777 10.48 9.85L18 5.28V13Z'
                  fill={theme.palette.primary.main}
                />
              </svg>
              {/* <img src='/assets/icons/user-profile-back-icon.svg' width='50px' alt='' style={{ cursor: 'pointer' }} /> */}
              <Typography ml={3} variant='h6' fontWeight='bold'>
                Inbox
              </Typography>
            </Box>
            <Box mt={2}>
              <Divider />
            </Box>
            {isMessagesLoading ? (
              <Loader height='465px' />
            ) : (
              <Grid container display='flex' overflow='hidden'>
                <Box
                  sx={{
                    backgroundColor: 'white',
                    width: isSmallScreen ? '100%' : '30%',
                    borderRight: `1px solid rgba(0, 0, 0, 0.12)`,
                    overflowY: 'scroll',
                    height: '465px',
                    display: isSmallScreen ? (currentView === 'list' ? 'block' : 'none') : 'block',
                  }}
                >
                  {dialogues.length === 0
                    ? ''
                    : dialogues.map((d) => {
                        const participant = d.participants.find((p) => p.id !== user.id);
                        return (
                          <ChatListItem
                            title={participant?.full_name}
                            avatar={
                              participant?.profile_image?.url
                                ? participant?.profile_image?.url
                                : '/assets/images/profiles-admin.svg'
                            }
                            dayDate={moment(d.updated_at).format('ddd - MMMM D')}
                            onClick={() => {
                              setActiveThread(d);
                              setCurrentView('thread');
                            }}
                            isActive={activeThread?.id === d.id}
                          />
                        );
                      })}
                </Box>
                <Box
                  sx={{
                    width: isSmallScreen ? '100%' : '70%',
                    display: isSmallScreen ? (currentView === 'thread' ? 'block' : 'none') : 'block',
                    height: '465px',
                  }}
                >
                  {isThreadLoading ? (
                    <Loader height='400px' />
                  ) : (
                    <Box
                      sx={{
                        backgroundColor: 'white',
                        width: '100%',
                        overflowY: 'auto',
                        paddingTop: '1em',
                        height: '380px',
                        display: 'flex',
                        flexDirection: 'column-reverse',
                      }}
                    >
                      {messages.map((m) => {
                        return (
                          <MessageBubble
                            self={m.sender_user_id === user.id ? true : false}
                            body={m.body}
                            dateTime={moment(m.created_at).format('HH:mm A, ddd - MMMM D')}
                          />
                        );
                      })}
                    </Box>
                  )}

                  <Divider />
                  <MessageBox
                    handleSendMessage={handleSendMessage}
                    newMessageBody={newMessageBody}
                    setNewMessageBody={setNewMessageBody}
                    isSendingMessage={isSendingMessage}
                  />
                </Box>
              </Grid>
            )}
          </Paper>
        </Box>
      </Container>
    </React.Fragment>
  );
};

const MessageBox = ({ handleSendMessage, newMessageBody, setNewMessageBody, isSendingMessage }) => {
  return (
    <Box ml={2} mt={2} sx={{ position: 'relative' }} className='MessageBox'>
      <TextField
        fullWidth
        size='small'
        placeholder='Type your reply here'
        value={newMessageBody}
        onChange={(e) => setNewMessageBody(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === 'Enter' && !isSendingMessage) handleSendMessage();
        }}
      />
      <IconButton
        sx={{ position: 'absolute', right: '0', top: '50%', transform: 'translateY(-50%)' }}
        onClick={handleSendMessage}
        disabled={isSendingMessage}
      >
        <Send />
      </IconButton>
    </Box>
  );
};

const ChatListItem = ({ title, dayDate, avatar, onClick, isActive }) => {
  return (
    <Box className='ChatListItem' onClick={onClick} sx={{ backgroundColor: isActive ? '#F3F4F4' : 'white' }}>
      {/* <Box sx={{ width: '10%', textAlign: 'center' }}>
        <Checkbox />
      </Box> */}
      <Box sx={{ width: '15%', textAlign: 'center' }}>
        <Box
          sx={{ borderRadius: '50%', overflow: 'hidden', width: '25px', lineHeight: '0.6', display: 'inline-block' }}
        >
          <img src={avatar} alt={title} width='100%' height='25px' />
        </Box>
      </Box>
      <Box sx={{ width: '85%' }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: '1em' }}>{title}</Typography>
        <Typography sx={{ color: COLORS.grey.thinner, fontSize: '0.8em' }}>{dayDate}</Typography>
      </Box>
    </Box>
  );
};

const MessageBubble = ({ body, self = true, dateTime }) => {
  return (
    <Box
      className='MessageBubble'
      sx={{
        backgroundColor: self ? '#F3F4F4' : '#EFF7FF',
        marginLeft: self ? '1em' : 'auto',
        marginRight: self ? 'auto' : '1em',
        position: 'relative',
        paddingBottom: '25px',
      }}
    >
      <Typography
        sx={{ position: 'absolute', fontSize: '0.8em', color: COLORS.grey.greyOnGrey, bottom: '5px', right: '15px' }}
      >
        {dateTime}
      </Typography>
      <Typography sx={{ fontSize: '0.9em' }}>{body}</Typography>
    </Box>
  );
};

export default Messages;
