import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import apiRequest from "../../../../api/apiRequest";
import urls from "../../../../api/urls";

import { MoreAboutUs } from "./../MoreAboutUs/index";
import { AmbassadorsProfileDetails } from "./../AmbassadorsProfileDetails/index";
// import { AmbassadorsProfileAlbum } from "./../AmbassadorsProfileAlbum/index";
// import { AmbassadorsProfileActivities } from "./../AmbassadorsProfileActivities/index";
import { AmbassadorsBioAndFavoriteMemory } from "./../AmbassadorsBioAndFavoriteMemory/index";
import { useParams } from "react-router-dom";
import LoadingIndicator from "../../../../components/Utils/LoadingIndicator";

export const FamilyAmbassadorsProfileDetails = () => {
  const { type, userId } = useParams();
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    apiRequest({
      url:
        type === "ambassador"
          ? `${urls.ambassadors}/${userId}`
          : `${urls.family}/${userId}`,
    }).then((resp) => {
      setProfile(resp.data);
      setIsLoading(false);
    });
  }, [type, userId]);

  return (
    <React.Fragment>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <React.Fragment>
          <Box p={{ xs: 2 }}>
            <AmbassadorsProfileDetails profile={profile} type={type} />
            <AmbassadorsBioAndFavoriteMemory profile={profile} type={type} />

            {/* {isFeatureEnabled("family_albums") && (
              <AmbassadorsProfileAlbum profile={profile} type={type} />
            )} */}
            {/* {isFeatureEnabled("family_activities") && (
              <AmbassadorsProfileActivities profile={profile} type={type} />
            )} */}
          </Box>
          <MoreAboutUs profile={profile} type={type} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default FamilyAmbassadorsProfileDetails;
