import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { AuthLandingScreen } from 'pages/ManageAuth/components/AuthLandingScreen/index';
import AuthSignInForm from '../AuthSignInForm';
import { SignUpSnackBar } from './../CreateAccount/components/SignUpSnackBar/index';
import { useTheme } from '@mui/material/styles';
import AuthContext from '../../../../contexts/AuthContext';
import { Redirect, useLocation } from 'react-router-dom';

export const LoginAccount = () => {
  const theme = useTheme();
  const history = useHistory();
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertType, setAlertType] = React.useState('');
  const { isLoading, isAuthenticated, login, error } = useContext(AuthContext);
  const { search } = useLocation();
  const useQuery = () => {
    return React.useMemo(() => new URLSearchParams(search), []);
  };
  const query = useQuery();

  useEffect(() => {
    if (error !== '') {
      setAlertType('Failed');
      setAlertMessage(error);
      // setShowAlert(true);
    } else {
      // setShowAlert(false);
    }
  }, [error]);

  if (isAuthenticated) {
    return <Redirect to='/' />;
  }

  const handleLogin = (email, password) => {
    const redirectUrl = query.get('redirect') ? query.get('redirect').toLowerCase() : null;

    login(email, password, redirectUrl);
  };

  return (
    <AuthLandingScreen height='1070px'>
      <AuthSignInForm
        OR
        appleButton
        googleButton
        facebookButton
        textColor='primary'
        title='Welcome Back!'
        authButtonText='Sign In'
        navigationButtonText='Sign Up Here'
        authHelperText={`Don't have an account yet?`}
        authButtonAction={handleLogin}
        navigationButtonTextAction={() => history.push(ROUTES.SIGN_UP)}
        isLoading={isLoading}
      />

      {error !== '' ? (
        <SignUpSnackBar
          familyLogin
          width={{ xs: 250, sm: 600, md: '1200px' }}
          open={error !== ''}
          close={() => {}}
          status={alertType}
          message={alertMessage}
          borderColor={theme.palette.primary.main}
        />
      ) : (
        ''
      )}

      {/* <AuthForm
        signInForm
        title="Welcome Back!"
        textColor="primary"
        authButtonText="Sign In"
        navigationButtonText="Sign Up Here"
        buttonVariant="contained"
        buttonBgColor={COLORS.grey.lightGrey}
        navigationButtonTextAction={() => history.push(ROUTES.SIGN_UP)}
      /> */}
    </AuthLandingScreen>
  );
};

export default LoginAccount;
