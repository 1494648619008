import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

export const SelectQAText = (props) => {
  const theme = useTheme();

  return (
    <Box
      width={80}
      height={80}
      sx={{
        position: 'absolute',
        bottom: props.title === 'A.' ? -110 : 'unset',
        top: props.title === 'Q.' ? -50 : 'unset',
        left: '50%',
        transform: 'translateX(-50%)',
        borderRadius: '50%',
        bgcolor: props.title === 'A.' ? theme.palette.primary.main : theme.palette.secondary.main,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography fontFamily='Proxima-Nova-Black' fontSize={{ md: 40 }} color='#FFFF'>
        {props.title}
      </Typography>
    </Box>
  );
};
export default SelectQAText;
