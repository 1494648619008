import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export const Heading = () => {
  return (
    <Container maxWidth='xl' style={{ marginTop: '30px' }}>
      <Grid container xs={12} sm={10} md={10}>
        <Grid item>
          <Typography
            gutterBottom
            variant='h6'
            sx={{
              color: '#131416',
              fontWeight: '900',
              lineHeight: '58px',
              fontSize: '50px',
              fontStyle: 'normal',
            }}
          >
            Beyond academics there’s a world of activity for your child to
            explore.
          </Typography>
          <Typography
            variant='body2'
            color='textSecondary'
            style={{
              fontWeight: '400',
              lineHeight: '28px',
              fontStyle: 'normal',
              marginBottom: '100px',
            }}
          >
            Help them find their passion.
          </Typography>
        </Grid>
        <Grid item sm={2} md={2}></Grid>
      </Grid>
    </Container>
  );
};
