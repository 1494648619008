import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';

export const SelectClass = ({ classYear, setClassYear }) => {
  const CALENDAR_YEARS = [2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023];
  const theme = useTheme();

  return (
    <Container>
      <Box
        display={{ lg: 'flex', md: 'flex', sm: 'none', xs: 'none' }}
        alignItems={'center'}
        mt={8}
        sx={{ justifyContent: 'center' }}
      >
        <Typography mr={2} variant='body1' color={theme.palette.secondary.main} fontWeight='bold'>
          Select Class
        </Typography>
        {CALENDAR_YEARS.map((data) => (
          <Box ml={1}>
            <Chip
              sx={{ px: 2 }}
              label={data}
              variant={data === classYear ? 'filled' : 'outlined'}
              color='secondary'
              onClick={() => setClassYear(data)}
            />
            <Box
              sx={{
                width: '30px',
                height: '3px',
                backgroundColor: data === classYear ? theme.palette.secondary.main : 'transparent',
                marginTop: '5px',
                marginX: 'auto',
                borderRadius: '10px',
              }}
            ></Box>
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default SelectClass;
