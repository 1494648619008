import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import GraduateCard from '../GraduateCard';
import LoadingIndicator from 'components/Utils/LoadingIndicator';

export const GraduatesList = ({ graduates, isLoading }) => {
  return (
    <Box mt={15} mb={10} marginX={5}>
      {/* <Container > */}
      {isLoading ? (
        <Box justifyContent='center' mt={6}>
          <LoadingIndicator />
        </Box>
      ) : (
        <Box justifyContent='center' mt={6} px={{ sm: 2, md: 20 }}>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            container
            rowSpacing={3}
            columnSpacing={3}
            alignItems='center'
            justifyContent='center'
          >
            {graduates.map((item, index) => (
              <Grid item md={3}>
                <Link to={`/staff-directory/${item.id}`}>
                  <GraduateCard
                    image={item?.profile_image?.url}
                    userName={item?.first_name}
                    educations={item?.educations}
                  />
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {/* </Container> */}
    </Box>
  );
};

export default GraduatesList;
