import React from 'react';
import COLORS from 'constants/colors';
import AddSocialLinks from '../AddSocialLinks';
import Box from '@mui/system/Box';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import Container from '@mui/system/Container';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

export const SocialLinks = ({ formData, updateFormData }) => {
  const [IsAddSocialLinks, setIsAddSocialLinks] = React.useState(false);

  const handleUpdate = (provider, url) => {
    const newSocialMedia = [...formData.social_media_accounts];
    newSocialMedia.find((p) => p.provider === provider).url = url;
    updateFormData('social_media_accounts', newSocialMedia);
  };

  return (
    <React.Fragment>
      <Container>
        <Box mt={5} display='flex' justifyContent='space-between'>
          <Typography variant='body1' fontWeight='bold'>
            Primary Social Media Links
          </Typography>
          {/* <img src='/assets/icons/profile-edit-icon.svg' alt='' style={{ cursor: 'pointer' }} /> */}
        </Box>
        <Grid container mt={2} spacing={3}>
          {formData?.social_media_accounts?.map((sm, index) => {
            return (
              <Grid item md={4} xs={12} sm={6}>
                <InputLabel className='MuiInputLabel-Profile-label' shrink>
                  {sm.provider}
                </InputLabel>
                <FormControl fullWidth>
                  <TextField
                    InputProps={{ disableUnderline: 'false' }}
                    variant='filled'
                    hiddenLabel
                    required
                    fullWidth
                    id='last-name'
                    name='last-name'
                    className='family-input-fields'
                    sx={{ mb: 2 }}
                    value={sm.url}
                    onChange={(e) => handleUpdate(sm.provider, e.target.value)}
                  ></TextField>
                </FormControl>
              </Grid>
            );
          })}
        </Grid>
        <Box my={2}>
          <Box display='flex' alignItems='center'>
            <Fab color='primary' onClick={() => setIsAddSocialLinks(!IsAddSocialLinks)}>
              <AddIcon />
            </Fab>
            <Typography ml={3} variant='body1' fontWeight='bold' color={COLORS.primary.main}>
              Add a new social media link
            </Typography>
          </Box>
        </Box>
        {IsAddSocialLinks && (
          <Box mt={3}>
            <AddSocialLinks
              setIsAddSocialLinks={setIsAddSocialLinks}
              updateFormData={updateFormData}
              formData={formData}
            />
          </Box>
        )}
        <Box my={5}>
          <Divider />
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default SocialLinks;
