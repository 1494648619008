import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect } from "react"
import useTheme from "@mui/material/styles/useTheme";
import urls from 'api/urls';

export const LeadersContact = (props) => {
  const theme = useTheme();
  const [leader1, setLeader1] = React.useState(null);
  const [leader2, setLeader2] = React.useState(null);
  const [leader3, setLeader3] = React.useState(null);

  useEffect(() => {
    if (props.club && props.club.leaders) {
      setLeader1(props.club.leaders[0]);
      setLeader2(props.club.leaders[1]);
      setLeader3(props.club.leaders[2]);
    }
  }, [props.club]);

  if (!leader1) {
    return null;
  }
  return (
    <Container maxWidth="xl" className="club-page-leaders">
      <Typography
        variant="h5"
        color="#131416"
        fontSize={{ md: 30 }}
        fontWeight={700}
        mb={"20px"}
      >
        Contact our leaders to learn more about our club
      </Typography>
 
      <Grid container spacing={3} mb={5}>
        {leader1 ? (
          <Grid item xs={12} md={5}>
            <Card elevation={0} className="club-leader-container big">
              <CardMedia style={{maxHeight:'900px'}}
                component="img"
                className="club-leader-image"
                image={
                  leader1.image
                    ? urls.getApiDomain() + leader1.image.path
                    : "/assets/images/profiles-admin.svg"
                }
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  fontWeight={900}
                >
                  {leader1.user.first_name} {leader1.user.last_name}
                </Typography>
                <Typography variant="body2" color="text.secondary"></Typography>
              </CardContent>
            </Card>
          </Grid>
        ) : null}

        
          <Grid item xs={12} md={4} mt={5}>
            <Grid spacing={3} direction={"column"}>
              <Grid item xs={12} md={12}>

                <Box style={{position:'relative'}}>
                    {leader2 ? <Card className="club-leader-card" elevation={0}>
                      <CardMedia
                        component="img"
                        className="club-leader-image"
                        image={
                          leader2.image_id
                            ? urls.getApiDomain() + leader2.image.path
                            : "/assets/images/profiles-admin.svg"
                        }
                      />
                      <CardContent className="club-leader-text">
                        <Typography
                          variant="h5"
                          component="div"
                          fontWeight={900}
                        >
                          {leader2.user.first_name} {leader2.user.last_name}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                        >
                          {leader1.title}
                        </Typography>
                      </CardContent>
                    </Card> : []}
                  </Box>

                    <Box style={{position:'relative'}}>
                      {leader3 ? <Card className="club-leader-card" elevation={0}>
                        <CardMedia
                          component="img"
                          className="club-leader-image"
                          image={
                            leader3.image_id
                            ? urls.getApiDomain() + leader3.image.path
                            : "/assets/images/profiles-admin.svg"
                          }
                        />
                       <CardContent className="club-leader-text">
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          fontWeight={900}
                        >
                          {leader3.user.first_name} {leader3.user.last_name}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                        >
                          {leader3.title}
                        </Typography>
                      </CardContent>
                      </Card> : []}
                    </Box>
              </Grid>
            </Grid>
          </Grid>
        
      </Grid>
    </Container>
  );
};
