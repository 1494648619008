import { createContext, useState, useEffect } from 'react';
import apiRequest from '../api/apiRequest';
import urls from '../api/urls';

const ConfigurationContext = createContext();

export const ConfigurationProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [gradeBands, setGradeBands] = useState([]);
  const [activities, setActivities] = useState([]);
  const [configurations, setConfigurations] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    fetchConfigurations();
    fetchSchools();
    fetchArticlesCategories();
    fetchActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchActivities = async () => {
    try {
      // setIsLoading(true);
      const response = await apiRequest({
        method: 'get',
        url: urls.activities,
      });
      setActivities(response.data);
      // setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  const isFeatureEnabled = (featureTag) => {
    if (!configurations.features) return false;
    const features = configurations.features;
    const foundFeature = features.find((f) => f.tag === featureTag);
    if (!foundFeature) return false;
    return foundFeature.is_frontend_useable;
  };

  const fetchSchools = async () => {
    try {
      // setIsLoading(true);
      const response = await apiRequest({
        method: 'get',
        url: urls.outcomes,
      });
      setSchools(response.data);
      // setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  const fetchArticlesCategories = async () => {
    try {
      // setIsLoading(true);
      const response = await apiRequest({
        method: 'get',
        url: urls.articlesCategories,
      });
      setCategories(response.data);
      // setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  const fetchConfigurations = async () => {
    try {
      setIsLoading(true);
      if (localStorage.configurations) {
        const configs = JSON.parse(localStorage.getItem('configurations'));
        setGradeBands(configs.gradeBands);
        setConfigurations(configs);
        setIsLoading(false);
      }

      const response = await apiRequest({
        method: 'get',
        url: urls.configurations,
      });
      localStorage.setItem('configurations', JSON.stringify(response.data));
      setGradeBands(response.data.gradeBands);
      setConfigurations(response.data);

      if (isLoading) setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  return (
    <ConfigurationContext.Provider
      value={{
        categories,
        gradeBands,
        configurations,
        activities,
        schools,
        isLoading,
        error,
        fetchConfigurations,
        fetchSchools,
        fetchActivities,
        fetchArticlesCategories,
        isFeatureEnabled,
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};

export default ConfigurationContext;
