import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthContext from 'contexts/AuthContext';
import { ROUTES } from 'constants/routes';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, isLoading } = React.useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated && !isLoading ? (
          <Redirect to={`${ROUTES.SIGN_IN}?redirect=${encodeURIComponent(window.location.href)}`} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PrivateRoute;
