import React, { useContext, useState, useEffect } from 'react';
import { Box, Container } from '@mui/system';

import Hero from './components/Hero';
import OutcomeArticleSection from './components/OutcomeArticleSection';
import UniversityGrid from './components/UniversityGrid';
import Quote from './components/Quote';
import { Think12Button as Button } from 'components/Global/Button';
import { BackgroundCircle } from 'components/Global/BackgroundCircle';
import Infographic1 from '../../assets/images/Delbarton_Infographic3 1.png';
import Infographic2 from '../../assets/images/Delbarton_Infographic2 1.png';
// import Infographic3 from '../../assets/images/Delbarton_Infographic1 1.png';
import Infographic4 from '../../assets/images/delbardon_infographics.png';
import { theme } from 'styles/theme';
import './manage-outcomes.css';
import apiRequest from '../../api/apiRequest';
import urls from '../../api/urls';
import ConfigurationContext from '../../contexts/ConfigurationContext';

export const ManageOutcomes = () => {
  const { configurations } = useContext(ConfigurationContext);

  console.log('configurations', configurations)

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [outcomesContent, setOutcomesContent] = useState([]);

  const fetchOutcomesContent = async () => {
    try {
      const response = await apiRequest({
        method: 'get',
        url: `outcome-content`,
      });
      setOutcomesContent(response);
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOutcomesContent();
  }, []);

  return <Box className='manage-outcomes-page'>
    {outcomesContent.map((content, index) => <div dangerouslySetInnerHTML={{__html: content.content}}>
    </div>)}
  </Box>
  
  return (
    <Box className='manage-outcomes-page'>
      <Hero />
      <Box
        sx={{
          mt: 8,
          width: { xs: '100%' },
          padding: { xs: 0 },
        }}
      >
        <OutcomeArticleSection />
      </Box>

      <Box sx={{ mt: { xs: 8, sm: 28 } }}>
        <Quote
          quoteText={`${
            configurations.schoolShortName ? configurations.schoolShortName : ''
          } was the best thing we could have done for our son. He was happy, positive, engaged and energized his entire 4 years at the school.`}
          quoteUser='Parent of a graduating senior'
        />
      </Box>
      <UniversityGrid />
      <Box className='page-quote' sx={{ pt: { xs: 8, sm: 36 }, pb: { xs: 8, sm: 36 } }}>
        <Quote
          quoteText={`I think in every aspect ${
            configurations.schoolShortName ? configurations.schoolShortName : ''
          } is great. Everywhere you turn there are people who care about you...`}
          quoteUser='Graduating student'
        />
        <BackgroundCircle
          bottom='-8%'
          right='1%'
          zIndex='100'
          styles={{ image: { width: 220, height: 220 } }}
          color={theme.palette.primary.main}
        />
      </Box>
      <Box>
        <img src={Infographic1} className='static-image' alt='' />
      </Box>
      <Box sx={{ mt: { xs: 8, sm: 37 } }}>
        <img src={Infographic2} className='static-image' alt='' />
      </Box>
      <Box className='page-quote' sx={{ pt: { xs: 8, sm: 10 }, pb: { xs: 12, sm: 16 } }}>
        <Quote
          quoteText='Overall a very positive experience. We see our son growing as a person after just this first year.'
          quoteUser={`Parent of a current ${
            configurations.schoolShortName ? configurations.schoolShortName : ''
          } student`}
        />
        <BackgroundCircle
          top='-10%'
          left='14%'
          zIndex='100'
          styles={{ image: { width: 220, height: 220 } }}
          color={theme.palette.primary.main}
        />
      </Box>
      <Box>
        <img src={Infographic4} className='static-image' alt='' />
      </Box>
      <Box mt={7}>
        <Container maxWidth={false}>
          <div className='tag-line'>
            Experience the {configurations.schoolShortName ? configurations.schoolShortName : ''} Difference
          </div>
        </Container>
      </Box>
      <Box mt={5} sx={{ mb: { xs: 8, sm: 20 } }}>
        <Button styles={{ container: { display: 'flex', justifyContent: 'center' } }} />
      </Box>
    </Box>
  );
};

export default ManageOutcomes;
