import React from 'react';
import Box from '@mui/system/Box';

export const BackgroundCircle = ({
  position = 'absolute',
  type = 'full',
  top,
  left,
  right,
  bottom,
  color = '#5A92FF',
  strokeWidth = '30',
  zIndex = -1,
  styles = {},
}) => {
  return (
    <Box
      display={{ lg: 'flex', md: 'flex', sm: 'none', xs: 'none' }}
      position={position}
      top={top}
      right={right}
      left={left}
      bottom={bottom}
      zIndex={zIndex}
      styles={styles.container || {}}
    >
      {type === 'full' && (
        <svg
          width='216'
          height='216'
          viewBox='0 0 216 216'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle
            cx='108'
            cy='108'
            r='93'
            stroke={color}
            stroke-opacity='0.3'
            stroke-width={strokeWidth}
          />
        </svg>
      )}
      {type === 'half' && (
        <svg
          width='77'
          height='154'
          viewBox='0 0 77 154'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle
            cy='77'
            r='62'
            stroke={color}
            stroke-opacity='0.3'
            stroke-width={strokeWidth}
          />
        </svg>
      )}
    </Box>
  );
};
