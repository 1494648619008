import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useTheme } from '@mui/material/styles';
import ConfigurationContext from '../../../../contexts/ConfigurationContext';
import { Link } from 'react-router-dom';
// import { PROGRAM_CONTENT } from 'constants/program_content';
// import urls from 'api/urls';
import { MessagesTab } from 'components/Global/Messages';

import { COLORS } from 'constants/colors';

export const AmbassadorsProfileDetails = ({ profile, type }) => {
  const [isMessageModalOpen, setIsMessageModalOpen] = React.useState(false);
  const theme = useTheme();
  const { configurations, isFeatureEnabled } = useContext(ConfigurationContext);
  const linkedIn = profile.socialMedias?.find((sm) => sm.media.toLowerCase().trim() === 'linkedin');
  const facebook = profile.socialMedias?.find((sm) => sm.media.toLowerCase().trim() === 'facebook');
  const twitter = profile.socialMedias?.find((sm) => sm.media.toLowerCase().trim() === 'twitter');

  return (
    <Box>
      <Grid container maxWidth='xl' justifyContent='center'>
        <Box sx={{ position: 'relative' }} display={{ xs: 'none', md: 'flex' }}>
          <Box sx={{ position: 'absolute', top: 410, right: -80, zIndex: -1 }}>
            <svg xmlns='http://www.w3.org/2000/svg' width='216' height='216' viewBox='0 0 216 216' fill='none'>
              <circle
                r='93'
                transform='matrix(-1 0 0 1 108 108)'
                stroke={theme.palette.primary.main}
                stroke-opacity='0.3'
                stroke-width='30'
              />
            </svg>
          </Box>
        </Box>
        <Grid item container md={10} sm={12} mt={10}>
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{ position: 'absolute', zIndex: -1, bottom: 350, left: 250 }}
              display={{ xs: 'none', md: 'flex', sm: 'none' }}
            >
              <svg xmlns='http://www.w3.org/2000/svg' width='154' height='154' viewBox='0 0 154 154' fill='none'>
                <circle
                  r='62'
                  transform='matrix(-1 0 0 1 77 77)'
                  stroke={theme.palette.primary.main}
                  stroke-opacity='0.3'
                  stroke-width='30'
                />
              </svg>
            </Box>
          </Box>
          {type === 'ambassador' ? (
            <Box sx={{ position: 'relative' }}>
              <Box sx={{ position: 'absolute' }}>
                <img src='/assets/images/tag.png' alt='' />
              </Box>
              <Box sx={{ position: 'absolute', top: 5, left: 15 }}>
                <Typography fontSize={{ md: 17 }} fontWeight={600} color={COLORS.white.main}>
                  ·Ambassador·
                </Typography>
              </Box>
            </Box>
          ) : (
            ''
          )}

          <Grid item md={4} sm={6} xs={12}>
            <Box
              p={{ md: 2, xs: 2 }}
              bgcolor={COLORS.white.main}
              sx={{ boxShadow: '0px 4px 15px 10px rgba(0, 0, 0, 0.03)' }}
            >
              <img
                src={
                  // profile.profileImageUrl
                  //   ? profile.profileImageUrl
                  //   : PROGRAM_CONTENT[urls.programId()].default_family_profile_image
                  //   ? PROGRAM_CONTENT[urls.programId()].default_family_profile_image
                  //   : '/assets/images/family-profile.svg'

                  profile.profileImageUrl
                    ? profile.profileImageUrl
                    : configurations?.default_user_profile_image?.url
                    ? configurations?.default_user_profile_image?.url
                    : '/assets/images/family-profile.svg'
                }
                alt=''
                width='100%'
                height='auto'
              />
            </Box>
          </Grid>

          <Grid item container md={8} sm={6} justifyContent='center' paddingX={5} mt={4}>
            <Grid item md={6}>
              {profile?.lastName ? (
                <Typography fontSize={{ md: 40 }} fontWeight={700}>
                  {profile.lastName}
                </Typography>
              ) : (
                ''
              )}
              <Box>
                <Typography fontSize={{ md: 18 }} fontWeight={600} mt={2} width={260} wrap>
                  {profile?.email ? (
                    <a
                      href={`mailto:${profile.email}`}
                      style={{
                        color: 'rgba(0, 0, 0, 0.87)',
                        textDecoration: 'none',
                      }}
                    >
                      {profile.email}
                    </a>
                  ) : (
                    ''
                  )}
                  {profile?.otherEmail ? (
                    <>
                      <br />
                      <a
                        href={`mailto:${profile.otherEmail}`}
                        style={{
                          color: 'rgba(0, 0, 0, 0.87)',
                          textDecoration: 'none',
                        }}
                      >
                        {profile.otherEmail}
                      </a>
                    </>
                  ) : (
                    ''
                  )}
                  <br />
                  Member of {configurations.schoolShortName ? configurations.schoolShortName : ''} since{' '}
                  {profile.memberOfSchool ? profile.memberOfSchool : ''}
                </Typography>
                {profile.educations?.map((ed) => (
                  <>
                    <Typography fontSize={{ md: 18 }} fontWeight={400} mt={4}>
                      {ed.name} went to {ed.outcome?.outcomeTitle}
                    </Typography>
                    <Box mt={2}>
                      <img src={ed.outcome?.outcomeLogoUrl} alt={ed.outcome?.outcomeLogoAltText} height='30px' />
                    </Box>
                  </>
                ))}
              </Box>
            </Grid>
            <Grid
              item
              md={6}
              mt={{ xs: 2, md: 0 }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              {isFeatureEnabled('family_message') ? (
                // profile?.is_contactable_by_current_user ? (
                <Button
                  variant='contained'
                  color='secondary'
                  sx={{ borderRadius: 50, padding: 2, width: 220 }}
                  onClick={() => setIsMessageModalOpen(true)}
                >
                  Message
                </Button>
              ) : (
                // ) : (
                //   ''
                // )
                ''
              )}
              <Box display='flex' justifyContent='flex-end' marginX={2} mt={2}>
                {facebook ? (
                  <Link to={`//${facebook.link}`}>
                    <IconButton disableRipple>
                      <Box sx={{ position: 'relative' }}>
                        <img src='/assets/icons/circle.png' alt='' />
                        <img
                          src='/assets/icons/facebook.svg'
                          alt=''
                          style={{ position: 'absolute', top: 8, left: 12 }}
                        />
                      </Box>
                    </IconButton>
                  </Link>
                ) : (
                  ''
                )}

                {twitter ? (
                  <Link to={`//${twitter.link}`}>
                    <IconButton disableRipple>
                      <Box sx={{ position: 'relative' }}>
                        <img src='/assets/icons/circle.png' alt='' />
                        <img src='/assets/icons/twitter.svg' alt='' style={{ position: 'absolute', top: 8, left: 8 }} />
                      </Box>
                    </IconButton>
                  </Link>
                ) : (
                  ''
                )}

                {linkedIn ? (
                  <Link to={`//${linkedIn.link}`}>
                    <IconButton disableRipple>
                      <Box sx={{ position: 'relative' }}>
                        <img src='/assets/icons/circle.png' alt='' />
                        <img
                          src='/assets/icons/linkedIn.svg'
                          alt=''
                          style={{ position: 'absolute', top: 8, left: 8 }}
                        />
                      </Box>
                    </IconButton>
                  </Link>
                ) : (
                  ''
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Modal
        open={isMessageModalOpen}
        onClose={() => setIsMessageModalOpen(false)}
        aria-labelledby='Messages'
        aria-describedby='Messages'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            // boxShadow: 24,
            width: '100%',
            maxWidth: '1000px',
          }}
        >
          <MessagesTab isNew={true} isModal={true} members={profile?.members ? profile?.members : []} />
        </Box>
      </Modal>
    </Box>
  );
};

export default AmbassadorsProfileDetails;
