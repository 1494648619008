import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ConfigurationContext from 'contexts/ConfigurationContext';

export const GraduateCard = (props) => {
  const { image, userName, educations } = props || {};
  const { configurations, schools } = React.useContext(ConfigurationContext);
  const [school, setSchool] = React.useState(null);

  React.useEffect(() => {
    if (educations?.length) {
      setSchool(schools?.find((s) => s.id === educations[0].outcome_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [educations]);

  return (
    <Box
      sx={{
        width: '100%',
        height: 'auto',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '300px',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={
            image
              ? image
              : configurations?.default_user_profile_image?.url
              ? configurations?.default_user_profile_image?.url
              : '/assets/images/family-profile.svg'
          }
          alt={userName}
          width='auto'
          height='100%'
        />
      </Box>
      <Box display='flex' justifyContent='space-between' p={4} alignItems='center'>
        <Typography sx={{ fontFamily: 'Proxima-Nova-Black' }} fontSize={22}>
          {userName}
        </Typography>
        {educations?.length && school ? <img src={school?.url} height='10px' alt={school?.outcomeTitle} /> : ''}
      </Box>
    </Box>
  );
};

export default GraduateCard;
