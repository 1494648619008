import React from 'react';
import COLORS from 'constants/colors';
// import { PROFILE_ACTIVITIES } from "constants/profiles";
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';
import Badge from '@mui/material/Badge';
import Container from '@mui/system/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Divider, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';

const Activities = ({ formData, updateFormData, allActivities }) => {
  const theme = useTheme();
  const [selectedActivities, setSelectedActivities] = React.useState(formData.activity_ids);

  const actDesc = [
    'Our Favorite Book to Read',
    'Our Favorite Restaurant',
    'Our Favorite Food to Cook Together',
    'Our Favorite Holiday',
    'Our Favorite Sport to Practice Together',
    'Perfect Family Plan',
    'Our Favorite Vacation Place',
    'Our Favorite Family Movie',
  ];

  const handleUpdateActivities = (activityId) => {
    let newSA;
    if (selectedActivities.includes(activityId)) {
      newSA = selectedActivities.filter((a) => a !== activityId);
    } else {
      newSA = [...selectedActivities, activityId];
    }
    updateFormData('activity_ids', newSA);
    setSelectedActivities(newSA);
  };

  const handleAboutUpdate = (name, value) => {
    const newAbout = [...formData.about];
    newAbout.find((a) => a.name === name).value = value;
    updateFormData(newAbout);
  };

  return (
    <React.Fragment>
      <Container>
        <Typography mt={5} variant='body2'>
          Select at least 3
        </Typography>
        <Typography mb={4} variant='h5' fontWeight='bold'>
          Activities We Like
        </Typography>
        <Grid container spacing={2}>
          {allActivities?.map((data, index) => (
            <Grid item md={2}>
              <Badge
                className='MuiBadge-Profile-badge'
                badgeContent={
                  (selectedActivities ?? []).includes(data.id) ? (
                    <DoneIcon
                      onClick={() => handleUpdateActivities(data.id)}
                      style={{
                        marginTop: 25,
                        marginRight: 20,
                        cursor: 'pointer',
                        backgroundColor: `#15DA7B`,
                        color: `white`,
                        borderRadius: '15px',
                      }}
                    />
                  ) : (
                    <AddIcon
                      onClick={() => handleUpdateActivities(data.id)}
                      style={{
                        marginTop: 25,
                        marginRight: 20,
                        cursor: 'pointer',
                        backgroundColor: `${theme.palette.primary.main}`,
                        color: `white`,
                        borderRadius: '15px',
                      }}
                    />
                  )
                }
              >
                <Box
                  bgcolor={COLORS.white.secondary}
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  textAlign='center'
                  alignItems='center'
                  borderRadius={'100%'}
                  width='100px'
                  height='100px'
                >
                  <img src={data.icon.url} alt='' width='30px' />
                  <Typography color={COLORS.dark.lighter} variant='caption'>
                    {data.name}
                  </Typography>
                </Box>
              </Badge>
            </Grid>
          ))}
        </Grid>

        <Grid mt={5} container spacing={3}>
          {formData?.about?.map((option, index) => (
            <Grid item md={6} xs={12} sm={6}>
              <InputLabel className='MuiInputLabel-Profile-label' shrink>
                {actDesc[index]}
              </InputLabel>
              <FormControl fullWidth>
                <div key={index}>
                  <TextField
                    fullWidth
                    InputProps={{ disableUnderline: 'false' }}
                    sx={{ mb: 2 }}
                    color='secondary'
                    variant='filled'
                    margin='normal'
                    className='family-input-fields'
                    name={`about.${index}.value`}
                    value={option.value}
                    onChange={(e) => handleAboutUpdate(option.name, e.target.value)}
                  />
                </div>
              </FormControl>
            </Grid>
          ))}
        </Grid>
        <Box my={5}>
          <Divider />
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Activities;
