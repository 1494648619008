import { SettingsInputAntennaTwoTone } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react';

export const ClubPageHeading = (props) => {
  const [club, setClub] = useState(props.club || {});
  useEffect(() => {
    if (props.club) setClub(props.club);
  }, [props.club]);
  
  return (
    <Container maxWidth='xl'>
      <Grid container>
        <Grid item lg={8} md={10}>
          <Typography
            sx={{
              fontStyle: 'normal',
              color: '#131416',
              fontWeight: '900',
              lineHeight: '58px',
              fontSize: '50px',
              marginTop: '50px',
              marginBottom: '130px',
            }}
          >
            {club.clubName}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};
