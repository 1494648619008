import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Hero from './components/Hero';
import Button from '@mui/material/Button';
// import Albums from "./components/Albums";
// import MyClubs from "pages/ManageProfiles/components/Profile/components/MyClubs";
import General from './components/General';
import About from './components/About';
import { Reviews } from './components/Reviews';
import { Image } from './components/Image';
import Container from '@mui/system/Container';
import Education from './components/Education';
import Activities from './components/Activities';
import ProfileContext from 'contexts/ProfileContext';
import apiRequest from 'api/apiRequest';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import LoadingIndicator from 'components/Utils/LoadingIndicator';
import ConfigurationContext from 'contexts/ConfigurationContext';

export const OurProfile = () => {
  const isFamily = 'isFamily';
  const { profile, isLoading, staffProfile, fetchStaffProfile } = React.useContext(ProfileContext);
  const { schools, fetchSchools, activities, fetchActivities } = React.useContext(ConfigurationContext);
  const [isUpdating, setIsUpdating] = useState(false);
  const [formData, setFormData] = useState({});

  React.useEffect(() => {
    if (schools.length === 0) fetchSchools();
    if (activities.length === 0) fetchActivities();
    if (profile) {
      fetchStaffProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  React.useEffect(() => {
    if (staffProfile) {
      setFormData(staffProfile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffProfile]);

  const updateFormData = (formDataKey, formDataValue) => {
    const newFormData = { ...formData };
    newFormData[formDataKey] = formDataValue;
    setFormData(newFormData);
  };

  const handleSubmit = async () => {
    if (!isUpdating) {
      try {
        setIsUpdating(true);

        // Prepare Final Data To Submit
        const dataToSubmit = { ...formData };

        dataToSubmit.about = dataToSubmit.about.map((d) => (d.value === '' ? { name: d.name, value: null } : d));
        dataToSubmit.social_media_accounts = dataToSubmit.social_media_accounts.filter((d) => d.url !== '');
        dataToSubmit.educations = dataToSubmit.educations.filter(
          (e) => e.outcome_id !== null && e.degree.trim() !== ''
        );
        for (let i = 0; i < dataToSubmit.educations.length; i++) {
          const element = dataToSubmit.educations[i];
          delete element.ourId;
        }
        delete dataToSubmit.about_media;

        // Prepare Data that needs to be deleted
        dataToSubmit.dissociate = {};

        // Prepare social media links that needs to be deleted
        const detachedSocialMedia = staffProfile?.social_media_accounts.filter(
          (sm) => !dataToSubmit.social_media_accounts.some((s) => s.provider === sm.provider)
        );
        const detachedSocialMediaIds = detachedSocialMedia.map((d) => ({ id: d.id }));
        if (detachedSocialMediaIds.length) dataToSubmit.dissociate.social_media_accounts = detachedSocialMediaIds;

        // Prepare educations that needs to be deleted
        const detachedEducations = staffProfile?.educations.filter(
          (ed) => !dataToSubmit.educations.some((m) => m.id === ed.id)
        );
        const detachedEducationsIds = detachedEducations.map((d) => ({ id: d.id }));
        if (detachedEducationsIds.length) dataToSubmit.dissociate.educations = detachedEducationsIds;

        console.log(dataToSubmit);
        const response = await apiRequest({
          method: 'PUT',
          url: `/staff/me`,
          data: dataToSubmit,
        });
        setIsUpdating(false);
        console.log(response);
        toast.success('Staff Profile Updated!');
      } catch (error) {
        toast.error(error);
      } finally {
        setIsUpdating(false);
      }
    }
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <React.Fragment>
      <Hero isFamily={isFamily} />
      <General formData={formData} updateFormData={updateFormData} />
      {/* <FamilyMembers formData={formData} updateFormData={updateFormData} /> */}
      {/* <SocialLinks formData={formData} updateFormData={updateFormData} /> */}
      <Education formData={formData} updateFormData={updateFormData} />
      <About formData={formData} updateFormData={updateFormData} />
      {/* <Albums /> */}
      <Activities formData={formData} updateFormData={updateFormData} allActivities={activities} />
      <Image formData={formData} updateFormData={updateFormData} />
      {/* <MyClubs isFamily={isFamily} /> */}
      <Reviews formData={formData} updateFormData={updateFormData} />
      <Container>
        <Box my={10} display='flex' justifyContent='flex-end'>
          <Button
            sx={{ width: '233px', height: '60px' }}
            className='MuiButton-profile'
            variant='contained'
            color='secondary'
            disabled={isUpdating ? true : false}
            onClick={handleSubmit}
          >
            {isUpdating ? <CircularProgress style={{ color: 'white' }} /> : 'Save Changes'}
          </Button>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default OurProfile;
