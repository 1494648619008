import React from 'react';
import Box from '@mui/system/Box';
import Container from '@mui/system/Container';

import Typography from '@mui/material/Typography';
import EducationRow from './EducationRow';

export const Education = ({ formData, updateFormData }) => {
  const updateMemberKey = (idx, key, value) => {
    const newMembers = [...formData.members];
    newMembers[idx]['education'][key] = value;
    updateFormData(newMembers);
  };

  return (
    <React.Fragment>
      <Container>
        <Box mt={5} display='flex' justifyContent='space-between'>
          <Typography variant='h5' fontWeight='bold'>
            Education
          </Typography>
          {/* <img src='/assets/icons/profile-edit-icon.svg' alt='' style={{ cursor: 'pointer' }} /> */}
        </Box>
        {formData?.members?.map((m, i) => (
          <EducationRow member={m} idx={i} updateMemberKey={updateMemberKey} />
        ))}
      </Container>
    </React.Fragment>
  );
};

export default Education;
