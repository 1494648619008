import React, { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Select from '@mui/material/Select';
import ConfigurationContext from '../../../../contexts/ConfigurationContext';
import { useTheme } from '@mui/material/styles';

export const SearchArea = ({ category, setCategory, gradeBand, setGradeBand, searchKey, setSearchKey }) => {
  const theme = useTheme();
  const [isDropDown, setIsDropDown] = useState(true);
  const [text, setText] = useState(searchKey);

  const { categories, gradeBands, fetchArticlesCategories } = useContext(ConfigurationContext);

  React.useEffect(() => {
    if (categories.length === 0) fetchArticlesCategories();
  }, [categories, fetchArticlesCategories]);

  const handleChangeGrad = (event) => {
    setGradeBand(event.target.value);
  };

  const handleChangeTopic = (event) => {
    setCategory(event.target.value);
  };

  const handleSearch = (e) => {
    setSearchKey(text);
  };

  return (
    <React.Fragment>
      <Box display={{ xs: 'flex', md: 'flex', lg: 'flex' }}>
        <Container sx={{ maxWidth: { xl: 'xl', lg: 'lg', md: 'flex', xs: 'flex' } }} position='relative'>
          <Box>
            <Box
              // component='form'
              mt={{ md: 10, sm: 5, xs: 5 }}
              sx={{
                p: 2,
                my: 2,
                width: 'auto',
                bgcolor: 'white',
                alignItems: 'center',
                borderRadius: '26px',
                display: { lg: 'flex', md: 'flex', sm: 'flex', xs: 'flex' },
                boxShadow: '0px 4px 10px 7px rgba(0, 0, 0, 0.05)',
              }}
            >
              <IconButton sx={{ p: '10px' }} aria-label='menu' onClick={handleSearch}>
                <SearchIcon sx={{ color: theme.palette.primary.main }} />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder='Search for anything (families, clubs, groups and more)'
                inputProps={{ 'aria-label': 'search google maps' }}
                onChange={(e) => setText(e.target.value)}
                value={text}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
              />
              <IconButton sx={{ p: '10px' }} aria-label='search'>
                <FilterAltOutlinedIcon
                  sx={{ color: theme.palette.primary.main }}
                  onClick={() => setIsDropDown(!isDropDown)}
                />
              </IconButton>
            </Box>
            <Box
              sx={{
                left: '5%',
                top: '20%',
                zIndex: -1,
                position: 'absolute',
              }}
            >
              <svg width='216' height='216' viewBox='0 0 216 216' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <circle
                  cx='108'
                  cy='108'
                  r='93'
                  stroke={theme.palette.primary.main}
                  stroke-opacity='0.3'
                  stroke-width='30'
                />
              </svg>

              {/* <img src='assets/images/background-circle.svg' alt='' /> */}
            </Box>
          </Box>
          {isDropDown && (
            <Container
              maxWidth='false'
              sx={{
                marginTop: '50px',
                marginLeft: { lg: '-60px', xs: '', md: '', xl: '0px' },
                display: { xs: 'flex', md: 'flex', lg: 'flex' },
                alignItems: 'center',
              }}
            >
              <Typography variant='body2' fontWeight={'bold'}>
                Topic:
              </Typography>
              <Box
                style={{
                  background: '#FFFFFF',
                  boxShadow: '0px 4px 10px 3px rgba(0, 0, 0, 0.1)',
                  borderRadius: '10px',
                  width: 172,
                  height: 52,
                  marginLeft: '1rem',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0 1rem',
                }}
              >
                <Select id='demo-simple-select' value={category} label='' onChange={handleChangeTopic} fullWidth>
                  {[{ id: 0, category_tag: 'All' }, ...categories].map(({ category_tag, id }, i) => (
                    <MenuItem value={id} key={i}>
                      {category_tag}
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              <Typography variant='body2' fontWeight={'bold'} sx={{ ml: 5 }}>
                Grade:
              </Typography>
              <Box
                style={{
                  background: '#FFFFFF',
                  boxShadow: '0px 4px 10px 3px rgba(0, 0, 0, 0.1)',
                  borderRadius: '10px',
                  width: 172,
                  height: 52,
                  marginLeft: '1rem',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0 1rem',
                }}
              >
                <Select
                  id='demo-simple-select'
                  placeholder='All'
                  value={gradeBand}
                  label=''
                  onChange={handleChangeGrad}
                  fullWidth
                  style={{ border: 'none !important;' }}
                >
                  {[{ id: 0, grade_band: 'All' }, ...gradeBands].map(({ id, grade_band }, idx) => (
                    <MenuItem value={id} key={idx}>
                      {id === 0 ? 'All' : grade_band}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Container>
          )}
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default SearchArea;
