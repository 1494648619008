import React from "react";
import Box from "@mui/system/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import COLORS from "constants/colors";
import Typography from "@mui/material/Typography";
import Meeting from "./../Meeting";
import useTheme from "@mui/material/styles/useTheme";

const AddMeeting = (props) => {
  const { setIsAddMeeting } = props || {};
  const [data, setData] = React.useState({});
  const theme = useTheme();

  const confirm = () => {
    setIsAddMeeting(false);
    if (props.onConfirm) props.onConfirm(data);
  };
  return (
    <React.Fragment>
      <Paper
        sx={{
          borderRadius: "5px",
          p: 5,
          borderTop: `5px solid ${theme.palette.primary.main}`,
          position: "relative",
        }}
        elevation={0}
      >

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Add a new meeting</Typography>
          <img
            src="/assets/icons/cancel-icon.svg"
            alt=""
            width="25px"
            onClick={() => setIsAddMeeting(false)}
            style={{ cursor: "pointer" }}
          />
        </Box>
        <Box mt={3}>
          <Meeting onChange={(d) => setData(d)} />
        </Box>

        <Box mt={5} display="flex" justifyContent="flex-end">
          <Button
              sx={{
                border: `1px solid ${theme.palette.primary.main}`,
                backgroundColor: 'none',
                borderRadius: '100px',
              }}
            variant="outlined"
            onClick={() => setIsAddMeeting(false)}
          >
            Cancel
          </Button> &nbsp;
          <Button
              variant='outlined'
              sx={{
                border: `1px solid ${theme.palette.primary.main}`,
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                borderRadius: '100px',
              }}
          >
            Confirm
          </Button>
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default AddMeeting;
