import { useState, createContext } from 'react';
import apiRequest from '../api/apiRequest';
import { toast } from 'react-toastify';

const MessagingContext = createContext();

export const MessagingProvider = ({ children }) => {
  const [dialogues, setDialogues] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  const fetchDialogues = async () => {
    try {
      const response = await apiRequest({
        method: 'get',
        url: `/users/me/dialogues`,
      });

      setDialogues(response.data.reverse());
    } catch (error) {
      console.log(`fetchDialogues Error: ${error}`);
      toast.error('Unable to get messages at this time');
    }
  };

  const sendMessage = async (recipientsIds, message) => {
    try {
      const response = await apiRequest({
        method: 'post',
        url: `/messages`,
        data: {
          body: message,
          recipient_user_ids: recipientsIds,
        },
      });

      return response.data;
    } catch (error) {
      console.log(`fetchDialogues Error: ${error}`);
      toast.error('Your message cannot be delivered at this time');
    }
  };

  const getMessages = async (userId, dialogueId) => {
    try {
      const response = await apiRequest({
        method: 'get',
        url: `/dialogues/${dialogueId}/messages`,
      });

      if (response.data.length) {
        const othersMessages = response.data.filter((d) => d.sender_user_id !== userId);

        if (othersMessages.length) {
          apiRequest({
            method: 'put',
            url: `/users/me/messages`,
            data: {
              messages: othersMessages.map((d) => ({
                id: d.id,
                status: 'Read',
              })),
            },
          });
        }
      }

      return response.data;
    } catch (error) {
      console.log(`fetchDialogues Error: ${error}`);
      toast.error('Unable to get messages at this time');
    }
  };

  const getUnreadCount = async () => {
    try {
      const response = await apiRequest({
        method: 'get',
        url: `/users/me/messages/unread/count`,
      });

      setUnreadCount(response.data);
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <MessagingContext.Provider
      value={{
        dialogues,
        unreadCount,
        fetchDialogues,
        sendMessage,
        getMessages,
        getUnreadCount,
      }}
    >
      {children}
    </MessagingContext.Provider>
  );
};

export default MessagingContext;
