import React, { useState, useEffect } from "react";
import { useParams } from  "react-router-dom";
import Hero from "./components/Hero";
import About from "./components/About";
import Gallery from "./components/Gallery";
import Families from "./components/Families";
import AddStaff from "./components/AddStaff";
import UpComingEvents from "./components/UpComingEvents";
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import Announcements from "./components/Announcements";

import apiRequest from '../../../../../api/apiRequest';

import { SelectFamilies } from "./components";
import { useHistory } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';

export const ClubsEditor = () => {
  const params = useParams();
  const history = useHistory();
  const navigate = (path) => {
    return history.push({
      pathname: path,
    });
  };
  const theme = useTheme();

  const [club, setClub] = useState({});
  const [clubs, setClubs] = useState([]);
  const [events, setEvents] = useState([]);
  const [leaders, setLeaders] = useState([]);
  const [members, setMembers] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [families, setFamilies] = useState([]);

  const [familySearch, setFamilySearch] = useState('');
  const [familyResults, setFamilyResults] = useState([]);

  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    if (params.clubId) {
      //Load existing club data
      apiRequest({
        method: 'get',
        url: '/clubs/' + params.clubId,
      }).then((res) => {
        setClub(res.data);
        setEvents(res.data.events);
        setLeaders(res.data.leaders);
        setFamilies(res.data.families);
        setGallery(res.data.gallery);
      });
    }

    //Load list of all clubs
    apiRequest({
      method: 'get',
      url: '/clubs',
    }).then((res) => {
      setClubs(res.data);
    });
  }, []);

  const addEvent = () => {
    events.push({
      name: '',
      location: '',
      date: new Date().toISOString(),
    });
    setEvents([...events]);
  };

  const removeEvent = (event) => {
    events.splice(events.indexOf(event), 1);
    setEvents([...events]);
  };

  const updateEvent = (event, prop, value) => {
    event[prop] = value;
    setEvents([...events]);
  };

  const updateLeader = (postition, data) => {
    leaders[postition] = data;
    setLeaders([...leaders]);
  };

  const addFamilyMember = (member) => {
    members.push(member);
    setMembers([...members]);
  };

  const removeFamilyMember = (member) => {
    members.splice(members.indexOf(member), 1);
    setMembers([...members]);
  };

  const updateClub = (club) => {
    setClub(club);
  };

  const save = () => {
    const data = {
      ...club,
      events,
      leaders,
      members,
      meetings,
      gallery,
      families,
    };
    apiRequest({
      method: 'put',
      url: '/clubs/' + params.clubId,
      data,
    }).then((res) => {
      navigate('/club-hub');
    });
  }
  
  return (
    <React.Fragment>
      <Hero club={club} onClubUpdate={(club) => updateClub(club)} clubs={clubs}/>
      <About club={club} onClubUpdate={(club) => updateClub(club)} onMeetingsUpdate={(meetings) => setMeetings(meetings)} />
      <AddStaff leaders={leaders} onChange={(leaders) => setLeaders(leaders)}/>
      <UpComingEvents events={events} onChange={(events) => setEvents(events)}/>
      <Announcements club={club} onChange={(club) => updateClub(club)}/>
      <Families families={families} onChange={(families) => setFamilies(families)}/>
      <Gallery club={club} images={gallery} onChange={(images) => setGallery(images)}/>
      <Box mt={3} mb={15} display="flex" justifyContent="flex-end">
      <Button
        style={{ width:'233px',height:'60px', background: theme.palette.primary.main,}}
        className="MuiButton-profile"
        variant="contained"
        onClick={() => save()}
      >
        Save Changes
      </Button>
    </Box>
    </React.Fragment>
  );
};

export default ClubsEditor;
