import React from 'react';
import { HOME_VIDEO_CARD } from 'constants/home';
import { Link } from 'react-router-dom';
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import urls from 'api/urls';
const videoUrls = [
  'https://think12-prod.s3.amazonaws.com/2/1663337916_6%20-%20Athletics%20-%20Nicolas.mp4',
  'https://think12-prod.s3.amazonaws.com/2/1663332258_4%20-%20Applying%20-%20Nicholas.mp4',
  'https://think12-prod.s3.amazonaws.com/2/1663363311_8%20-%20inclusive%20-%20Zachary.mp4',
  'https://think12-prod.s3.amazonaws.com/2/1663167401_3%20-%20Classroom%20experience%20-%20Richie.mp4',
];

export const VideoRow = () => {
  return (
    <Container maxWidth={'xl'}>
      <Box my={{ xs: 5, md: 10 }}>
        <Typography
          my={2}
          textTransform='uppercase'
          variant='body1'
          // fontWeight='bold'
          fontWeight='900'
        >
          You’ve got questions. We’ve got answers.
        </Typography>
        <Grid container spacing={2}>
          {urls.isProduction()
            ? videoUrls.map((i) => {
                return (
                  <Grid item md={3} sm={6} xs={12}>
                    <Box>
                      <video src={`${i}#t=0.01`} width='100%' controls preload='auto'></video>
                    </Box>
                  </Grid>
                );
              })
            : HOME_VIDEO_CARD.map((data) => (
                <Grid item md={3} sm={3} xs={3}>
                  <Box position='relative'>
                    <Link>
                      <img
                        width={'16%'}
                        src='/assets/icons/play-button.svg'
                        alt=''
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'Translate(-50%, -50%)',
                        }}
                      />
                    </Link>
                    <img
                      src={data}
                      alt=''
                      style={{
                        width: '100%',
                        backgroundSize: 'cover',
                      }}
                    />
                  </Box>
                </Grid>
              ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default VideoRow;
