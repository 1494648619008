import React from 'react';
import ClubCard from '../ClubCard';
import Grid from '@mui/material/Grid';
import { clubHubCardData } from 'constants/content';

export const CardSection = (props) => {
  
  return (
    <Grid container spacing={4}>
      {props.clubs.map((club) => (
        <Grid item xs={12} sm={6} md={4}>
          <ClubCard club={club} />
        </Grid>
      ))}
    </Grid>
  );
};
