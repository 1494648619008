import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
// import InputBase from "@mui/material/InputBase";
import Typography from '@mui/material/Typography';
// import IconButton from "@mui/material//IconButton";
// import SearchIcon from "@mui/icons-material/Search";
import { COLORS } from 'constants/colors';
import { ROUTES } from 'constants/routes';
import { familyNetworkAlphabetsData } from 'constants';
import apiRequest from 'api/apiRequest';
import urls from 'api/urls';
import { useEffect } from 'react';
import { useState } from 'react';
import FamilyCard from './FamilyNetworkProfileDetails/components/FamilyCard/FamilyCard';
import LoadingIndicator from 'components/Utils/LoadingIndicator';
import { Button } from '@mui/material';
// import img from '../../../assets/images'
// import img from '../../../assets/images/'
export const FamilyNetworkDetails = () => {
  //state for famillies
  const [isLoading, setIsLoading] = useState(false);
  const [familyCard, setFamilyCard] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages] = useState(0);
  const [nextPageUrl, setNextPageUrl] = useState('');
  const [prevPageUrl, setPrevPageUrl] = useState('');

  const [filter, setFilter] = useState('');
  const handleClick = (item) => {
    setFilter(item);
  };

  //fetch filter
  const fetchFilter = async () => {
    setIsLoading(true);
    const response = await apiRequest({
      method: 'get',
      url: `${urls.families}?filter[last_name][prefix]=${filter}&filter[is_standard][is]=1`,
    });
    setFamilyCard(response.data);
    setIsLoading(false);
  };

  //fetch families endpoint
  const fetchFamilies = async () => {
    setIsLoading(true);
    const response = await apiRequest({
      method: 'get',
      url: `${urls.families}?filter[is_standard][is]=1`,
    });
    setFamilyCard(response.data);
    setCurrentPage(response.current_page);
    setNextPageUrl(response.next_page_url);
    setPrevPageUrl(response.prev_page_url);
    setIsLoading(false);
  };

  useEffect(() => {
    try {
      fetchFamilies();
    } catch(error) {
      console.log(error);
    }
    
  }, []);

  useEffect(() => {
    if (filter) {
      fetchFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const fetchNextPage = async () => {
    setIsLoading(true);

    const response = await apiRequest({
      method: 'get',
      url: `${nextPageUrl}`,
    });
    setFamilyCard(response.data);
    setCurrentPage(response.current_page);
    setNextPageUrl(response.next_page_url);
    setIsLoading(false);
  };
  const fetchPrevPage = async () => {
    setIsLoading(true);

    const response = await apiRequest({
      method: 'get',
      url: `${prevPageUrl}`,
    });
    setFamilyCard(response.data);
    setCurrentPage(response.current_page);
    setPrevPageUrl(response.prev_page_url);
    setIsLoading(false);
  };
  const fetchPage = async (i) => {
    setIsLoading(true);

    const response = await apiRequest({
      method: 'get',
      url: `${urls.families}?page=${i}`,
    });
    setFamilyCard(response.data);
    setCurrentPage(response.current_page);
    setIsLoading(false);
  };
  const pages = Array.from({ length: totalPages }, (_, i) => (
    <Button key={i} onClick={() => fetchPage(i)} style={{ fontSize: '20px' }}>
      {i + 1}
    </Button>
  ));

  return (
    <Box mb={10}>
      <Box sx={{ position: 'relative' }}>
        <Container>
          <Box sx={{ position: 'absolute', top: 10, left: '5%', zIndex: -1 }}>
            <img src={'assets/icons/EllipseFull.svg'} alt='' />
          </Box>
        </Container>
      </Box>
      <Container maxWidth='false' disableGutters>
        {/* <Box justifyContent="center" display="flex">
          <Box
            component="form"
            width={1200}
            sx={{
              p: 2,
              mt: 8,
              display: "flex",
              bgcolor: "white",
              alignItems: "center",
              borderRadius: "26px",
              boxShadow: "0px 4px 10px 7px rgba(0, 0, 0, 0.05)",
            }}
          >
            <IconButton sx={{ p: "10px" }}>
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search by family, name, grade, clubs, etc..."
            />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton sx={{ p: "10px" }} aria-label="search">
                <img src={"assets/icons/filterIcon.svg"} alt="" />
              </IconButton>
              <Typography ml={1} color="gray">
                My Group
              </Typography>
              <IconButton
                color="primary"
                sx={{ p: "10px", ml: 1 }}
                aria-label="directions"
              >
                <img src={"../../../assets/icons/arrowDown.svg"} alt="arrow" />
              </IconButton>
            </Box>
          </Box>
        </Box> */}
        <Box justifyContent='center' display='flex'>
          {/* <Grid item container md={10} xs={12} justifyContent="center">
            {familyNetworkAlphabetsData.map((item,id) => (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography
                  fontSize={16}
                  color="primary"
                  ml={2}
                  mt={{ xs: 2, md: 8 }}
                >
                  {item}
                  
                </Typography>
              </Box>
            ))}
          </Grid> */}
          <Grid item container md={10} xs={12} justifyContent='center'>
            {familyNetworkAlphabetsData.map((item, id) => (
              <Box display='flex' justifyContent='center' alignItems='center'>
                <Typography
                  fontSize={16}
                  color='primary'
                  ml={2}
                  mt={{ xs: 2, md: 8 }}
                  onClick={() => handleClick(item)}
                  sx={{ cursor: 'pointer' }}
                >
                  {item}
                </Typography>
              </Box>
            ))}
          </Grid>
        </Box>
        <Container>
          <Box>
            <Typography fontSize={{ md: 40 }} fontWeight={700} color={COLORS.black.dark} mt={{ xs: 2 }}>
              It Takes a Village
            </Typography>
            <Typography mt={{ xs: 2, md: 3 }} width={{ md: 1045 }} wrap>
              Better connected families improve student experience, academic outcomes and school safety. And for
              parents, it’s always nice to put a face with a name.
            </Typography>
            <Grid item spacing={4} container mt={{ md: 3, xs: 2 }} justifyContent='center'>
              {isLoading ? (
                <Grid container justifyContent='center'>
                  <LoadingIndicator />
                </Grid>
              ) : (
                familyCard?.map((item) => (
                  <Grid item md={4}>
                    <Link to={`${ROUTES.FAMILY_NETWORK_DETAILS}/${item?.id}`}>
                      <FamilyCard
                        image={item?.profileImageUrl}
                        grade={item?.user?.id}
                        userName={item?.last_name}
                        cartoons={item?.user?.bio}
                        id={item?.user?.id}
                        familyMembers={item?.members}
                      />
                    </Link>
                  </Grid>
                ))
              )}
            </Grid>
            <Box display='flex' justifyContent='center' alignItems='center' sx={{ paddingTop: '60px' }}>
              <Grid>
                <Button
                  disabled={currentPage === 1 ? true : false}
                  onClick={fetchPrevPage}
                  style={{ fontSize: '20px' }}
                >
                  {'<'}
                </Button>
                {pages}
                <Button style={{ fontSize: '20px' }} disabled={nextPageUrl ? false : true} onClick={fetchNextPage}>
                  {'>'}
                </Button>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Container>
    </Box>
  );
};

export default FamilyNetworkDetails;
