import React, { useContext, useState, useEffect } from 'react';
import Tips from './components/Tips';
import Topics from './components/Topics';
import Article from './components/Article';
import ArticleMore from './components/ArticleMore';
import TextArea from './components/TextArea';
import SearchArea from './components/SearchArea';
// import FilterArea from './components/FilterArea';
import ArticleDetail from './components/ArticleDetail';
import LoadingIndicator from '../../components/Utils/LoadingIndicator';
import ArticlesContext from '../../contexts/ArticlesContext';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import ConfigurationContext from 'contexts/ConfigurationContext';

export const ManageTips = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [bottomIsLoading, setBottomIsLoading] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [gradeBand, setGradeBand] = useState(0);
  const [category, setCategory] = useState(0);
  const [page, setPage] = useState(1);
  const { articles, count, fetchArticles } = useContext(ArticlesContext);
  const theme = useTheme();
  const { configurations } = useContext(ConfigurationContext);

  const getInitialData = async () => {
    setIsLoading(true);
    await fetchArticles({
      category: 0,
      gradeBand: 0,
      searchKey: '',
      page: 1,
      limit: 14,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchKey = async (value) => {
    setIsLoading(true);
    setSearchKey(value);
    setPage(1);
    await fetchArticles({
      category,
      gradeBand,
      searchKey: value,
      page: 1,
      limit: 14,
    });
    setIsLoading(false);
  };

  const handleGradeBand = async (value) => {
    setIsLoading(true);
    setGradeBand(value);
    setPage(1);
    await fetchArticles({
      category,
      gradeBand: value,
      searchKey,
      page: 1,
      limit: 14,
    });
    setIsLoading(false);
  };

  const handleCategory = async (value) => {
    setIsLoading(true);
    setCategory(value);
    setPage(1);
    await fetchArticles({
      category: value,
      gradeBand,
      searchKey,
      page: 1,
      limit: 14,
    });
    setIsLoading(false);
  };

  const handlePage = async (value) => {
    setBottomIsLoading(true);
    setPage(value);
    await fetchArticles({
      category,
      gradeBand,
      searchKey,
      page: value,
      limit: 14,
    });
    setBottomIsLoading(false);
  };

  return (
    <React.Fragment>
      {configurations?.article_header_copy && configurations?.article_subheader_copy ? (
        <Grid item container md={12}>
          <Grid item md={8} p={{ xs: 2, md: 10 }} pb={{ xs: 0, md: 0 }}>
            {configurations?.article_header_copy && (
              <Box p={{ xs: 1, md: 0 }}>
                <Typography fontSize={{ md: 56 }} fontWeight={900}>
                  {configurations?.article_header_copy}
                </Typography>
              </Box>
            )}
            {configurations?.article_subheader_copy && (
              <Box p={{ xs: 1, md: 0 }} mt={{ md: 4, xs: 0 }}>
                <Typography wrap fontSize={{ md: 32 }} fontWeight={700} width={{ xs: 300, md: 800 }}>
                  {configurations?.article_subheader_copy}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      ) : (
        ''
      )}
      <SearchArea
        searchKey={searchKey}
        setSearchKey={handleSearchKey}
        gradeBand={gradeBand}
        setGradeBand={handleGradeBand}
        category={category}
        setCategory={handleCategory}
      />
      {/* <FilterArea /> */}
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <React.Fragment>
          {/* Top Section - 7 Articles */}
          <Tips articleContent={articles} />

          {/* Sample Text */}
          {articles.length > 7 ? <TextArea /> : ''}

          {/* Middle Section - 5 Articles */}
          {articles.length > 7 ? <Article articleContent={articles.slice(7, 12)} /> : ''}

          {/* Load More Articles */}
          {articles.length > 14 ? <ArticleMore articleContent={articles.slice(12, articles.length - 2)} /> : ''}

          <br />
          {count <= articles.length ? (
            ''
          ) : (
            <Box textAlign='center' sx={{ marginTop: '1em' }}>
              <Button
                variant='contained'
                sx={{
                  bgcolor: theme.palette.secondary.main,
                  borderRadius: 50,
                  px: 2,
                  py: 1,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handlePage(page + 1);
                }}
                disabled={bottomIsLoading ? true : false}
              >
                {bottomIsLoading ? <CircularProgress /> : 'Load More'}
              </Button>
            </Box>
          )}

          {/* Bottom Section - 2 Articles */}
          {articles.length > 12 ? <Topics articleContent={articles.slice(articles.length - 2, articles.length)} /> : ''}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export const ManageArticleDetail = ArticleDetail;

export default ManageTips;
