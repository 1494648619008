import React from 'react';
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/system/Container';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import moment from 'moment';
import { FormControlLabel } from '@mui/material';

export const General = ({ formData, updateFormData }) => {
  return (
    <React.Fragment>
      <Container>
        <Box mt={5} display='flex' justifyContent='space-between'>
          <Typography variant='h5' fontWeight='bold'>
            General
          </Typography>
        </Box>
        <Grid container mt={2} spacing={3}>
          <Grid item md={6} xs={12} sm={6}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Name*
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                InputProps={{ disableUnderline: 'false' }}
                variant='filled'
                hiddenLabel
                fullWidth
                id='name'
                name='name'
                className='family-input-fields'
                sx={{ mb: 2 }}
                value={formData?.first_name}
                onChange={(e) => updateFormData('first_name', e.target.value)}
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12} sm={6}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Last Name*
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                InputProps={{ disableUnderline: 'false' }}
                variant='filled'
                hiddenLabel
                fullWidth
                id='last-name'
                name='last-name'
                className='family-input-fields'
                sx={{ mb: 2 }}
                value={formData?.last_name}
                onChange={(e) => updateFormData('last_name', e.target.value)}
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12} sm={6}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Email Address*
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                InputProps={{ disableUnderline: 'false' }}
                variant='filled'
                hiddenLabel
                required
                fullWidth
                id='work-email'
                name='work-email'
                className='family-input-fields'
                value={formData.email}
                disabled
              ></TextField>
            </FormControl>
            <FormControl fullWidth>
              <FormControlLabel
                control={<Checkbox color='primary' />}
                label='Allow this email address to be visible in my profile'
                checked={formData?.is_primary_email_visible}
                onChange={(e) => updateFormData('is_primary_email_visible', e.target.checked)}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12} sm={6}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Class
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                select
                value={formData?.attending_school_since}
                onChange={(e) => updateFormData('attending_school_since', Number(e.target.value))}
              >
                <MenuItem>
                  <em>Select Year</em>
                </MenuItem>
                {Array.from({ length: 50 }, (_, i) => String(i + (Number(moment().format('YYYY')) - 49)))
                  .reverse()
                  .map((item = '') => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
              </TextField>
            </FormControl>
          </Grid>

          <Grid item md={6} xs={12} sm={6}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              LinkedIn Profile
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                InputProps={{ disableUnderline: 'false' }}
                variant='filled'
                hiddenLabel
                required
                fullWidth
                id='linked-in'
                name='linked-in'
                className='family-input-fields'
                value={formData?.social_media_accounts ? formData?.social_media_accounts[0]?.url : ''}
                onChange={(e) =>
                  updateFormData('social_media_accounts', [{ provider: 'LinkedIn', url: e.target.value }])
                }
              ></TextField>
            </FormControl>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default General;
