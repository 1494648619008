import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Hero from './components/Hero';
import Button from '@mui/material/Button';
// import Albums from "./components/Albums";
// import MyClubs from "pages/ManageProfiles/components/Profile/components/MyClubs";
import General from './components/General';
import Container from '@mui/system/Container';
import Education from './components/Education';
import Activities from './components/Activities';
import SocialLinks from './components/SocialLinks';
import FamilyMembers from './components/FamilyMembers';
import ProfileContext from 'contexts/ProfileContext';
import apiRequest from 'api/apiRequest';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import LoadingIndicator from 'components/Utils/LoadingIndicator';
import ConfigurationContext from 'contexts/ConfigurationContext';

export const OurProfile = () => {
  const isFamily = 'isFamily';
  const { profile, isLoading, familyProfile, fetchFamilyProfile } = React.useContext(ProfileContext);
  const { schools, fetchSchools, activities, fetchActivities } = React.useContext(ConfigurationContext);
  const [isUpdating, setIsUpdating] = useState(false);
  const [formData, setFormData] = useState({});

  React.useEffect(() => {
    if (schools.length === 0) fetchSchools();
    if (activities.length === 0) fetchActivities();
    if (profile) {
      fetchFamilyProfile(profile.family_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  React.useEffect(() => {
    if (familyProfile) {
      generateFormData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [familyProfile]);

  const generateFormData = () => {
    // Create Form Data Object
    const newFormData = {
      last_name: familyProfile?.last_name ? familyProfile?.last_name : '',
      primary_email: familyProfile?.primary_email ? familyProfile?.primary_email : '',
      secondary_email: familyProfile?.secondary_email ? familyProfile?.secondary_email : '',
      is_primary_email_visible: familyProfile?.is_primary_email_visible
        ? familyProfile?.is_primary_email_visible
        : false,
      is_secondary_email_visible: familyProfile?.is_secondary_email_visible
        ? familyProfile.is_secondary_email_visible
        : false,
      attending_school_since: familyProfile?.attending_school_since ? familyProfile.attending_school_since : '',
      activity_ids: familyProfile?.activities ? familyProfile.activities.map((a) => a.id) : [],
    };

    // Add Abouts to Form Data
    const allAbouts = [
      {
        name: 'favorite_book',
        value: '',
      },
      {
        name: 'favorite_restaurant',
        value: '',
      },
      {
        name: 'favorite_food',
        value: '',
      },
      {
        name: 'favorite_holiday',
        value: '',
      },
      {
        name: 'favorite_sport',
        value: '',
      },
      {
        name: 'perfect_family_plan',
        value: '',
      },
      {
        name: 'favorite_vacation_place',
        value: '',
      },
      {
        name: 'favorite_movie',
        value: '',
      },
    ];

    if (familyProfile?.about?.values) {
      for (const key in familyProfile?.about?.values) {
        const found = allAbouts.find((a) => a.name === key);
        if (found) found.value = familyProfile?.about?.values[key];
      }
    }
    newFormData.about = [...allAbouts];

    // Add Social Profiles to Form Data
    const socialProfiles = [
      { provider: 'LinkedIn', url: '' },
      { provider: 'Facebook', url: '' },
      { provider: 'Twitter', url: '' },
    ];

    if (familyProfile?.social_media_accounts) {
      for (let i = 0; i < familyProfile.social_media_accounts.length; i++) {
        const foundSp = socialProfiles.find((p) => p.provider === familyProfile.social_media_accounts[i].provider);
        if (foundSp) {
          foundSp.url = familyProfile.social_media_accounts[i].url;
          foundSp.id = familyProfile.social_media_accounts[i].id;
        }
      }
    }
    newFormData.social_media_accounts = [...socialProfiles];

    // Add Members and Educations to Form Data
    let members;
    if (familyProfile?.members?.length) {
      members = familyProfile?.members.map((m) => ({
        id: m.id,
        name: m.first_name,
        gender: m.gender,
        age: m.age,
        icon_id: m.icon_id,
        education: {
          // name: m?.educations[0]?.name || '',
          id: m?.educations[0]?.id,
          degree: m?.educations[0]?.degree || '',
          outcome_id: m?.educations[0]?.outcome_id || '',
        },
      }));
    } else {
      members = [];
    }

    newFormData.members = members;

    console.log(newFormData);
    setFormData(newFormData);
  };

  const updateFormData = (formDataKey, formDataValue) => {
    const newFormData = { ...formData };
    newFormData[formDataKey] = formDataValue;
    setFormData(newFormData);
  };

  const handleSubmit = async () => {
    if (!isUpdating) {
      try {
        setIsUpdating(true);

        // Prepare Final Data To Submit
        const dataToSubmit = { ...formData };
        dataToSubmit.about = dataToSubmit.about.map((d) => (d.value === '' ? { name: d.name, value: null } : d));
        dataToSubmit.social_media_accounts = dataToSubmit.social_media_accounts.filter((d) => d.url !== '');
        dataToSubmit.members = dataToSubmit.members.map((member) => ({
          ...member,
          educations: [member.education],
        }));

        // Prepare Data that needs to be deleted
        dataToSubmit.dissociate = {};

        // Prepare social media links that needs to be deleted
        const detachedSocialMedia = familyProfile?.social_media_accounts.filter(
          (sm) => !dataToSubmit.social_media_accounts.some((s) => s.provider === sm.provider)
        );
        const detachedSocialMediaIds = detachedSocialMedia.map((d) => ({ id: d.id }));
        if (detachedSocialMediaIds.length) dataToSubmit.dissociate.social_media_accounts = detachedSocialMediaIds;

        // Prepare members that needs to be deleted
        const detachedMembers = familyProfile?.members.filter(
          (mem) => !dataToSubmit.members.some((m) => m.id === mem.id)
        );
        const detachedMembersIds = detachedMembers.map((d) => ({ id: d.id }));
        if (detachedMembersIds.length) dataToSubmit.dissociate.members = detachedMembersIds;

        console.log(dataToSubmit);
        const response = await apiRequest({
          method: 'PUT',
          url: `/families/${profile.family_id}`,
          data: dataToSubmit,
        });
        setIsUpdating(false);
        console.log(response);
        toast.success('Profile Updated!');
      } catch (error) {
        toast.error(error);
      } finally {
        setIsUpdating(false);
      }
    }
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <React.Fragment>
      <Hero isFamily={isFamily} />
      <General formData={formData} updateFormData={updateFormData} />
      <FamilyMembers formData={formData} updateFormData={updateFormData} />
      <SocialLinks formData={formData} updateFormData={updateFormData} />
      <Education formData={formData} updateFormData={updateFormData} />
      {/* <Albums /> */}
      <Activities formData={formData} updateFormData={updateFormData} allActivities={activities} />
      {/* <MyClubs isFamily={isFamily} /> */}
      <Container>
        <Box my={10} display='flex' justifyContent='flex-end'>
          <Button
            sx={{ width: '233px', height: '60px' }}
            className='MuiButton-profile'
            variant='contained'
            color='secondary'
            disabled={isUpdating ? true : false}
            onClick={handleSubmit}
          >
            {isUpdating ? <CircularProgress style={{ color: 'white' }} /> : 'Save Changes'}
          </Button>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default OurProfile;
