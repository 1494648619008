import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { UploadControl } from './UploadControl';
import apiRequest from 'api/apiRequest';
import { toast } from 'react-toastify';

function AboutMediaRow({ about }) {
  const [uploadFile, setUploadFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (uploadFile) {
      uploadFileToServer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFile]);

  const uploadFileToServer = async () => {
    try {
      setIsLoading(true);
      // Upload File to serer
      const formData = new FormData();
      // const json = {
      //   image: 'test',
      // };

      // console.log(uploadFile);
      formData.append('about_media[image]', uploadFile);
      if (about) formData.append('image_id', about.id);
      if (about) formData.append('_method', 'PUT');
      // formData.append('image', uploadFile);
      // formData.append('about_media[test]', 'iqbal');
      // console.log(formData);
      await apiRequest({
        method: 'POST',
        url: `/staff/me`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setIsLoading(false);
    } catch (error) {
      toast.error(error);
      setIsLoading(false);
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xd={12} md={10}>
        <Box mt={2}>
          <Typography variant='caption'>Add a representative video or image about you.</Typography>
        </Box>
      </Grid>
      {about?.url && (
        <Grid item xd={10}>
          <img alt='' src={uploadFile ? URL.createObjectURL(uploadFile) : about?.url} width='100%'></img>
        </Grid>
      )}
      <Grid item md={10} xs={12} mt={2}>
        <UploadControl
          icon='/assets/icons/modalFileIcon.svg'
          title1='Drop your file(s) here or'
          title2='browse'
          height='400px'
          isLoading={isLoading}
          setUploadFile={setUploadFile}
        />
      </Grid>
    </Grid>
  );
}

export default AboutMediaRow;
