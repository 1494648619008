import React, { useContext } from 'react';
import COLORS from 'constants/colors';
import { Link } from 'react-router-dom';
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import ConfigurationContext from '../../../../contexts/ConfigurationContext';
import { ROUTES } from '../../../../constants/routes';
import urls from 'api/urls';

const videoUrls = [
  'https://think12-prod.s3.amazonaws.com/2/1663166739_2%20-%20Being%20a%20Delbarton%20Student%20Means%20-%20Zachary.mp4',
  'https://think12-prod.s3.amazonaws.com/2/1663333158_5%20-%20Resources%20-%20Braden%20Lalin.mp4',
  'https://think12-prod.s3.amazonaws.com/2/1663363247_8%20-%20inclusive%20-%20Dylan%20Cawley.mp4',
];

export const QuestionAnswerVideo = () => {
  const theme = useTheme();
  const { configurations } = useContext(ConfigurationContext);

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item md={1}></Grid>
        <Grid item md={6} xs={12}>
          <Box p={{ xs: 2, md: 0, sm: 0, lg: 0 }}>
            <Typography variant='body2' fontWeight='900' color={theme.palette.secondary.main}>
              Q&A Videos™
            </Typography>
            <Typography
              variant='h2'
              fontWeight='bold'
              color={theme.palette.secondary.main}
              fontSize={{ xs: 40, md: 45, sm: 40, lg: 60 }}
              lineHeight={1}
            >
              Hear all about <br /> {configurations.schoolShortName ? configurations.schoolShortName : ''} from real{' '}
              <br /> {configurations.schoolShortName ? configurations.schoolShortName : ''} students.
            </Typography>
            {urls.isProduction() ? (
              <Box position='relative' mt={7}>
                <video src={`${videoUrls[0]}#t=0.01`} controls preload='auto' width='100%' playsInline></video>
              </Box>
            ) : (
              <Box position='relative' mt={7}>
                <img
                  src='/assets/images/home-QA-video1.png'
                  alt=''
                  width={'100%'}
                  style={{
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    objectFit: 'cover',
                  }}
                />
                <Link>
                  <img
                    width={'8%'}
                    src='/assets/icons/play-button.svg'
                    alt=''
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'Translate(-50%, -50%)',
                    }}
                  />
                </Link>
              </Box>
            )}
            <Box
              mt={2}
              display={{ lg: 'flex', md: 'flex', sm: 'flex', xs: 'none' }}
              justifyContent={{
                xs: 'center',
                md: 'start',
                sm: 'center',
                lg: 'start',
              }}
            >
              <Button
                variant='contained'
                sx={{
                  // background: COLORS.secondary.main,
                  background: theme.palette.secondary.main,
                  color: 'white',
                  borderRadius: '100px',
                }}
                href={ROUTES.QA}
              >
                <Typography py={1} px={5} fontSize={14} textTransform='capitalize' fontWeight='900'>
                  See More
                </Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item md={5} xs={12}>
          <Box px={{ xs: 2, md: 0, sm: 0, lg: 0 }}>
            {urls.isProduction() ? (
              <Box position='relative' pt={{ xs: 0, md: 10, sm: 8, lg: 10 }}>
                <video src={`${videoUrls[1]}#t=0.01`} controls preload='auto' width='100%'></video>
              </Box>
            ) : (
              <Box position='relative' pt={{ xs: 0, md: 10, sm: 8, lg: 10 }}>
                <img
                  src='/assets/images/home-QA-video2.png'
                  alt=''
                  width={'100%'}
                  style={{
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    objectFit: 'cover',
                  }}
                />
                <Link>
                  <img
                    width={'9%'}
                    src='/assets/icons/play-button.svg'
                    alt=''
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'Translate(-50%, -50%)',
                    }}
                  />
                </Link>
              </Box>
            )}
            {urls.isProduction() ? (
              <Box position='relative' mt={2}>
                <video src={`${videoUrls[2]}#t=0.01`} controls preload='auto' width='100%'></video>
              </Box>
            ) : (
              <Box position='relative' mt={2}>
                <img
                  src='/assets/images/home-QA-video3.png'
                  alt=''
                  width={'100%'}
                  style={{
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    objectFit: 'cover',
                  }}
                />
                <Link>
                  <img
                    width={'9%'}
                    src='/assets/icons/play-button.svg'
                    alt=''
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'Translate(-50%, -50%)',
                    }}
                  />
                </Link>
              </Box>
            )}
            <Box
              mt={2}
              display={{ lg: 'none', md: 'none', sm: 'none', xs: 'flex' }}
              justifyContent={{
                xs: 'center',
                md: 'start',
                sm: 'start',
                lg: 'start',
              }}
            >
              <Button
                variant='contained'
                sx={{
                  background: COLORS.secondary.main,
                  color: 'white',
                  borderRadius: '100px',
                }}
                href={ROUTES.QA}
              >
                <Typography py={1} px={5} fontSize={14} textTransform='capitalize'>
                  See More
                </Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
