import React, { useState, useEffect } from "react";
import { familyNetworkAlphabetsData } from "constants";
import Box from "@mui/system/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import COLORS from "constants/colors";
import Divider from "@mui/material/Divider";
import Container from "@mui/system/Container";
import InputBase from "@mui/material/InputBase";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Pagination from "@mui/material/Pagination";
import FamiliesTable from "./components/FamiliesTable";

import apiRequest from 'api/apiRequest';
import urls from 'api/urls';

export const SelectFamilies = (props) => {
  const { onCancel, onEnd } = props || {};

  const [search, setSearch] = useState("");
  const [selectedFamilies, setSelectedFamilies] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const doSearch = () => {
    apiRequest({
      method: 'get',
      url: '/families?per_page=10&searchKey=' + search + "&page=" + currentPage + "&offset=" + (currentPage - 1) * itemsPerPage,
    }).then((res) => {
      setSearchResults(res.data || []);
      setCurrentPage(res.current_page);
      setTotalPages(res.last_page);
      setTotalItems(res.total);
    });
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    doSearch();
  }, [search, currentPage]);

  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Paper
          sx={{
            borderRadius: "5px",
            p: 5,
          }}
          elevation={0}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">
              Select the Families that are part of this club
            </Typography>
          </Box>

          <Box mt={3} justifyContent="space-between" display="flex">
            {familyNetworkAlphabetsData.map((item) => (
              <Box display="flex" justifyContent="space-between">
                <Typography fontSize={16} color="primary" fontWeight="bold">
                  <a style={{cursor:'pointer'}} onClick={() => setSearch(item)}>{item}</a>
                </Typography>
                <Divider
                  style={{ width: "100%" }}
                  variant="middle"
                  flexItem
                  orientation="vertical"
                />
              </Box>
            ))}
          </Box>

          <Box>
            <Box
              component="form"
              mt={5}
              sx={{
                display: "flex",
                bgcolor: COLORS.white.light,
                alignItems: "center",
                borderRadius: "20px",
              }}
            >
              <IconButton sx={{ p: "10px" }} aria-label="menu">
                <SearchIcon sx={{ color: COLORS.blue.secondary }} />
              </IconButton>
              <InputBase
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search by school name"
              />
            </Box>
          </Box>

          <Box mt={2}>
            <FamiliesTable families={props.families} setFamilies={props.setFamilies} searchResults={searchResults.slice(0,10)}/>
            <div style={{display:'flex', justifyContent:'center', marginTop:'20px'}}>
            <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />
            </div>
             
          </Box>
          {/* <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              onClick={onCancel}
              sx={{ px: 5, mr: 2 }}
              className="MuiButton-profile"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              sx={{ px: 5 }}
              className="MuiButton-profile"
              variant="contained"
            >
              Confirm
            </Button>
          </Box> */}
        </Paper>
      </Container>
    </React.Fragment>
  );
};

export default SelectFamilies;
