import React, { useEffect } from "react";
import COLORS from "constants/colors";
import AddMeeting from "../AddMeeting";
import Meeting from "../Meeting";
import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useParams } from "react-router-dom";
import useTheme from "@mui/material/styles/useTheme";

export const About = (props) => {
  const params = useParams();
  const [club, setClub] = React.useState(props.club ? props.club : {});
  const [about, setAbout] = React.useState(props.club ? props.club.about : "");
  const [meetings, setMeetings] = React.useState((props.club && props.club.meetings) ? props.club.meetings : []);

  const theme = useTheme();

  const addEmptyMeeting = () => {
    const meeting = {
      numStudents: 0,
      grades: "",
      meetingDay: "",
      meetingTime: "",
    }
    addMeeting(meeting);
  };

  const addMeeting = (meeting) => {
    setMeetings([...meetings, meeting]);
  }

  const removeMeeting = (meeting) => {
    setMeetings(meetings.filter((m) => m !== meeting));
  }

  useEffect(() => {
    club.about = about;
    if (props.onClubUpdate) {
      props.onClubUpdate(club);
    }
    setClub(club);
  }, [about]);

  useEffect(() => {
    if (props.onMeetingsUpdate) {
      props.onMeetingsUpdate(meetings);
    }
  }, [meetings]);

  useEffect(() => {
    setClub(props.club);
    setMeetings(props.club.meetings);
    if (!about) {
      setAbout(props.club.about);
    }
  }, [props.club]);
  

  const [isAddMeeting, setIsAddMeeting] = React.useState(false);
  return (
    <React.Fragment>
      <Grid container>
        <Grid item md={2}></Grid>
        <Grid item md={9}>
          <Box
            px={{ md: 0, lg: 0, sm: 3, xs: 3 }}
            mt={5}
            display="flex"
            justifyContent="space-between"
          >
            <Typography variant="h5" fontWeight="800">
              About Our Club
            </Typography>
          </Box>

          <FormControl fullWidth>
            <TextField
              InputProps={{ disableUnderline: "false" }}
              variant="filled"
              required
              multiline
              fullWidth
              rows={5}
              id="name"
              name="name"
              value={about}
              sx={{ mb: 2, px: { md: 0, lg: 0, sm: 3, xs: 3 } }}
              placeholder="Write a short description of the club"
              onChange={(e) => setAbout(e.target.value)}
            />
          </FormControl>
          <Box px={{ md: 0, lg: 0, sm: 3, xs: 3 }}>
            {meetings && meetings.map((meeting, index) => (

              <Meeting disabled={true} meeting={meeting} onDelete={() => removeMeeting(meeting)}/>

            ))}
          </Box>
          <Box ml={{ xs: 2, sm: 2, md: 0, lg: 0 }} mt={3}  display="flex" alignItems="center">
            <Fab onClick={() => setIsAddMeeting(!isAddMeeting)} color="primary" sx={{ backgroundColor: theme.palette.primary.main, '&:hover': { backgroundColor: theme.palette.secondary.main }}}>
              <AddIcon />
            </Fab>
            <Typography
              ml={3}
              variant="body1"
              fontWeight="bold"
              color={theme.palette.primary.main}
            >
              Add a new meeting time
            </Typography>
          </Box>
          {isAddMeeting && (
            <Box mt={4}>
              <AddMeeting setIsAddMeeting={setIsAddMeeting} onConfirm={(data) => addMeeting(data)} />
            </Box>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default About;
