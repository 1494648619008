import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { COLORS } from 'constants/colors';
import SelectQAText from '../SelectQAText';
export const QAQuestionBox = (props) => {
  const { selected, onClick, topic } = props;
  const theme = useTheme();

  return (
    <React.Fragment>
      <Grid
        className='overflow-questionBox'
        container
        spacing={2}
        gap={2}
        mt={5}
        overflow={'hidden'}
        justifyContent={'center'}
      >
        <Grid item mb={14} mt={5}>
          <Box sx={{ position: 'relative' }}>
            <Box
              width={{ md: 300, sm: 300, xs: 215, lg: 240, xl: 290 }}
              height={{ md: 160, xs: 150 }}
              sx={{
                borderRadius: 10,
                boxShadow: '0px 4px 10px 7px rgba(0, 0, 0, 0.05)',
                position: 'relative',
                bgcolor: selected ? `${theme.palette.primary.main}` : '#FFFF',
                cursor: 'pointer',
              }}
              onClick={onClick}
            >
              {selected && <SelectQAText title='Q.' />}
              {selected && <SelectQAText title='A.' />}
              <Grid item display='flex' alignItems='center' justifyContent='center' height={160}>
                <Typography
                  fontSize={{ md: 18 }}
                  fontWeight={700}
                  color={selected ? 'white' : COLORS.grey.main}
                  p={2}
                  textAlign='center'
                >
                  {topic}
                </Typography>
              </Grid>

              <Box
                sx={{
                  position: 'absolute',
                  bottom: '-10px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  zIndex: -1,
                }}
              >
                <Box
                  sx={{
                    width: 30,
                    height: 30,
                    bgcolor: selected ? `${theme.palette.primary.main}` : '#FFFF',
                    transform: 'rotate(45deg)',
                  }}
                ></Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default QAQuestionBox;
