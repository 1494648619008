import React, { useEffect } from "react";
import { ClubPageCover } from "../ClubPageCover";
import { LeadersContact } from "../LeadersContact";
import { ClubPageHeading } from "../ClubPageHeading";
import { FamiliesCardsSection } from "../ClubPageFamilies";
import ClubHubUpComingEvents from "../ClubHubUpcomingEvents";
import { GallerySection } from "../Gallery";
import AuthContext from 'contexts/AuthContext';
import apiRequest from 'api/apiRequest';

export const ClubPage = (props) => {
  const [club, setClub] = React.useState();
  const { isAuthenticated, user, logout } = React.useContext(AuthContext);
  
  useEffect(() => {
    apiRequest({
      url: `/clubs/${props.match.params.clubId}`,
    }).then((res) => {
      setClub(res.data);
    });
  }, []);

  return (
    <React.Fragment>
      <ClubPageHeading club={club}/>
      <ClubPageCover club={club}/>
      <LeadersContact club={club}/>
      <ClubHubUpComingEvents club={club} />
      {(user && user.is_verified_family) ? <FamiliesCardsSection club={club}/> : []}
      <GallerySection club={club}/>
    </React.Fragment>
  );
};

export default ClubPage;
