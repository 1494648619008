import React from 'react';
import { ManageStaffHeader } from './components';
import AllLeaders from './components/AllLeaders';
import LeaderProfile from './components/LeaderProfile';
import Leadership from './components/Leadership/Leadership';
import ManageStaffLeadersArea from './components/ManageStaffLeadersArea';
import { toast } from 'react-toastify';
import apiRequest from 'api/apiRequest';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Badge from '@mui/material/Badge';

export const ManageStaffDirectory = () => {
  const [isLoadingLeaders, setIsLoadingLeaders] = React.useState(true);
  const [isLoadingStaff, setIsLoadingStaff] = React.useState(true);
  const [leaders, setLeaders] = React.useState([]);
  const [staff, setStaff] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [pages, setPages] = React.useState(null);
  const [query, setQuery] = React.useState('');

  const fetchLeadership = async () => {
    try {
      setIsLoadingLeaders(true);
      const resp = await apiRequest({
        method: 'get',
        url: `/staff?filter[staff_profile][has][is_standard]=0`,
      });

      setLeaders(resp.data);
      setIsLoadingLeaders(false);
    } catch (error) {
      toast.error(error);
      setIsLoadingLeaders(false);
    }
  };

  const fetchStaff = async (pageNumber) => {
    try {
      setIsLoadingStaff(true);
      const resp = await apiRequest({
        method: 'get',
        url: `/staff?filter[staff_profile][has][is_standard]=1&page=${pageNumber}${
          query.trim() !== '' ? `&search[query]=${encodeURIComponent(query.trim())}` : ''
        }`,
      });

      if (pageNumber === 1) setPages(resp.last_page);
      setStaff(resp.data);
      setIsLoadingStaff(false);
    } catch (error) {
      toast.error(error);
      setIsLoadingStaff(false);
    }
  };

  React.useEffect(() => {
    if (leaders.length === 0) fetchLeadership();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    fetchStaff(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const fetchNextPage = () => {
    if (page < pages) {
      const nextPage = page + 1;
      setPage(nextPage);
    }
  };

  const fetchPrevPage = () => {
    if (page > 1) {
      const nextPage = page - 1;
      setPage(nextPage);
    }
  };

  const handleSearch = () => {
    setPage(1);
    fetchStaff(1);
  };

  return (
    <React.Fragment>
      <ManageStaffHeader query={query} setQuery={setQuery} onSearch={handleSearch} />
      <Leadership leaders={leaders} staff={staff} isLoading={isLoadingLeaders} />
      <ManageStaffLeadersArea leaders={leaders} staff={staff} isLoading={isLoadingStaff} />
      {pages > 1 && (
        <Box display='flex' justifyContent='center' alignItems='center'>
          <Grid>
            <Button disabled={page === 1} onClick={fetchPrevPage} style={{ fontSize: '20px' }}>
              {'<'}
            </Button>
            <Badge badgeContent={page} />
            <Button style={{ fontSize: '20px' }} onClick={fetchNextPage} disabled={page >= pages}>
              {'>'}
            </Button>
          </Grid>
        </Box>
      )}
    </React.Fragment>
  );
};

export const ManageStaff = AllLeaders;

export default ManageStaffDirectory;

export const ManageStaffProfile = LeaderProfile;
