import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import ConfigurationContext from '../../../../../contexts/ConfigurationContext';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { MessagesTab } from 'components/Global/Messages';
// import { PROGRAM_CONTENT } from 'constants/program_content';
// import urls from 'api/urls';

import { COLORS } from 'constants/colors';

export const FamilyProfileDetails = ({ profile, type }) => {
  const [isMessageModalOpen, setIsMessageModalOpen] = React.useState(false);
  const theme = useTheme();
  const { configurations, schools, isFeatureEnabled } = useContext(ConfigurationContext);
  const linkedIn = profile.social_media_accounts?.find((sm) => sm.provider.toLowerCase().trim() === 'linkedin');
  const facebook = profile.social_media_accounts?.find((sm) => sm.provider.toLowerCase().trim() === 'facebook');
  const twitter = profile.social_media_accounts?.find((sm) => sm.provider.toLowerCase().trim() === 'twitter');

  return (
    <Box>
      <Grid container maxWidth='xl' justifyContent='center'>
        <Box sx={{ position: 'relative' }} display={{ xs: 'none', md: 'flex' }}>
          <Box sx={{ position: 'absolute', top: 410, right: -80, zIndex: -1 }}>
            <svg xmlns='http://www.w3.org/2000/svg' width='216' height='216' viewBox='0 0 216 216' fill='none'>
              <circle
                r='93'
                transform='matrix(-1 0 0 1 108 108)'
                stroke={theme.palette.primary.main}
                stroke-opacity='0.3'
                stroke-width='30'
              />
            </svg>
          </Box>
        </Box>
        <Grid item container md={10} sm={12} mt={10}>
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{ position: 'absolute', zIndex: -1, bottom: 350, left: 250 }}
              display={{ xs: 'none', md: 'flex', sm: 'none' }}
            >
              <svg xmlns='http://www.w3.org/2000/svg' width='154' height='154' viewBox='0 0 154 154' fill='none'>
                <circle
                  r='62'
                  transform='matrix(-1 0 0 1 77 77)'
                  stroke={theme.palette.primary.main}
                  stroke-opacity='0.3'
                  stroke-width='30'
                />
              </svg>
            </Box>
          </Box>
          {type === 'ambassador' ? (
            <Box sx={{ position: 'relative' }}>
              <Box sx={{ position: 'absolute' }}>
                <img src='/assets/images/tag.png' alt='' />
              </Box>
              <Box sx={{ position: 'absolute', top: 5, left: 15 }}>
                <Typography fontSize={{ md: 17 }} fontWeight={600} color={COLORS.white.main}></Typography>
              </Box>
            </Box>
          ) : (
            ''
          )}

          <Grid item md={4} sm={6} xs={12}>
            <Box
              p={{ md: 2, xs: 2 }}
              bgcolor={COLORS.white.main}
              sx={{ boxShadow: '0px 4px 15px 10px rgba(0, 0, 0, 0.03)' }}
            >
              <img
                // src={profile?.profileImageUrl ? profile?.profileImageUrl : '/assets/images/family-profile.svg'}
                src={
                  // profile?.profileImageUrl
                  //   ? profile?.profileImageUrl
                  //   : PROGRAM_CONTENT[urls.programId()].default_family_profile_image
                  //   ? PROGRAM_CONTENT[urls.programId()].default_family_profile_image
                  //   : '/assets/images/family-profile.svg'
                  profile?.profileImageUrl
                    ? profile?.profileImageUrl
                    : configurations?.default_user_profile_image?.url
                    ? configurations?.default_user_profile_image?.url
                    : '/assets/images/family-profile.svg'
                }
                alt=''
                width='100%'
                height='auto'
              />
            </Box>
          </Grid>

          <Grid item container md={8} sm={6} justifyContent='center' paddingX={5} mt={4}>
            <Grid item md={6}>
              {profile?.last_name ? (
                <Typography fontSize={{ md: 40 }} fontWeight={700}>
                  {profile.last_name} Family
                </Typography>
              ) : (
                ''
              )}
              <Box>
                <Typography fontSize={{ md: 18 }} fontWeight={600} mt={2} width={260} wrap>
                  {/* {profile?.head?.first_name || ''} */}
                  {profile?.members &&
                    profile.members.map((mem, i) => `${mem.first_name}${i < profile.members.length - 1 ? ', ' : ''}`)}
                </Typography>

                <Typography fontSize={{ md: 18 }} fontWeight={600} mt={2} width={260} wrap>
                  {profile?.primary_email && profile?.is_primary_email_visible ? (
                    <a
                      href={`mailto:${profile?.primary_email}`}
                      style={{
                        color: 'rgba(0, 0, 0, 0.87)',
                        textDecoration: 'none',
                      }}
                    >
                      {profile.primary_email}
                    </a>
                  ) : (
                    ''
                  )}
                  {profile?.secondary_email && profile?.is_secondary_email_visible ? (
                    <>
                      <br />
                      <a
                        href={`mailto:${profile.secondary_email}`}
                        style={{
                          color: 'rgba(0, 0, 0, 0.87)',
                          textDecoration: 'none',
                        }}
                      >
                        {profile.secondary_email}
                      </a>
                    </>
                  ) : (
                    ''
                  )}
                  <br />
                  {profile?.attending_school_since && configurations?.schoolShortName
                    ? `Member of ${configurations?.schoolShortName} since ${profile?.attending_school_since}`
                    : ''}
                </Typography>
                {profile?.members?.map((mem) =>
                  mem?.educations?.map((ed) => {
                    const foundSchool = schools.find((s) => s.id === ed.outcome_id);
                    if (!foundSchool) return <React.Fragment></React.Fragment>;
                    return (
                      <>
                        <Typography fontSize={{ md: 18 }} fontWeight={400} mt={4}>
                          {mem?.first_name} went to {foundSchool?.outcomeTitle || 'School Not Found'}
                        </Typography>
                        {foundSchool?.url && (
                          <Box mt={2}>
                            <img src={foundSchool?.url} alt={foundSchool?.outcomeTitle} height='30px' />
                          </Box>
                        )}
                      </>
                    );
                  })
                )}
              </Box>
            </Grid>
            <Grid
              item
              md={6}
              mt={{ xs: 2, md: 0 }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              {isFeatureEnabled('family_message') ? (
                profile?.is_contactable_by_current_user ? (
                  <Button
                    variant='contained'
                    color='secondary'
                    sx={{ borderRadius: 50, padding: 2, width: 220 }}
                    onClick={() => setIsMessageModalOpen(true)}
                  >
                    Message
                  </Button>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              <Box display='flex' justifyContent='flex-end' marginX={2} mt={2}>
                {facebook ? (
                  <a
                    href={facebook.url.toLowerCase().startsWith('http') ? facebook.url : `//${facebook?.url}`}
                    target='blank'
                  >
                    <IconButton disableRipple>
                      <Box sx={{ position: 'relative' }}>
                        <FacebookIcon color='primary' fontSize='large' />
                      </Box>
                    </IconButton>
                  </a>
                ) : (
                  ''
                )}

                {twitter ? (
                  <a
                    href={twitter.url.toLowerCase().startsWith('http') ? twitter.url : `//${twitter?.url}`}
                    target='blank'
                  >
                    <IconButton disableRipple>
                      <Box sx={{ position: 'relative' }}>
                        <TwitterIcon color='primary' fontSize='large' />
                      </Box>
                    </IconButton>
                  </a>
                ) : (
                  ''
                )}

                {linkedIn ? (
                  <a
                    href={linkedIn.url.toLowerCase().startsWith('http') ? linkedIn.url : `//${linkedIn?.url}`}
                    target='blank'
                  >
                    <IconButton disableRipple>
                      <Box sx={{ position: 'relative' }}>
                        <LinkedInIcon color='primary' fontSize='large' />
                      </Box>
                    </IconButton>
                  </a>
                ) : (
                  ''
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Modal
        open={isMessageModalOpen}
        onClose={() => setIsMessageModalOpen(false)}
        aria-labelledby='Messages'
        aria-describedby='Messages'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            // boxShadow: 24,
            width: '100%',
            maxWidth: '1000px',
          }}
        >
          <MessagesTab isNew={true} isModal={true} members={profile?.members ? profile?.members : []} />
        </Box>
      </Modal>
    </Box>
  );
};

export default FamilyProfileDetails;
