const delbartonContent = {
  logo: '/assets/images/logo-delbarton.png',
  home_hero_image: 'assets/images/home-hero-delbarton.png',
  home_hero_subtitle: (schoolName) => `He is called to excellence. At ${schoolName}, we will answer his call.`,
  default_family_profile_image: '/assets/images/family-profile-delbarton.jpg',
  auth_screen_image: '/assets/images/auth-screen-delbarton.jpg',
};

const sawgrassContent = {
  logo: '/assets/images/logo-sawgrass.png',
  home_hero_image: 'assets/images/home-hero-sawgrass.jpg',
  auth_screen_image: '/assets/images/auth-screen-sawgrass.jpg',
};

export const PROGRAM_CONTENT = {
  localhost: delbartonContent,
  'localhost:3000': delbartonContent,
  'delbarton.think-12.com': delbartonContent,
  'app.dev.think-12.com': delbartonContent,
  'app.staging.think-12.com': delbartonContent,
  'sawgrass.think-12.com': sawgrassContent,
};
