import React, { useEffect } from 'react';
import { Grid, Typography, Box, Container } from '@mui/material';

import { ClubFamilyCard } from 'pages/ManageClubHub/components/ClubFamilyCard';

export const FamiliesCardsSection = (props) => {
  const [families, setFamilies] = React.useState([]);
  useEffect(() => {
    if (props.club && families.length === 0) {
      setFamilies(props.club.families);
    }
  }, [props.club]);
  
  if (families.length === 0) {
    return [];
  }
  return (
    <Box mt={5} mb={5}>
      <Container maxWidth='xl'>
        <Box display='flex' justifyContent='center'>
          <Grid item container>
            <Grid item md={12} sm={12} lg={12}>
              <Typography
                color='#131416'
                fontSize={{ md: 40 }}
                fontWeight={700}
              >
                Families Following this Club
              </Typography>
              <Grid item md={12}></Grid>
            </Grid>
          </Grid>
        </Box>

        <Box mt={6}>
          <Grid container spacing={3} justifyContent="center">
            {families.slice(0,3).map((family, index) => (
              <Grid item xs={12} md={4} key={index}>
                <ClubFamilyCard family={family}/>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};
