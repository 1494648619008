import React, { useEffect } from "react";
import AddStaffCard from "../AddStaffCard";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/system/Container";
import Typography from "@mui/material/Typography";
import AddStaffModal from "../AddStaffModal";
import DeleteModal from "../DeleteModal";
import urls from "api/urls";

const AddStaff = (props) => {
  const [leaders, setLeaders] = React.useState(props.leaders || []);
  useEffect(() => {
    setLeaders(props.leaders || []);
  }, [props.leaders]);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(leaders);
    }
  }, [leaders]);

  const [isShowStaffModal, setIsShowStaffModal] = React.useState(false);
  const [deleteStaffId, setDeleteStaffId] = React.useState(null);

  const [isDeleteModal, setIsDeleteModal] = React.useState(false);

  const removeLeader = (id) => {
    setDeleteStaffId(id);
    setIsDeleteModal(true);
  };

  return (
    <React.Fragment>
      <Container maxWidth="xl" style={{marginBottom:'250px'}}>
        <Box my={5}>
          <Typography variant="h4">
            Contact our leaders to learn more about our club
          </Typography>
        </Box>

        <Grid container spacing={3} mb={15}>
          <Grid item md={6} sm={12} xs={12}>
            {leaders.length ? (
              <Box display="flex" flexDirection="column" position="relative" className="club-leader-container big">
                <img className="club-leader-image"
                  src={
                    leaders[0].image
                      ? urls.getApiDomain() + leaders[0].image.path
                      : "/assets/images/profiles-admin.svg"
                  }
                  alt=""
                />
                <Box my={2} className="club-leader-text">
                  <Typography mb={1} variant="h5" fontWeight="800">
                    {leaders[0].user.first_name +
                      " " +
                      leaders[0].user.last_name}
                  </Typography>
                  <Typography variant="caption">{leaders[0].title}</Typography>

                  <img className="club-leader-remove"
                    src="/assets/icons/cancel-icon.svg"
                    alt=""
                    onClick={() => removeLeader(leaders[0].id)}
                  />
                </Box>
              </Box>
            ) : (
              <AddStaffCard
                height={"300px"}
                onOpenModal={() => setIsShowStaffModal(true)}
              />
            )}
          </Grid>
          <Grid sm={12} xs={12} item md={6} marginTop='4em'>
            <Grid container spacing={3} display="flex" flexDirection="column" className="club-leader-container">
              <Grid item md={8} spacing={3}>
                {leaders.length >= 2 ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    position="relative"
                  >
                    <img className="club-leader-image"
                      src={
                        leaders[1].image
                          ? urls.getApiDomain() + leaders[1].image.path
                          : "/assets/images/profiles-admin.svg"
                      }
                      alt=""
                    />
                    <Box my={2} className="club-leader-text">
                      <Typography mb={1} variant="h5" fontWeight="800">
                        {leaders[1].user.first_name +
                          " " +
                          leaders[1].user.last_name}
                      </Typography>
                      <Typography variant="caption">
                        {leaders[1].title}
                      </Typography>

                      <img className="club-leader-remove"
                        src="/assets/icons/cancel-icon.svg"
                        alt=""
                        onClick={() => removeLeader(leaders[1].id)}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Grid item md={6} sm={12} xs={12}>
                    <AddStaffCard
                      height={"300px"}
                      onOpenModal={() => setIsShowStaffModal(true)}
                    />
                  </Grid>
                )}
                {leaders.length >= 3 && leaders[2].user ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    position="relative"
                    className="club-leader-container"
                    mt={4}
                  >
                    <img className="club-leader-image"
                      src={
                        leaders[2].image
                          ? urls.getApiDomain() + leaders[2].image.path
                          : "/assets/images/profiles-admin.svg"
                      }
                      alt=""
                    />
                    <Box my={2} className="club-leader-text">
                      <Typography mb={1} variant="h5" fontWeight="800">
                        {leaders[2].user.first_name +
                          " " +
                          leaders[2].user.last_name}
                      </Typography>
                      <Typography variant="caption">
                        {leaders[2].title}
                      </Typography>

                      <img className="club-leader-remove"
                        src="/assets/icons/cancel-icon.svg"
                        alt=""
                        onClick={() => removeLeader(leaders[2].id)}
                      />
                    </Box>
                  </Box>
                ) : <Grid item md={6} sm={12} xs={12} mt={4}>
                <AddStaffCard
                  height="300px"
                  onOpenModal={() => setIsShowStaffModal(true)}
                />
              </Grid>}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <AddStaffModal
        onConfirm={(staff) => {
          setIsShowStaffModal(false);
          setLeaders([...leaders, staff]);
        }}
        setIsShowStaffModal={setIsShowStaffModal}
        isShowStaffModal={isShowStaffModal}
      />
      <DeleteModal
        title="Delete Club Leader"
        text="Are you sure you want to delete?"
        setIsDeleteModal={setIsDeleteModal}
        isDeleteModal={isDeleteModal}
        onConfirm={() => {
          setIsDeleteModal(false);
          setLeaders(leaders.filter((staff) => staff.id !== deleteStaffId));
        }}
      />
    </React.Fragment>
  );
};

export default AddStaff;
