import React, { useContext, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import Layout from 'components/Layout';
import Routes from 'core/Routes';
import { theme } from 'styles/theme';
import 'styles/_main.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfigurationContext from '../../contexts/ConfigurationContext';
import AuthContext from '../../contexts/AuthContext';
import ProfileContext from '../../contexts/ProfileContext';
import ScrollWrapper from '../ScrollWrapper';
import Loader from '../../components/Global/Loader';
import MessagingContext from 'contexts/MessagingContext';

export const App = () => {
  const { configurations, isLoading, isFeatureEnabled } = useContext(ConfigurationContext);
  const { user } = useContext(AuthContext);
  const { getUnreadCount } = useContext(MessagingContext);
  const { fetchProfile, profile, isLoading: isProfileLoading } = useContext(ProfileContext);
  const [scriptsAdded, setScriptsAdded] = React.useState(false);

  useEffect(() => {
    if (user && !profile) {
      if (!isProfileLoading) fetchProfile(user.id);
    }
    if (configurations?.programName) document.title = configurations.programName;
    if (configurations.color2) theme.palette.primary.main = configurations.color2;
    if (configurations.color1) theme.palette.secondary.main = configurations.color1;
    if (configurations.color2) theme.palette.secondary.dark = configurations.color2;
    if (configurations.color3) theme.palette.warning.main = configurations.color3;
  }, [configurations, user, profile, isProfileLoading, fetchProfile]);

  React.useEffect(() => {
    if (user) {
      if (isFeatureEnabled('family_message')) getUnreadCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (isFeatureEnabled('family_message') && user) getUnreadCount();
    }, 15000);
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (configurations?.scripts?.length > 0 && !scriptsAdded) {
      setScriptsAdded(true);
      for (let i = 0; i < configurations.scripts.length; i++) {
        let scriptBody = configurations.scripts[i]?.body;
        if (/<script>/gi.test(scriptBody)) scriptBody = /(?<=<script>).*(?=<\/script>)/gi.exec(scriptBody)[0];
        if (scriptBody) {
          const script = document.createElement('script');
          script.innerText = scriptBody;
          document.head.appendChild(script);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurations]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <BrowserRouter>
        <ScrollWrapper>
          <ThemeProvider theme={theme}>
            <Layout>
              <CssBaseline />
              <Routes />
            </Layout>
          </ThemeProvider>
        </ScrollWrapper>
      </BrowserRouter>
      <ToastContainer theme='dark' position='top-center' style={{ fontSize: '0.8em' }} />
    </>
  );
};

export default App;
