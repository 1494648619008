import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ConfigurationContext from '../../../../contexts/ConfigurationContext';
import { useTheme } from '@mui/material/styles';
import EnrollButton from '../../../../components/EnrollButton';

export const ThanksBox = ({ qaData }) => {
  const theme = useTheme();
  const { configurations } = useContext(ConfigurationContext);

  return (
    <Grid container justifyContent='center'>
      <Grid item container md={10}>
        <Grid item md={6} display={{ xs: 'none', md: 'flex' }} alignItems='center'>
          <Box>
            <svg width='340' height='340' viewBox='0 0 340 340' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <circle
                cx='170'
                cy='170'
                r='150'
                stroke={theme.palette.primary.main}
                stroke-opacity='0.1'
                stroke-width='40'
              />
            </svg>
          </Box>
        </Grid>
        <Grid item md={6} paddingX={{ xs: 4, md: 0 }} mt={{ xs: 0, md: 0 }}>
          <svg xmlns='http://www.w3.org/2000/svg' width='80' height='63' viewBox='0 0 80 63' fill='none'>
            <path
              opacity='0.2'
              d='M0 41.1183C0 55.0649 8.89841 63 18.7588 63C27.6572 63 35.1126 55.5458 35.1126 46.4084C35.1126 37.5115 28.8597 31.2595 20.6828 31.2595C19.2398 31.2595 17.3158 31.7405 16.5943 31.9809C18.2778 23.8053 26.2142 13.9466 33.9101 9.37786L22.6068 0C9.3794 9.37786 0 24.5267 0 41.1183ZM44.492 41.1183C44.492 55.0649 53.3904 63 63.2508 63C72.1492 63 79.6047 55.5458 79.6047 46.4084C79.6047 37.5115 73.3517 31.2595 65.1748 31.2595C63.7318 31.2595 61.8078 31.7405 61.0863 31.9809C62.7698 23.8053 70.7062 13.9466 78.4022 9.37786L67.0988 0C53.8714 9.37786 44.492 24.5267 44.492 41.1183Z'
              fill={theme.palette.secondary.main}
            />
          </svg>
          <Box>
            <Typography fontSize={{ md: 50, xs: 30 }} fontWeight={900} color={theme.palette.secondary.main}>
              {qaData?.quoteText ||
                `Thank you Ms. Robertsand the entire staff at ${
                  configurations.schoolShortName ? configurations.schoolShortName : ''
                } for giving
              our kids the best learning environment and ongoing continued
              compassion and support.`}
            </Typography>
          </Box>
          <Box display='flex' justifyContent='flex-end'>
            <svg xmlns='http://www.w3.org/2000/svg' width='80' height='63' viewBox='0 0 80 63' fill='none'>
              <path
                opacity='0.2'
                d='M79.6045 21.8817C79.6045 7.93512 70.7061 -7.77923e-07 60.8457 -1.63995e-06C51.9473 -2.41787e-06 44.4919 7.4542 44.4919 16.5916C44.4919 25.4885 50.7448 31.7405 58.9217 31.7405C60.3647 31.7405 62.2887 31.2595 63.0102 31.0191C61.3267 39.1947 53.3903 49.0534 45.6944 53.6221L56.9977 63C70.2251 53.6221 79.6045 38.4733 79.6045 21.8817ZM35.1125 21.8817C35.1125 7.93512 26.2141 -4.66754e-06 16.3537 -5.52956e-06C7.45526 -6.30749e-06 -0.000155361 7.4542 -0.00015616 16.5916C-0.000156938 25.4885 6.25277 31.7405 14.4297 31.7405C15.8727 31.7405 17.7967 31.2595 18.5181 31.0191C16.8347 39.1947 8.89825 49.0534 1.20232 53.6221L12.5057 63C25.7331 53.6221 35.1125 38.4733 35.1125 21.8817Z'
                fill={theme.palette.secondary.main}
              />
            </svg>
          </Box>
          <Grid item display='flex' flexDirection='row' alignItems='center' mt={{ xs: 4, md: 0 }}>
            {/* <Avatar
              alt="Remy Sharp"
              sx={{ width: 120, height: 120, borderRadius: "50%" }}
              src="/static/images/avatar/1.jpg"
            /> */}
            <Box display='flex' flexDirection='column' ml={{ md: 2, xs: 3 }}>
              <Typography fontSize={{ md: 20 }} fontWeight={900}>
                {qaData?.quoteUser || `Tim A. Davist`}
              </Typography>
              <Typography fontSize={{ md: 14 }} fontWeight={400} mt={1}>
                {qaData?.quoteTitle || `User`}
              </Typography>
              <Box mt={2}>
                {/* <Button
                  variant='text'
                  sx={{
                    color: '#2E75FF',
                    fontSize: 15,
                    fontWeight: 700,
                    textDecoration: 'underline',
                  }}
                >
                  View Profile
                </Button> */}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ width: '100%' }} mt={{ xs: 10, md: 8 }} textAlign='center'>
          <Typography fontSize={{ md: 40 }} fontWeight={900} align='center'>
            Experience the {configurations.schoolShortName ? configurations.schoolShortName : ''} Difference
          </Typography>
          <EnrollButton topSpacing={4} bottomSpacing={20} />
        </Box>
      </Grid>
    </Grid>
  );
};
export default ThanksBox;
