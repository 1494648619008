import React from 'react';
import { Hero, Enroll, Explore, VideoRow, ExtraCurricular, QuestionAnswerVideo } from './components';
import ConfigurationContext from 'contexts/ConfigurationContext';

export const ManageHome = () => {
  const { isFeatureEnabled } = React.useContext(ConfigurationContext);

  return (
    <React.Fragment>
      {isFeatureEnabled('home_hero') && <Hero />}
      {isFeatureEnabled('home_questions') && <VideoRow />}
      {isFeatureEnabled('home_students_videos') && <QuestionAnswerVideo />}
      {isFeatureEnabled('home_ambassadors') && <ExtraCurricular />}
      {isFeatureEnabled('home_explore') && <Explore />}
      {isFeatureEnabled('home_enroll') && <Enroll />}
    </React.Fragment>
  );
};

export default ManageHome;
