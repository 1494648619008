import React from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { zodResolver } from '@hookform/resolvers/zod';

import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/system/Container';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import { CircularProgress } from '@mui/material';
import ConfigurationContext from 'contexts/ConfigurationContext';
import AuthContext from 'contexts/AuthContext';

import { COUNTRIES, STATES } from 'constants/countries';
import ConnectLinkedIn from '../ConnectLinkedIn';
import { useForm, Controller } from 'react-hook-form';
import { editAccountSchema } from '../../../../../../validations/schemas';
import ProfileContext from 'contexts/ProfileContext';

export const General = () => {
  const { profile, updateProfile } = React.useContext(ProfileContext);
  const [isConnectLinkedIn, setIsConnectLinkedIn] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const { configurations } = React.useContext(ConfigurationContext);
  const { updateUserName } = React.useContext(AuthContext);
  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
    getValues,
  } = useForm({
    resolver: zodResolver(editAccountSchema),
    defaultValues: {
      firstName: profile?.first_name || '',
      lastName: profile?.last_name || '',
      title: profile?.title || '',
      email: profile?.email || '',
      phone: profile?.phone_number || '',
      classYear: profile?.attending_school_since || '',
      city: profile?.location?.city || '',
      state: profile?.location?.subdivision || '',
      country: profile?.location?.country || '',
      zipCode: profile?.location?.code || '',
    },
  });

  // const userNameChange = () => {
  //   let userData = JSON.parse(localStorage.getItem("user"));
  //   userData.first_name = getValues("firstName");
  //   userData.last_name = getValues("lastName");
  // };

  const onSubmitHandle = async (values) => {
    if (!isUpdating) {
      try {
        setIsUpdating(true);
        await updateProfile(profile.id, values);
        setIsUpdating(false);
        toast.success('Profile Updated!');
        updateUserName(getValues('firstName'), getValues('lastName'));
      } catch (error) {
        toast.error('Something went wrong!');
      }
    }
  };

  return (
    <React.Fragment>
      <Container>
        <Box mt={5} display='flex' justifyContent='space-between'>
          <Typography variant='h5' fontWeight='bold'>
            General
          </Typography>
        </Box>
        <Grid container mt={2} spacing={3}>
          <Grid item md={6} xs={12} sm={6}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Family ID
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                InputProps={{ disableUnderline: 'false', readOnly: true }}
                hiddenLabel
                variant='filled'
                fullWidth
                color='secondary'
                sx={{ mb: 2 }}
                value={profile?.family_id}
              />
            </FormControl>
          </Grid>

          <Grid item md={6} xs={12} sm={6}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              First Name*
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                InputProps={{ disableUnderline: 'false' }}
                hiddenLabel
                variant='filled'
                fullWidth
                color='secondary'
                sx={{ mb: 2 }}
                error={!!errors['firstName']}
                helperText={errors['firstName'] ? errors['firstName'].message : ''}
                {...register('firstName')}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12} sm={6}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Last Name*
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                InputProps={{ disableUnderline: 'false' }}
                hiddenLabel
                variant='filled'
                fullWidth
                color='secondary'
                sx={{ mb: 2 }}
                error={!!errors['lastName']}
                helperText={errors['lastName'] ? errors['lastName'].message : ''}
                {...register('lastName')}
              />
            </FormControl>
          </Grid>

          <Grid item md={8} xs={12} sm={6}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Title
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                InputProps={{ disableUnderline: 'false' }}
                hiddenLabel
                variant='filled'
                fullWidth
                color='secondary'
                sx={{ mb: 2 }}
                error={!!errors['title']}
                helperText={errors['title'] ? errors['title'].message : ''}
                {...register('title')}
              />
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12} sm={6}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Member of {configurations.schoolShortName ? configurations.schoolShortName : ''} Since
            </InputLabel>
            <FormControl fullWidth>
              <Controller
                control={control}
                name='classYear'
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    select
                    error={!!errors['classYear']}
                    helperText={errors['classYear'] ? errors['classYear'].message : ''}
                    {...register('classYear')}
                  >
                    <MenuItem value=''>
                      <em>Select Year</em>
                    </MenuItem>
                    {Array.from({ length: 50 }, (_, i) => String(i + (Number(moment().format('YYYY')) - 49)))
                      .reverse()
                      .map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                  </TextField>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item md={6} xs={12} sm={6}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Email Address*
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                InputProps={{ disableUnderline: 'false' }}
                hiddenLabel
                variant='filled'
                fullWidth
                color='secondary'
                sx={{ mb: 2 }}
                error={!!errors['email']}
                helperText={errors['email'] ? errors['email'].message : ''}
                {...register('email')}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12} sm={6}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Contact Number
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                InputProps={{ disableUnderline: 'false' }}
                hiddenLabel
                variant='filled'
                fullWidth
                color='secondary'
                sx={{ mb: 2 }}
                error={!!errors['phone']}
                helperText={errors['phone'] ? errors['phone'].message : ''}
                {...register('phone')}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12} sm={6}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              City
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                InputProps={{ disableUnderline: 'false' }}
                hiddenLabel
                variant='filled'
                fullWidth
                color='secondary'
                sx={{ mb: 2 }}
                error={!!errors['city']}
                helperText={errors['city'] ? errors['city'].message : ''}
                {...register('city')}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12} sm={6}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              State
            </InputLabel>
            <FormControl fullWidth>
              <Controller
                control={control}
                name='state'
                render={({ field: { onChange, value } }) => (
                  <TextField
                    InputProps={{ disableUnderline: 'false' }}
                    value={value}
                    onChange={onChange}
                    select
                    error={!!errors['state']}
                    helperText={errors['state'] ? errors['state'].message : ''}
                  >
                    <MenuItem value=''>
                      <em>Select State</em>
                    </MenuItem>
                    {STATES && STATES.map((st) => <MenuItem value={st.abbreviation}>{st.name}</MenuItem>)}
                  </TextField>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12} sm={6}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Zip Code
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                InputProps={{ disableUnderline: 'false' }}
                hiddenLabel
                variant='filled'
                fullWidth
                color='secondary'
                sx={{ mb: 2 }}
                error={!!errors['zipCode']}
                helperText={errors['zipCode'] ? errors['zipCode'].message : ''}
                {...register('zipCode')}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12} sm={6}>
            <InputLabel className='MuiInputLabel-Profile-label' shrink>
              Country
            </InputLabel>
            <FormControl fullWidth>
              <Controller
                control={control}
                name='country'
                render={({ field: { onChange, value } }) => (
                  <TextField
                    InputProps={{ disableUnderline: 'false' }}
                    select
                    onChange={onChange}
                    value={value}
                    error={!!errors['country']}
                    helperText={errors['country'] ? errors['country'].message : ''}
                    // {...register('country')}
                  >
                    <MenuItem value=''>
                      <em>Select Country</em>
                    </MenuItem>
                    <Divider />
                    {COUNTRIES && COUNTRIES.map((country) => <MenuItem value={country.code}>{country.name}</MenuItem>)}
                  </TextField>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box my={10} display='flex' justifyContent='flex-end'>
              <Button
                sx={{ width: '233px', height: '60px', fontWeight: '500' }}
                className='MuiButton-profile'
                variant='contained'
                color='secondary'
                onClick={handleSubmit(onSubmitHandle)}
                disabled={isUpdating}
              >
                {isUpdating ? <CircularProgress style={{ color: 'white' }} /> : 'Save Changes'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <ConnectLinkedIn
        title='Connect Your LinkedIn Account'
        text={` ${
          configurations.schoolName ? configurations.schoolName : ''
        } would like to use your LinkedIn profile to set up your staff member account.`}
        setIsConnectLinkedIn={setIsConnectLinkedIn}
        isConnectLinkedIn={isConnectLinkedIn}
      />
    </React.Fragment>
  );
};

export default General;
