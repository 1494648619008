import React, {useEffect} from "react";
import styled from "styled-components";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import COLORS from "constants/colors";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import { Checkbox } from "@mui/material";
import { PropaneSharp } from "@mui/icons-material";

export const FamiliesTable = (props) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "white",
      color: "black",
      border: "none",
      fontWeight: "bold",
      fontSize: "18px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
      border: "none",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: COLORS.white.secondary,
      border: "none",
    },
  }));

  const hasFamily = (family) => {
    const familyExists = props.families.find((f) => f.id === family.id);
    return !!familyExists;
  }

  const addFamily = (family) => {
    props.setFamilies([...props.families, family]);
  }

  const removeFamily = (family) => {
    const newFamilies = props.families.filter((f) => f.id !== family.id);
    props.setFamilies(newFamilies);
  }

  const toggleFamily = (family) => {
    if (hasFamily(family)) {
      removeFamily(family);
    } else {
      addFamily(family);
    }
  }


  return (
    <TableContainer>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>Family Name</StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>Family Members</StyledTableCell>
            <StyledTableCell>Grade</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.searchResults.map((data, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>
                {" "}
                <Checkbox size="small" checked={hasFamily(data)} onClick={() => toggleFamily(data)} color="primary" />
              </StyledTableCell>
              <StyledTableCell>{data.last_name}</StyledTableCell>
              <StyledTableCell>
                <img src={data.cartoons} alt="" />
              </StyledTableCell>
              <StyledTableCell>{data.member_names.join(" ")}</StyledTableCell>
              <StyledTableCell>{data.member_grades.join(", ")}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FamiliesTable;
