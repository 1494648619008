import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export const TextArea = () => {
  const theme = useTheme();
  return (
    <React.Fragment>
      <Container maxWidth='false'>
        <Grid container alignItems='center' justifyContent='center'>
          <Grid item md={6} xs={11}>
            <Typography
              p={{ md: 5 }}
              my={5}
              variant={'h3'}
              fontWeight='900'
              sx={{ color: theme.palette.secondary.main }}
            >
              Selecting <br />
              (and staying in)
              <br /> the right school...
            </Typography>
          </Grid>
          <Grid item md={6} xs={1}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'right',
                pb: { md: 15, sm: 10, xs: 1 },
                pr: { md: 10, sm: 5, xs: 0 },
              }}
            >
              <svg width='120' height='120' viewBox='0 0 216 216' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <circle
                  cx='108'
                  cy='108'
                  r='93'
                  stroke={theme.palette.secondary.main}
                  stroke-opacity='0.3'
                  stroke-width='30'
                />
              </svg>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default TextArea;
