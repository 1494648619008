import React from 'react';
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Container from '@mui/system/Container';
import EditProfile from '../EditProfile';
import ProfileContext from 'contexts/ProfileContext';
// import { PROGRAM_CONTENT } from 'constants/program_content';
// import urls from 'api/urls';
import ConfigurationContext from 'contexts/ConfigurationContext';

export const Hero = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { familyProfile } = React.useContext(ProfileContext);
  const { configurations } = React.useContext(ConfigurationContext);

  return (
    <React.Fragment>
      <Container>
        <Grid container>
          <Grid item md={4} xs={12} sm={12}>
            <Box>
              <Box pt={3}>
                <img
                  src={
                    // familyProfile?.profile_image?.url
                    //   ? familyProfile?.profile_image?.url
                    //   : PROGRAM_CONTENT[urls.programId()].default_family_profile_image
                    //   ? PROGRAM_CONTENT[urls.programId()].default_family_profile_image
                    //   : '/assets/images/family-profile.svg'

                    familyProfile?.profile_image?.url
                      ? familyProfile?.profile_image?.url
                      : configurations?.default_user_profile_image?.url
                      ? configurations?.default_user_profile_image?.url
                      : '/assets/images/family-profile.svg'
                  }
                  alt=''
                  width='100%'
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            md={3}
            px={5}
            display='flex'
            justifyContent='center'
            alignItems={{ xs: 'center', sm: 'normal' }}
            flexDirection='column'
            xs={12}
            sm={12}
          >
            <Button
              sx={{ width: '208px', height: '50px' }}
              className='MuiButton-profile'
              variant='outlined'
              onClick={() => setIsOpen(true)}
            >
              Edit Profile Image
            </Button>
          </Grid>
        </Grid>
      </Container>
      <EditProfile setIsOpen={setIsOpen} isOpen={isOpen} />
    </React.Fragment>
  );
};

export default Hero;
