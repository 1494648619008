import { createTheme } from '@mui/material/styles';
import { COLORS } from 'constants/colors';

export let theme = createTheme({
  palette: {
    primary: {
      main: COLORS.primary.main,
      light: COLORS.primary.light,
    },

    secondary: {
      main: COLORS.secondary.main,
    },

    dark: {
      main: COLORS.dark.main,
      light: COLORS.dark.light,
      contrastText: COLORS.white.main,
    },

    warning: {
      main: COLORS.warning.main,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '& .MuiButton-navbar': {
            fontSize: 15,
            display: 'flex',
            alignItems: 'center',
            color: COLORS.white.main,
            textTransform: 'capitalize',
            padding: theme.spacing(2, 3, 3, 3),
            borderRadius: 0,
            // '&.MuiSelected': {
            //   borderTopLeftRadius: 10,
            //   borderTopRightRadius: 10,
            //   background: COLORS.secondary.main,
            // },
          },
          '& .MuiToolbar-root': {
            minHeight: 'auto',
            paddingTop: theme.spacing(1.2),
          },
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          '&.MuiPaper-profile-menu': {
            overflow: 'visible',
            marginTop: theme.spacing(1.5),
            borderRadius: 10,
            // filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.32))',
            boxShadow: '0px 4px 10px 3px rgba(0, 0, 0, 0.1)',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              marginLeft: theme.spacing(-0.5),
              marginRight: theme.spacing(1),
            },
          },
          '&.MuiPaper-card-layer': {
            background:
              'rgba(32, 33, 47, 0) linear-gradient(to bottom, rgba(32, 33, 47, 0) 10%, rgba(32, 33, 47, .8) 40%, rgba(32, 33, 47, .9) 75%, rgba(32, 33, 47, 1) 100%) repeat scroll 0 0',
            position: 'relative',
            marginTop: '-150px',
            height: '150px',
            boxShadow: '0 !important',
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-enroll': {
            fontSize: 18,
            boxShadow: 'none',
            borderRadius: 100,
            padding: theme.spacing(0.7, 7),
            lineHeight: '5px',
            textTransform: 'capitalize',
            display: 'flex',
            flexDirection: 'column',
          },
        },
        outlined: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
          '&:hover': {
            boxShadow: 'none',
          },
          '&.MuiButton-tips-filter': {
            borderRadius: 50,
            borderColor: COLORS.dark.main,
            color: COLORS.dark.main,
            fontWeight: 'bold',
          },
          '&.MuiButton-profile': {
            borderRadius: 50,
            borderColor: COLORS.secondary.main,
            color: COLORS.secondary.main,
            fontWeight: 'bold',
            textTransform: 'capitalize',
          },
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
          '&:hover': {
            boxShadow: 'none',
          },
          '&.MuiButton-profile': {
            borderRadius: 50,
            color: COLORS.white.main,
            fontWeight: 'bold',
            paddingTop: '5px',
            paddingBottom: '5px',
            fontSize: '12px',
            textTransform: 'capitalize',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.MuiTypography-profile-sidebar': {
            fontSize: '14px',
            // marginTop: '20px',
            // marginBottom: '20px',
            cursor: 'pointer',
          },
          '&.MuiTypography-profile-sidebar-selected': {
            fontSize: '14px',
            // marginTop: '20px',
            // marginBottom: '20px',
            cursor: 'pointer',
            fontWeight: 900,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.white.secondary,
          '&:hover': {
            backgroundColor: COLORS.white.secondary,
          },
          '&.Mui-focused': {
            backgroundColor: COLORS.white.secondary,
          },
        },
      },
    },

    MuiCardMedia: {
      styleOverrides: {
        root: {
          '&.MuiCardMedia-tips': {},
        },
      },
    },

    MuiGrid: {
      styleOverrides: {
        root: {
          '&.Mui-Grid-image-silder': {
            height: 500,
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.MuiInputLabel-Profile-label': {
            color: COLORS.grey.dark,
            fontSize: '18px',
            fontWeight: '400',
          },
        },
      },
    },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.MuiListItemButton-sidebar': {
            color: COLORS.white.main,
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
            padding: theme.spacing(2, 4),
            '&.Mui-selected': {
              background: COLORS.secondary.main,
            },
          },
        },
      },
    },
  },
};

// export default theme;
