import axios from 'axios';
import urls from './urls';

axios.defaults.headers.common['X-Think12-Program-Id'] = urls.programId();

const apiRequest = (options, queryOptions = {}) =>
  new Promise((resolve, reject) => {
    axios({
      baseURL: urls.baseUrl,
      ...options,
    })
      .then((response) => {
        const { returnFullResponse = true } = queryOptions;
        resolve(returnFullResponse ? response.data : response.data.data);
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          // error from server
          console.log('Errors from Server: ', error.response.data.data);
        } else {
          // error in request
          console.error('Error Message:', error.message);
        }
        // if (error.response.status === 400) {
        //   return reject('Unauthorized');
        // }
        //reject(error.response.data.message || error.message);
      });
  });

export default apiRequest;
