import * as React from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { AuthLandingScreen } from "pages/ManageAuth/components/AuthLandingScreen/index";
import AuthSignUpForm from "../AuthSignUpForm";
import { SignUpModal } from "./components/SignUpModal/index";
import { SignUpSnackBar } from "./components/SignUpSnackBar/index";
import { useState } from "react";
import GreetingScreen from "../GreetingScreen";

export const CreateAccount = () => {
  const history = useHistory();
  const [values, setValues] = React.useState({
    showModal: false,
    showAlert: false,
  });
  const [userRole, setuserRole] = useState(0);

  const handleuserRoleChange = (e) => {
    if (e === 1 || e === 3) {
      handleShowModal();
    }
    setuserRole(e);
  };
  const { showModal, showAlert } = values;

  const handleShowModal = () => {
    setValues({ ...values, showModal: true });
  };
  const handleCloseModal = () => {
    setValues({ ...values, showModal: false });
  };
  const handleCloseAlert = () => {
    setValues({ ...values, showAlert: false });
  };

  const handleSignup = () => {
    handleCloseModal();
    setuserRole(4);
  };

  return (
    <React.Fragment>
      <AuthLandingScreen height="1120px">
        {userRole === 0 || userRole === 1 || userRole === 3 ? (
          <GreetingScreen handleuserRoleChange={handleuserRoleChange} />
        ) : (
          <AuthSignUpForm
            title="Welcome!"
            authButtonText="Sign Up"
            navigationButtonText="Log In Here"
            authButtonAction={handleShowModal}
            navigationButtonTextAction={() => history.push(ROUTES.SIGN_IN)}
            returnToSignUpAction={(e) => setuserRole(0)}
          />
        )}
        {showModal && (
          <SignUpModal
            open={showModal}
            close={handleCloseModal}
            confirmAction={() => setValues({ ...values, showAlert: true })}
            handleSignup={handleSignup}
          />
        )}
        {showAlert && (
          <SignUpSnackBar
            width={{ xs: 320, md: 570 }}
            open={showAlert}
            close={handleCloseAlert}
          />
        )}

        {/* <AuthForm
          title="Welcome!"
          authButtonText="Sign Up"
          navigationButtonText="Log In Here"
          navigationButtonTextAction={() => history.push(ROUTES.SIGN_IN)}
        /> */}
      </AuthLandingScreen>
    </React.Fragment>
  );
};

export default CreateAccount;
