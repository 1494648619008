import { createContext, useEffect, useState } from 'react';
import apiRequest from '../api/apiRequest';
import urls from '../api/urls';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState();

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadUser = () => {
    if (localStorage.token && localStorage.user) {
      setAuthToken(localStorage.token);
      setAuth(JSON.parse(localStorage.user), localStorage.token);
    }
  };

  const login = (email, password, redirect = null) => {
    setIsLoading(true);
    setError('');
    apiRequest({
      method: 'post',
      url: urls.login,
      data: {
        identity: {
          type: 'email',
          value: email,
        },
        secret: {
          type: 'password',
          value: password,
        },
      },
    })
      .then((response) => {
        setAuth(response.user, `Bearer ${response.token.value}`);
        if (redirect) window.location.href = redirect;
      })
      .catch((error) => {
        clearAuth(error);
      });
  };

  const handleVerification = async (email, verificationCode) => {
    setIsLoading(true);
    setError('');

    await apiRequest({
      url: '/identities/verifications',
      method: 'POST',
      data: {
        type: 'code',
        value: verificationCode,
        identity: {
          type: 'email',
          value: email,
        },
      },
    })
      .then((response) => {
        setAuth(response.user, `Bearer ${response.token.value}`);
      })
      .catch((error) => {
        clearAuth(error);
      });
  };

  const changePassword = (currentPassword, password, confirmPassword) =>
    new Promise(async (resolve, reject) => {
      await apiRequest({
        method: 'put',
        url: urls.changePassword,
        data: {
          password_current: currentPassword,
          password,
          password_confirmation: confirmPassword,
        },
      })
        .then((response) => {
          const token = `Bearer ${response.data.access_token}`;
          localStorage.setItem('token', token);
          setAuthToken(token);
          setToken(token);
          return resolve(true);
        })
        .catch((error) => reject(error));
    });

  const createPassword = (password, confirmPassword) =>
    new Promise(async (resolve, reject) => {
      await apiRequest({
        method: 'put',
        url: urls.changePassword,
        data: {
          password,
          password_confirmation: confirmPassword,
        },
      })
        .then((response) => {
          return resolve(true);
        })
        .catch((error) => reject(error));
    });

  const sendResetPasswordLink = (email) =>
    new Promise(async (resolve, reject) => {
      await apiRequest({
        method: 'post',
        url: urls.forgotPassword,
        data: {
          identity: {
            type: 'email',
            value: email,
          },
        },
      })
        .then((response) => {
          return resolve(true);
        })
        .catch((error) => reject(error));
    });

  const resetPassword = (email, code, password, confirmPassword) =>
    new Promise(async (resolve, reject) => {
      await apiRequest({
        method: 'post',
        url: urls.resetPassword,
        data: {
          type: 'code',
          value: code,
          identity: {
            type: 'email',
            value: email,
          },
          secret: {
            type: 'password',
            value: password,
            value_confirmation: confirmPassword,
          },
        },
      })
        .then((response) => {
          console.log(response);
          return resolve(true);
        })
        .catch((error) => reject(error));
    });

  const signUp = (firstName, lastName, email, password) => {
    setIsLoading(true);
    setError('');
    apiRequest({
      method: 'post',
      url: urls.signUp,
      data: {
        first_name: firstName,
        last_name: lastName,
        identity: {
          type: 'email',
          value: email,
        },
        secret: {
          type: 'password',
          value: password,
        },
      },
    })
      .then((response) => {
        setAuth(response.user, `Bearer ${response.token.value}`);
      })
      .catch((error) => {
        clearAuth(error);
      });
  };

  const logout = () => {
    clearAuth();
  };

  const clearAuth = (error) => {
    setAuthToken();
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setIsLoading(false);
    setUser(null);
    setIsAuthenticated(false);
    setToken('');
    if (error) {
      console.log(`ERROR SET`);
      setError(error);
    }
    if (error) {
      setTimeout(() => {
        setError('');
        console.log(`ERROR RESET`);
      }, 3000);
    }
  };

  const setAuth = (user, token) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    setAuthToken(token);
    setIsLoading(false);
    setUser(user);
    setIsAuthenticated(true);
    setToken(token);
    setError('');
  };

  const updateUserName = (firstName, lastName) => {
    console.log(firstName, lastName);
    const currentUser = JSON.parse(localStorage.getItem('user'));
    currentUser['first_name'] = firstName;
    currentUser['last_name'] = lastName;
    localStorage.setItem('user', JSON.stringify(currentUser));
    setUser(currentUser);
  };

  const setAuthToken = (token) => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = token;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        error,
        isLoading,
        isAuthenticated,
        token,
        login,
        logout,
        loadUser,
        handleVerification,
        signUp,
        changePassword,
        createPassword,
        resetPassword,
        updateUserName,
        sendResetPasswordLink,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
