import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Slider.css";
import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import "./Slider.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import PauseIcon from '@mui/icons-material/Pause';
import QAQuestionBox from "../QAQuestionBox";
import { useTheme } from "@mui/material/styles";
import { theme } from "styles/theme";

export const MultiSlider = ({
  qaData,
  data,
  selectedResponse,
  setSelectedResponse,
}) => {
  const theme = useTheme();
  // const [isPlaying, setIsPlaying] = useState(null);
  const videoSliderRef = useRef(null);
  const questionsSliderRef = useRef(null);
  const thumnailsSliderRef = useRef(null);
  const selectedResponseRef = useRef();
  selectedResponseRef.current = selectedResponse;

  const handlePlay = (id) => {
    // setIsPlaying(id);
    let videoElm = document.getElementById(id);
    videoElm.play();
  };

  const handlePause = (id) => {
    // setIsPlaying('');
    let videoElm = document.getElementById(id);
    videoElm.pause();
  };

  useEffect(() => {
    if (videoSliderRef.current?.slickGoTo)
      videoSliderRef.current.slickGoTo(selectedResponse);
    if (thumnailsSliderRef.current?.slickGoTo)
      thumnailsSliderRef.current.slickGoTo(selectedResponse);
    if (questionsSliderRef.current?.slickGoTo)
      questionsSliderRef.current.slickGoTo(
        data.findIndex((e) => e.id === qaData[selectedResponse].id)
      );
  }, [selectedResponse, data, qaData]);

  const questionsSliderSettings = {
    infinite: true,
    slidesToShow: data.length >= 5 ? 4.5 : data.length,
    slidesToScroll: 1,
    dots: false,
    speed: 300,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: data.length >= 5 ? 5 : data.length,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: data.length >= 3 ? 3 : data.length,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: data.length >= 2 ? 2 : data.length,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const thumnailsSliderSettings = {
    infinite: true,
    dots: null,
    slidesToShow: 6,
    slidesToScroll: 5,
    arrows: null,
    appendDots: null,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 5,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const videosSliderSettings = {
    lazyLoad: true,
    afterChange: (indexOfNextSlide) => {
      if (indexOfNextSlide !== selectedResponse) {
        if (indexOfNextSlide < qaData.length) {
          setSelectedResponse(indexOfNextSlide);
        } else {
          setSelectedResponse(0);
        }
      }
    },
    beforeChange: () => {
      const vidElement = document.querySelector(".slick-current .qavideos");
      vidElement.pause();
    },
  };

  return (
    <>
      {/* Questions Slider */}
      <Grid>
        <Slider
          {...questionsSliderSettings}
          ref={questionsSliderRef}
          nextArrow={<NextArrowQA />}
          prevArrow={<PrevArrowQA />}
          className="questions-slider"
        >
          {data?.map((item, index) => {
            return (
              <Box ml={2} mr={2} mt={{ xs: 5, md: 0 }} key={index}>
                <QAQuestionBox
                  selected={
                    qaData[selectedResponse].id === data[index].id
                      ? true
                      : false
                  }
                  topic={item?.topic}
                  onClick={() => {
                    setSelectedResponse(
                      qaData.findIndex((e) => e.id === data[index].id)
                    );
                  }}
                />
              </Box>
            );
          })}
        </Slider>
      </Grid>

      <div style={{ margin: "-30px 30px 0px 30px", position: "relative" }}>
        {/* Video Slider */}
        <Slider
          {...videosSliderSettings}
          autoplaySpeed={2000}
          slidesToShow={1}
          centerMode={true}
          dots={null}
          ref={videoSliderRef}
          infinite
          nextArrow={<SampleNextArrow />}
          prevArrow={<SamplePrevArrow />}
          dotsClass="slick-dots custom-indicator"
          className="qa-video-slider"
        >
          {qaData?.map((item, index) => {
            return (
              <Box
                className="video-wrap"
                width={"100%"}
                height="auto"
                position={"relative"}
                sx={{
                  padding: "0 10px",
                }}
                key={index}
              >
                {/* {isPlaying !== `video${index}` ? (
                  <div 
                  className='play-btn'
                    style={{
                      background: '#fff',
                      width: 80,
                      height: 80,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '50%',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: '1',
                    }}
                onClick={() => handlePlay(`video${index}`)}

                  >
                    <PlayArrowIcon fontSize='large' />
                  </div>
                ) : (
                  ''
                )} */}

                <div
                  style={{
                    width: "100%",
                    height: "80%",
                  }}
                >
                  <video
                    preload="metadata"
                    poster={item?.thumbnail}
                    className="qavideos"
                    id={`video${index}`}
                    src={`${item?.responseUrl}#t=0.02`}
                    // onError={(e) => {
                    //   e.target.onerror = null;
                    //   e.target.src = 'http://techslides.com/demos/sample-videos/small.webm';
                    // }}
                    autoPlay={false}
                    width={"100%"}
                    height="90%"
                    controls={true}
                    onPause={() => handlePause(`video${index}`)}
                    onPlay={() => handlePlay(`video${index}`)}
                  />
                </div>

                <div style={{ marginTop: "1rem" }}>
                  <Typography
                    variant="h3"
                    sx={{ fontSize: "1.5rem", fontWeight: "900" }}
                  >
                    {item.responseName}
                  </Typography>
                  <Typography variant="body" sx={{ fontSize: "1rem" }}>
                    {item.responseTitle}
                  </Typography>
                </div>
              </Box>
            );
          })}
        </Slider>

        {/* Thumnails Slider */}
        <Slider ref={thumnailsSliderRef} {...thumnailsSliderSettings}>
          {qaData?.map((item, index) => {
            return (
              <Box
                sx={{ cursor: "pointer", marginTop: "2rem" }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexWrap={"wrap"}
                width={"100%"}
                height="100%"
                gap={"1rem"}
                paddingY={"1rem"}
                key={index}
                px={".5rem"}
                onClick={() => {
                  setSelectedResponse(index);
                }}
              >
                <Typography>{item.quoteTitle}</Typography>
                <img
                  src={
                    item.thumbnail
                      ? item.thumbnail
                      : "/assets/images/noImage.jpg"
                  }
                  alt={item.responseTitle}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "assets/images/noImage.jpg";
                  }}
                  width={"100%"}
                  height={"112px"}
                  style={{ objectFit: "cover" }}
                />

                {selectedResponse === index ? (
                  <Box
                    sx={{
                      width: "111px",
                      height: "7px",
                      borderRadius: "5px",
                      bgcolor: theme.palette.primary.main,
                      margin: "1rem auto",
                    }}
                  />
                ) : (
                  ""
                )}
              </Box>
            );
          })}
        </Slider>
      </div>
    </>
  );
};

export default MultiSlider;

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={"slick-arrow"}
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        cursor: "pointer",
        zIndex: "1",
        height: "calc(100% - 74px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "linear-gradient(90deg, rgba(0, 0, 0, 0.9), transparent)",
        width: "100px",
      }}
      onClick={onClick}
    >
      <NavigateNextIcon
        sx={{
          color: theme.palette.secondary.main,
          fontSize: "2rem",
          transform: "rotate(180deg)",
        }}
      />
    </div>
  );
}
function PrevArrowQA(props) {
  const { onClick } = props;
  return (
    <div
      className={"slick-arrow1"}
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        cursor: "pointer",
        zIndex: "1",
        height: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100px",
      }}
      onClick={onClick}
    >
      <NavigateNextIcon
        sx={{
          color: theme.palette.secondary.main,
          fontSize: "2rem",
          transform: "rotate(180deg)",
        }}
      />
    </div>
  );
}

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={"slick-arrow"}
      style={{
        position: "absolute",
        top: "0",
        right: "0",
        cursor: "pointer",
        zIndex: "1",
        height: "calc(100% - 74px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.9))",
        width: "100px",
      }}
      onClick={onClick}
    >
      <NavigateNextIcon
        sx={{ color: theme.palette.secondary.main, fontSize: "2rem" }}
      />
    </div>
  );
}

function NextArrowQA(props) {
  const { onClick } = props;
  return (
    <div
      className={"slick-arrow1"}
      style={{
        position: "absolute",
        top: "0",
        right: "0",
        cursor: "pointer",
        zIndex: "1",
        height: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100px",
      }}
      onClick={onClick}
    >
      <NavigateNextIcon
        sx={{ color: theme.palette.secondary.main, fontSize: "2rem" }}
      />
    </div>
  );
}
