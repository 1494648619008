import React from 'react';
import FormControl from '@mui/material/FormControl';
import SelectSchool from './SelectSchool';
import CustomSelect from './CustomSelect';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import ConfigurationContext from 'contexts/ConfigurationContext';

function EducationRow({ member, idx, updateMemberKey }) {
  const [isSelectSchool, setIsSelectSchool] = React.useState(false);
  const { schools } = React.useContext(ConfigurationContext);
  const schoolName = schools.find((s) => s.id === member?.education?.outcome_id)?.outcomeTitle;

  const setSchool = (sc) => {
    updateMemberKey(idx, 'outcome_id', sc);
  };

  return (
    <div>
      <Box mt={5}>
        <Typography variant='body1' fontWeight='bold'>
          Family Member {idx + 1} ({member?.name})
        </Typography>
      </Box>
      <Grid container mt={2} spacing={3}>
        <Grid item md={5} xs={12} sm={12}>
          <InputLabel className='MuiInputLabel-Profile-label' shrink>
            Degree
          </InputLabel>
          <FormControl fullWidth>
            <TextField
              InputProps={{ disableUnderline: 'false' }}
              variant='filled'
              hiddenLabel
              fullWidth
              id='last-name'
              className='family-input-fields'
              sx={{ mb: 2 }}
              value={member?.education?.degree}
              onChange={(e) => updateMemberKey(idx, 'degree', e.target.value)}
            ></TextField>
          </FormControl>
        </Grid>

        <Grid item md={5} xs={12} sm={12}>
          <InputLabel className='MuiInputLabel-Profile-label' shrink>
            School
          </InputLabel>
          <FormControl fullWidth>
            <CustomSelect
              InputProps={{ disableUnderline: 'false' }}
              isSelectSchool={isSelectSchool}
              setIsSelectSchool={setIsSelectSchool}
              value={schoolName}
            ></CustomSelect>
          </FormControl>
        </Grid>
      </Grid>
      {!isSelectSchool && (
        <Box my={5}>
          <Divider />
        </Box>
      )}
      {isSelectSchool && (
        <SelectSchool setSchool={setSchool} setIsSelectSchool={setIsSelectSchool} school={schoolName} />
      )}
    </div>
  );
}

export default EducationRow;
