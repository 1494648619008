import { Typography, Box } from '@mui/material';
import React from 'react';
import ConfigurationContext from '../../contexts/ConfigurationContext';
import { useTheme } from '@mui/material/styles';
// import urls from 'api/urls';
// import { PROGRAM_CONTENT } from 'constants/program_content';

function Logo({ variant }) {
  const { configurations } = React.useContext(ConfigurationContext);
  const theme = useTheme();

  return (
    <Box component='div'>
      <img
        // src={PROGRAM_CONTENT[urls.programId()].logo}
        src={
          configurations?.logoUrl ? configurations?.logoUrl : configurations?.logo_url ? configurations?.logo_url : ''
        }
        className='logo'
        alt={configurations?.schoolName}
        style={{ display: 'block', margin: variant === 'left' ? 'unset' : 'auto', height: '62px' }}
      />
      <Typography
        sx={{
          color: theme.palette.primary.main,
          fontWeight: '500',
          fontSize: { xs: '0.7em', sm: '0.9em' },
          letterSpacing: '1px',
          marginLeft: { xs: '0px', sm: '8px' },
          marginTop: '3px',
          display: 'block',
          width: variant === 'left' ? '80%' : '100%',
        }}
      >
        {/* There are worlds to discover at {configurations.schoolName ? configurations.schoolName : ''}! */}
        {configurations?.school_tagline}
      </Typography>
    </Box>
  );
}

export default Logo;
