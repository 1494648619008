import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { ROUTES } from 'constants/routes';
import ConfigurationContext from 'contexts/ConfigurationContext';

const pagesAll = [
  {
    title: 'ThinKTank',
    urls: [ROUTES.TIPS, ROUTES.TIPS_ARTICLE],
    icon: '/assets/icons/tips.svg',
    tag: 'article',
  },
  {
    title: 'Q&A Videos',
    urls: [ROUTES.QA],
    icon: '/assets/icons/qa.svg',
    tag: 'qa',
  },
  {
    title: 'Outcomes',
    urls: [ROUTES.OUTCOMES],
    icon: '/assets/icons/outcomes.svg',
    tag: 'outcome',
  },
  {
    title: 'Family Network',
    urls: [
      ROUTES.FAMILY_NETWORK,
      ROUTES.PROFILE_DETAILS,
      ROUTES.FAMILY_AMBASSADORS_DETAILS,
      ROUTES.FAMILY_AMBASSADORS_PROFILE_DETAILS,
    ],
    icon: '/assets/icons/family.svg',
    tag: 'family',
  },
  {
    title: 'Staff Directory',
    urls: [ROUTES.STAFF_DIRECTORY, ROUTES.OUR_STAFF, ROUTES.STAFF_PROFILE],
    icon: '/assets/icons/staff.svg',
    tag: 'staff',
  },
  {
    title: 'ClubHub',
    urls: [ROUTES.CLUB_HUB, ROUTES.CLUB_PAGE],
    icon: '/assets/icons/clubhub.svg',
    tag: 'club',
  },
  {
    title: 'Graduates',
    urls: [ROUTES.GRADUATES, ROUTES.GRADUATES],
    icon: '/assets/icons/clubhub.svg',
    tag: 'graduate',
  },
];

const Navbar = () => {
  const { pathname } = useLocation();
  const { isFeatureEnabled } = React.useContext(ConfigurationContext);
  const pages = pagesAll.filter((p) => isFeatureEnabled(p.tag));

  return (
    <AppBar position='static' color='primary' sx={{ display: { xs: 'none', md: 'block' } }}>
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Box
            mx={7}
            flexGrow={1}
            justifyContent={pages.length === pagesAll.length ? 'space-between' : 'start'}
            sx={{ display: { xs: 'none', sm: 'flex' } }}
          >
            {pages.map(({ title, urls, icon }, index) => (
              <Link to={urls[0]} key={`menu-${index}`}>
                <Button className={`MuiButton-navbar ${urls.find((url) => url === pathname) && 'MuiSelected'}`}>
                  <img src={icon} width={20} alt='' />
                  <Box ml={2}>{title}</Box>
                </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navbar;
