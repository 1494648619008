import React, { useContext } from 'react';
import { CURRICULAR_TEXT } from 'constants/home';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { COLORS } from '../../../../constants/colors';
import ConfigurationContext from 'contexts/ConfigurationContext';
import { useTheme } from '@mui/material/styles';
import { ROUTES } from '../../../../constants/routes';
import { Link } from 'react-router-dom';

export const ExtraCurricular = () => {
  const { configurations } = useContext(ConfigurationContext);
  const theme = useTheme();

  return (
    <React.Fragment>
      <Grid
        container
        mt={{ lg: 10, xs: 5, sm: 15, md: 15 }}
        direction={'column'}
        sx={{ backgroundColor: 'black', zIndex: -1 }}
      >
        <Grid item md={6} xs={12} sx={{}}>
          <Box
            className='ourschool-banner'
            pt={16}
            px={10}
            // height={{ lg: 700, xs: 600, sm: 700, md: 700 }}
            height={{ lg: 750, xs: 600, sm: 700, md: 700 }}
            position='relative'
            sx={{
              backgroundImage:
                // "linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.9)),url(assets/images/teamwork.png)",
                'linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),url(assets/images/teamwork-img-2.png)',
              objectFit: 'cover',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              // backgroundPosition: "center",
              backgroundPosition: 'bottom',
              opacity: 0.9,
            }}
          >
            <Box position='absolute' bottom={'2%'} right={{ lg: '2%', xs: '60%', sm: '2%', md: '2%' }}>
              <img src='/assets/images/home-image-logo.svg' alt='' />
              <Typography color={COLORS.white.dark} fontSize={10} align='center' fontWeight='bold'>
                Secured and Verified <br /> for Current {configurations.schoolName ? configurations.schoolName : ''}{' '}
                <br /> Families Only.
              </Typography>
            </Box>
            <Container maxWidth='xl'>
              <Grid container>
                <Grid item md={6}>
                  <Typography
                    my={2}
                    variant='h3'
                    color='white'
                    fontWeight='bold'
                    fontSize={{ xs: 40, md: 45, sm: 40, lg: 60 }}
                    lineHeight={1}
                  >
                    Our school.
                    <br />
                    Your community.
                  </Typography>
                  <Box>
                    <Typography color='white' width={{ xs: 260, md: 500, sm: 350 }}>
                      {`Get an inside perspective on the ${
                        configurations.schoolShortName ? configurations.schoolShortName : ''
                      } experience from current members of the ${
                        configurations.schoolShortName ? configurations.schoolShortName : ''
                      } commuity! `}
                    </Typography>
                    <Grid
                      mt={3}
                      display={{
                        md: 'flex',
                        sm: 'flex',
                        lg: 'flex',
                        xs: 'none',
                      }}
                      direction='column'
                    >
                      {CURRICULAR_TEXT.map((data) => (
                        <Grid container sx={{ alignItems: 'center' }} mt={1}>
                          {/* <img src={"/assets/images/tick.png"} alt="" /> */}
                          <img src={'/assets/images/green-tick.png'} alt='' />
                          <Typography variant='body1' color={'white'} ml={2}>
                            {data}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  <Box display={{ md: 'flex', sm: 'flex', lg: 'flex', xs: 'none' }} direction='column'>
                    <Link to={ROUTES.FAMILY_NETWORK}>
                      <Button
                        variant='contained'
                        sx={{
                          bgcolor: theme.palette.primary.main,
                          borderRadius: 10,
                          paddingX: 5,
                          paddingY: 1.5,
                          marginTop: 4,
                        }}
                      >
                        <Typography
                          color={'white'}
                          textTransform={'capitalize'}
                          variant='body2'
                          fontWeight='bold'
                          fontSize={12}
                        >
                          Connect with {configurations.schoolName ? configurations.schoolName : ''} <br /> Ambassadors
                        </Typography>
                      </Button>
                    </Link>
                  </Box>
                </Grid>
                <Grid item md={6}></Grid>
              </Grid>
            </Container>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={6}>
          <Grid p={3} mt={3} display={{ md: 'none', sm: 'none', lg: 'none', xs: 'flex' }} direction='column'>
            {CURRICULAR_TEXT.map((data) => (
              <Grid className='searchandconnectmob' container sx={{ alignItems: 'center' }} mt={1}>
                <svg xmlns='http://www.w3.org/2000/svg' width='18' height='12' viewBox='0 0 18 12' fill='none'>
                  <path
                    d='M16.8282 0.411504C16.7197 0.302154 16.5907 0.215361 16.4485 0.156131C16.3064 0.0969006 16.1539 0.0664062 15.9999 0.0664062C15.8458 0.0664062 15.6934 0.0969006 15.5512 0.156131C15.409 0.215361 15.28 0.302154 15.1715 0.411504L6.47986 9.11484L2.82819 5.4515C2.71558 5.34272 2.58265 5.25719 2.43699 5.19979C2.29132 5.14238 2.13578 5.11423 1.97924 5.11694C1.82269 5.11965 1.66821 5.15316 1.52462 5.21557C1.38103 5.27798 1.25114 5.36806 1.14236 5.48067C1.03358 5.59328 0.948049 5.72621 0.890644 5.87187C0.833239 6.01754 0.805087 6.17308 0.807796 6.32963C0.810504 6.48617 0.84402 6.64065 0.906429 6.78424C0.968838 6.92783 1.05892 7.05772 1.17153 7.1665L5.65153 11.6465C5.75998 11.7559 5.88902 11.8426 6.03119 11.9019C6.17336 11.9611 6.32585 11.9916 6.47986 11.9916C6.63387 11.9916 6.78636 11.9611 6.92853 11.9019C7.0707 11.8426 7.19974 11.7559 7.30819 11.6465L16.8282 2.1265C16.9466 2.01725 17.0411 1.88466 17.1058 1.73708C17.1704 1.5895 17.2038 1.43012 17.2038 1.269C17.2038 1.10789 17.1704 0.948513 17.1058 0.80093C17.0411 0.653347 16.9466 0.520754 16.8282 0.411504Z'
                    fill={theme.palette.secondary.main}
                  />
                </svg>
                <Typography variant='body1' color={'black'} ml={2}>
                  {data}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Box
            className='millerhs-button'
            px={3}
            mb={2}
            display={{ md: 'none', sm: 'none', lg: 'none', xs: 'flex' }}
            direction='column'
          >
            <Link to={ROUTES.FAMILY_NETWORK}>
              <Button
                // variant='contained'
                sx={{
                  bgcolor: theme.palette.primary.main,
                  borderRadius: 10,
                  paddingX: 6,
                  paddingY: 1.5,
                  marginTop: 4,
                }}
              >
                <Typography
                  color={'white'}
                  textTransform={'capitalize'}
                  variant='body2'
                  fontWeight='bold'
                  fontSize={12}
                >
                  Connect with {configurations.schoolName ? configurations.schoolName : ''} <br /> Ambassadors
                </Typography>
              </Button>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ExtraCurricular;
