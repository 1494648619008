import React from "react";
// import { PROFILE_CLUB } from "constants/profiles";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
// import Button from "@mui/material/Button";
import Container from "@mui/system/Container";
import Typography from "@mui/material/Typography";
// import { ROUTES } from "constants/routes";
// import { useHistory } from "react-router-dom";

export const MyClubs = (props) => {
  // const { isDate } = props || {};

  // const history = useHistory();

  return (
    <React.Fragment>
      <Container>
        <Box>
          <Typography variant="h5" fontWeight="bold">
            My Clubs
          </Typography>
        </Box>
        <Grid container spacing={3} mb={10} mt={5}>
          {/* {PROFILE_CLUB.map((data) => (
            <Grid item md={4} sm={6} xs={12}>
              <img src={data.image} alt="" width="100%" />
              <Box my={2}>
                <Typography variant="body1" fontWeight="bold">
                  {data.name}
                </Typography>
              </Box>
              {isDate && (
                <Box mb={2}>
                  <Typography variant="body2">{data.date}</Typography>
                </Box>
              )}

              <Button
                sx={{ width:'170px',height:'47px' }}
                className="MuiButton-profile"
                variant="outlined"
                onClick={()=>history.push(ROUTES.CLUB_EDITOR)}
              >
                Edit Club Page
              </Button>
            </Grid>
          ))} */}
        </Grid>
      </Container>
    </React.Fragment>
  );
};
export default MyClubs;
